export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablodbò"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfòmite Tablodbò"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akèy"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chajman ap fèt..."])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèl yo"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesyonè yo"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranpli kesyonè sou dilijans yo epi bay dokiman yo"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumèt Kesyonè"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fòmilè an Soumèt"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mwen konfime sa ki anwo a"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retounen nan tablodbò an"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontinye"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apèsi"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dat li Afekte an:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dat li Fèt lan:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telechaje"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FÈT"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AP FÈT"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA KÒMANSE"])},
    "linkUpModalHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksyon adisyonèl obligatwa"])},
    "linkUpModalBodyText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mèsi paske w ranpli Fòmilè Diligence Due Diligence Salesforce la."])},
    "linkUpModalBodyText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapre repons yo bay yo, Salesforce mande pou òganizasyon w ranpli Kesyonè Dilijans Twazyèm Pati Salesforce la."])},
    "linkUpModalBodyText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanpri ranpli kesyonè sa a pi vit posib. Si w pa ranpli kesyonè a ka afekte demann òganizasyon w lan fè pou antre nan yon relasyon biznis ak Salesforce."])},
    "linkUpModalBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kòmanse Kesyonè dilijans la"])},
    "linkUpModalCancelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retounen nan kesyonè yo"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siprime Ranje an"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifye Ranje an"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padon, pa gen okenn opsyon matche."])}
  },
  "training": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fòmasyon yo"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Li materyèl fòmasyon yo epi prepare pou egzamen yo"])},
    "trainingTite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Training Courses"])},
    "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code of Conduct"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fèmen"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revize"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kòmanse"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontinye"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ap Fèt"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fini"])},
    "notComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pa Kòmanse"])},
    "toFirstSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to first slide"])},
    "toDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retounen nan Tablodbò an"])},
    "slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing:"])},
    "nextSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Slide"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "startCertification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your certification"])},
    "trainingCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Completed"])},
    "proceedToExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning stage of the training complete. Proceed to examination."])},
    "fromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start From beginning"])},
    "continueTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Training"])},
    "ContinueText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to Continue from slide number"])},
    "ContinueText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or start from beginning"])},
    "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telechaje Fichye an"])}
  },
  "certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sètifikasyon yo"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pran egzamen an epi resevwa sètifika"])},
    "noOfQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Questions"])},
    "examTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Time: "])},
    "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mins"])},
    "maxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Attempts"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" attempt(s)"])},
    "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" remaining"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "timeLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Left"])},
    "guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes."])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "summaryText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a total of"])},
    "summaryText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attempts for the entire exam and its questions"])},
    "startNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["START NOW"])},
    "doLate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'll do it later"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
    "attemptsToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempts to date:"])},
    "remainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Attempts:"])},
    "maximumReach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of attempts. Please contact the compliance officer to discuss your options"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
    "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "backToQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing:"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "submitAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumèt Answers"])},
    "answerOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer Overview"])},
    "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewed"])},
    "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered"])},
    "notAnswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not answered"])},
    "timeLimitText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a"])},
    "timeLimitText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute timed exam"])},
    "examAboutToStartText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your exam is about to start in"])},
    "examAboutToStartText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "startImmediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Immediately"])},
    "startExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Exam"])},
    "aboutToSubmitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to submit your answers. This will count towards the maximum attempts. If you are happy with your answers then submit."])},
    "aboutToSubmitTextSimple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to submit your exam. This action cannot be undone. Are you sure you want to submit?"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
    "noteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can go back and review your answers before you submit."])},
    "submitExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumèt Exam"])},
    "cancelExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Exam"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumèt"])},
    "noAnsGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Answer Given"])},
    "cancelExamText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to cancel without completing your exam?"])},
    "cancelExamText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelling the exam would count towards your attempts."])},
    "cancelExamText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have"])},
    "cancelExamText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attempt(s) remaining"])},
    "examSubmittedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Submitted"])},
    "closeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fèmen"])},
    "thankYouText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for Choosing Our Exam Portal, for further queries please contact our Admin."])},
    "examResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Result"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score:"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["out of"])},
    "yourResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Result:"])},
    "toPassExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Needed To Pass Exam:"])},
    "examStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Status:"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed"])},
    "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations you have passed!"])},
    "PlsTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again"])},
    "goToQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to question"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert!"])},
    "blankAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Cannot Submit Blank Answer/s."])},
    "englishOriginalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Original"])}
  },
  "annual-certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sètifikasyon yo"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sètifye Plizyè Sètifikasyon"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou sou wout pou kite paj la. Nenpòt chanjman ki pa sovgade ap pèdi. Èske ou sèten ou vle kite?"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou sou wout pou soumèt kesyonè ou an. Aksyon sa a ou pa kapab defèt. Èske ou sèten ou vle soumèt?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fòmilè ou an te soumèt avèk siksè."])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youn oswa plizyè espas manke oswa yo jwenn erè. Tanpri revize epi modifye si sa nesesè."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avètisman"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sovgade Siksè an"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pwogrè a pa sovgade. Tanpri eseye ankò."])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siksè Mizajou an"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nou ap kreye PDF ou an..."])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF lan telechaje avèk siksè"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF lan pa telechaje, tanpri eseye ankò"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siksè Dekoneksyon"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrejistreman Fèt"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Modifye Pwofil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imèl"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay pou Kontak"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mete Modpas la ajou"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenon"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siyati"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimewo pou Kontak"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tit Djòb la"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpas Aktyèl"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvo Modpas"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfime Modpas la"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mete Prenon Ou"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mete Non Fanmi/Siyati Ou "])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mete Nimewo Kontak Ou"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mete Tit Djòb oswa Wòl Ou"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mete Modpas Aktyèl la"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mete Nouvo Modpas la"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mete Konfimasyon Modpas la"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espas sa a obligatwa"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpas yo dwe idantik"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demann ou an ap trete..."])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rale epi lage pou telechaje voye kontni!"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...oswa klike pou chwazi yon fichye nan òdinatè ou an"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Fichye"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip Fichye"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwosè fichye (bit)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksyon"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efase"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatwa"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mete"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efase"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repwograme"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mizajou"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siprime"])},
    "deleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Èske ou sèten ou vle siprime eleman sa a?"])},
    "deleteHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siprime Ranje an"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
    "burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Taiwan"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
    "haitian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haitian Creole"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "khmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "lao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lao"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "portuguesebr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Brazilian"])},
    "portuguesemz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Mozambique"])},
    "portuguesepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Portugal"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "serbian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian"])},
    "sinhalese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
    "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "spanishmx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish Mexican"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fòma a pa valab"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imèl la pa valab"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpas aktyèl la pa valab."])}
  },
  "privacyStatement": {
    "msgTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politik sou Vi prive"])},
    "contactUsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The responsible use of personal data is a core value at Novartis. Novartis Group fully respects privacy laws and is subject to an internal framework of privacy rules and policies. The internal transfer of data is governed by Binding Corporate Rules, so called \"BCR\". BCR are a system of privacy principles, rules and tools intended to ensure the protection of personal data. This collection of rules represents today's best practice to meet the European Economic Area's (\"EEA\") data protection requirements for the transfer of personal data within a Group of companies. To be legally effective, the BCR have been approved by EEA Data Protection Agencies. BCR regulate the mechanism of transfer of data inside the Novartis Group of companies."])},
    "contactMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish to contact us regarding how we use your personal data or you wish to exercise your data privacy rights, please email us at"])}
  },
  "footerDashboard": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any information provided will be handled in accordance with Tecan's "])},
    "linkText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "fullTex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any information provided will be handled in accordance with Tecan's Privacy Policy."])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ap sovgade......"])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kòmanse"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sovgade"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontinye"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anile"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fèmen"])},
  "okBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siprime"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifye"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumèt"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sovgade Pwogrè"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konekte"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekonekte"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konekte"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekonekte"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mizajou"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pwofil"])}
}