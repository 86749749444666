import sanitizeHtml from "sanitize-html";
import markdown from "markdown-it";
import turndown from "turndown";

const markDownInstance = markdown("commonmark");
const turndownInstance = new turndown({
  headingStyle: "atx",
  bulletListMarker: "*",
  emDelimiter: "*"
});

/**
 * @namespace HTMLElements
 */

/**
 * Returns the highest clientWidth value from a HTMLCollection
 * @memberof HTMLElements
 * @param {HTMLCollection} HTMLElements - Collection of badge elements
 * @returns {number} - highest width in pixels
 */
const getWidestElement = (HTMLElements) => {
  let widestElement = 0;

  for (const badgeElement of HTMLElements) {
    if (badgeElement?.clientWidth > widestElement) {
      widestElement = badgeElement?.clientWidth;
    }
  }

  return widestElement;
};

/**
 * Returns sanitized HTML
 * @memberof HTMLElements
 * @param {object} parameter - Provided Object
 * @param {string} parameter.html - HTML for sanitization
 * @param {object} parameter.configuration - configuration object on how to sanitize the html
 * @returns {string} - sanitized HTML
 */
const sanitizeHTML = ({ html = "", configuration = {} } = {}) =>
  sanitizeHtml(html, configuration).trim();

/**
 * Returns HTML
 * @memberof HTMLElements
 * @param {object} parameter - Provided Object
 * @param {string} parameter.markDown - markdown for conversation
 * @param {Function} parameter._sanitizeHTML - function to do the sanitization
 * @param {object} parameter.sanitizeConfiguration - Provided sanitization configuration object
 * @returns {string} - sanitized HTML
 */
const markDownToHTML = ({
  markDown = "",
  _sanitizeHTML = sanitizeHTML,
  sanitizeConfiguration = {}
} = {}) => {
  const html = markDownInstance.render(markDown);
  return _sanitizeHTML({ html, configuration: sanitizeConfiguration });
};

/**
 * Returns markdown
 * @memberof HTMLElements
 * @param {object} parameter - Provided Object
 * @param {string} parameter.html - html for sanitization
 * @param {Function} parameter._sanitizeHTML - function to do the sanitization
 * @param {object} parameter.sanitizeConfiguration - Provided sanitization configuration object
 * @returns {string} - converted html into markdown
 */
const htmlToMarkdown = ({
  html = "",
  _sanitizeHTML = sanitizeHTML,
  sanitizeConfiguration = {}
} = {}) => {
  const sanitizedHTML = _sanitizeHTML({
    html,
    configuration: sanitizeConfiguration
  });
  return turndownInstance.turndown(sanitizedHTML);
};

export { getWidestElement, markDownToHTML, htmlToMarkdown, sanitizeHTML };
