<template>
  <div class="product-widget">
    <BaseText :size="typographySize.BODY_TEXT_BOLD">
      {{ getWidgetLabel(widget) }}
    </BaseText>

    <div class="product-widget__status-wrapper">
      <div
        v-for="(status, index) in widgetStatusData"
        :key="index"
        class="product-widget__status-card"
      >
        <BaseChartWidget
          :chart-title="getStatusLabel(status)"
          :chart-options="chartOptions"
          class="product-widget__status-card__title"
        >
          <PointValue v-bind="getPointValueOptions(status)" />
        </BaseChartWidget>
      </div>
    </div>
  </div>
</template>

<script>
import BaseChartWidget from "@/atoms/BaseChartWidget/BaseChartWidget";
import PointValue from "@/molecules/PointValue/PointValue";
import BaseText from "@/atoms/BaseText/BaseText";
import { typographySize } from "@/constants";

export default {
  name: "ProductWidget",
  components: { BaseChartWidget, PointValue, BaseText },
  props: {
    widget: {
      type: Object,
      required: true
    },
    widgetStatusData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      typographySize,
      chartOptions: {}
    };
  },
  methods: {
    getWidgetLabel(widget) {
      return widget?.label || "";
    },
    getStatusLabel(status) {
      return status?.label || "";
    },
    getPointValueOptions(status) {
      return {
        value: status?.count || 0
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.product-widget {
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  text-align: center;

  &__status-wrapper {
    display: flex;
    justify-content: space-evenly;
  }

  &__status-card {
    &__title {
      text-transform: capitalize;
    }
  }
}
</style>
