import SetPassword from "@/pages/SetPassword/SetPassword";
import ForgotPasswordModal from "@/organisms/ForgotPasswordModal/ForgotPasswordModal";
import Login from "@/pages/Login/Login";
import PlatformSwitcher from "@/molecules/PlatformSwitcher/PlatformSwitcher";
import { routeNames, urls, externalUrls, templates } from "@/constants";
import { getUsersHomepage } from "@/utils";

export default [
  {
    path: urls.HOME,
    name: routeNames.HOME,
    beforeEnter: async (_, __, next) => {
      const route = await getUsersHomepage();
      next(route);
    },
    meta: { requiresAuth: false, template: templates.EB360 }
  },
  {
    path: urls.LOGIN,
    name: routeNames.LOGIN,
    component: Login,
    meta: {
      requiresAuth: false,
      template: templates.EB360,
      isFullscreenPage: true
    },
    children: [
      {
        path: "forgot-password",
        name: routeNames.FORGOT_PASSWORD,
        component: ForgotPasswordModal,
        meta: { requiresAuth: false, template: templates.EB360 }
      }
    ]
  },
  {
    path: urls.SET_PASSWORD,
    name: routeNames.SET_PASSWORD,
    component: SetPassword,
    meta: { requiresAuth: false, template: templates.EB360 }
  },
  {
    path: urls.SET_PASSWORD_EMAIL_ID,
    name: routeNames.SET_PASSWORD_EMAIL_ID,
    component: SetPassword,
    props: (route) => ({ userProvidedEmail: route.params.emailId }),
    meta: { requiresAuth: false, template: templates.EB360 }
  },
  {
    path: urls.FAQS,
    redirect: () => {
      window.location.href = externalUrls.FAQS_ZENDESK;
    },
    meta: { requiresAuth: false, template: templates.EB360 }
  },
  {
    path: urls.PLATFORM_SWITCHER,
    name: routeNames.PLATFORM_SWITCHER,
    props: false,
    component: PlatformSwitcher,
    meta: { requiresAuth: false, template: templates.EB360 }
  }
];
