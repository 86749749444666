import { thirdPartyService } from "@/services";
import {
  actionName,
  mutationName,
  thirdPartyActionProductType,
  trainingsKeysAPIDTO,
  infoMessages,
  customErrorMessages
} from "@/constants";
import {
  hasStatus404,
  hasStatus403,
  hasStatus412,
  hasStatus422
} from "@/utils";

export const actions = {
  async fetchTCertificationOptions({ commit }, { productId }) {
    try {
      const { data = {} } =
        await thirdPartyService.fetchTComplianceOrderTypeProduct(productId);

      commit("setTCertificationOrderTypes", data);
      return data;
    } catch (error) {
      commit("setTCertificationOrderTypes", []);
      throw error;
    }
  },
  async fetchESGMetricsOptions({ commit }, { productId }) {
    try {
      const { data = {} } =
        await thirdPartyService.fetchTComplianceOrderTypeProduct(productId);

      commit("setESGMetricsOrderTypes", data);
      return data;
    } catch (error) {
      commit("setESGMetricsOrderTypes", []);
      throw error;
    }
  },
  async fetchFormsLanguagesList({ commit }, { companyId }) {
    try {
      const data = await thirdPartyService.fetchFormsLanguagesList(companyId);

      commit(mutationName.THIRD_PARTY.SET_FORMS_LANGUAGES_LIST, data);
      return data;
    } catch (error) {
      commit(mutationName.THIRD_PARTY.SET_FORMS_LANGUAGES_LIST, []);
      throw error;
    }
  },
  async fetchOCStatesList({ commit }) {
    try {
      const data = await thirdPartyService.fetchOCStatesList();

      commit(mutationName.THIRD_PARTY.SET_OC_STATES_LIST, data);
      return data;
    } catch (error) {
      commit(mutationName.THIRD_PARTY.SET_OC_STATES_LIST, []);
      throw error;
    }
  },
  async fetchHeaderConfigData({ commit, rootState }) {
    const { userId } = rootState.user;
    const { companyId } = rootState.company;

    try {
      const data = await thirdPartyService.fetchHeaderConfigData(
        companyId,
        userId
      );

      commit(mutationName.THIRD_PARTY.SET_HEADER_CONFIG_DATA, data);
      return data;
    } catch (error) {
      commit(mutationName.THIRD_PARTY.SET_HEADER_CONFIG_DATA, {});
      throw error;
    }
  },
  async fetchEDDClientAllPackages({ commit, rootState }) {
    const { companyId } = rootState.company;

    try {
      const data = await thirdPartyService.fetchEDDClientAllPackages(companyId);
      const filterData = data?.filter(
        (list) => parseInt(list.client_packages_id) > 0
      );

      commit(mutationName.THIRD_PARTY.SET_EDD_CLIENT_ALL_PACKAGES, filterData);
      return filterData;
    } catch (error) {
      commit(mutationName.THIRD_PARTY.SET_EDD_CLIENT_ALL_PACKAGES, []);
      throw error;
    }
  },
  async fetchAllThirdPartyData({ dispatch, rootState }) {
    const { companyId } = rootState.company;

    const formData = {
      sort: "tp_sequence|asc",
      comp_id: companyId,
      is_active: 1
    };

    await Promise.allSettled([
      dispatch(
        actionName.CUSTOM_ATTRIBUTES.FETCH_CUSTOM_ATTRIBUTES_LIST,
        formData,
        { root: true }
      ),
      dispatch("fetchTCertificationOptions", {
        productId: thirdPartyActionProductType.T_CERTIFICATION
      }),
      dispatch("fetchESGMetricsOptions", {
        productId: thirdPartyActionProductType.ESG_METRIC
      }),
      dispatch("fetchOCStatesList"),
      dispatch("fetchHeaderConfigData"),
      dispatch("fetchEDDClientAllPackages")
    ]);
  },
  async fetchThirdPartyUBOs({ commit }, queryParameters) {
    try {
      commit(mutationName.THIRD_PARTY.SET_IS_UBO_DATA_LOADING, true, {
        root: true
      });
      commit(mutationName.THIRD_PARTY.SET_THIRD_PARTY_UBO_ERROR, "", {
        root: true
      });
      const { data } = await thirdPartyService.fetchUBOThirdPartyList(
        queryParameters
      );
      if (data?.beneficialOwners?.length) {
        commit(mutationName.THIRD_PARTY.SET_THIRD_PARTY_UBO_LIST, data, {
          root: true
        });
      } else {
        const error =
          "Ultimate Beneficial Ownership (UBO) data was requested for this third party, but none was found. We will continue to monitor this third party. If any new UBO data is detected, it will be added to this page.";
        commit(mutationName.THIRD_PARTY.SET_THIRD_PARTY_UBO_ERROR, error, {
          root: true
        });
      }
    } catch ({ response }) {
      const errorMessage =
        hasStatus404(response) ||
        hasStatus412(response) ||
        hasStatus422(response)
          ? "This third party doesn't have any Ultimate Beneficial Owner information present. This will appear once the Ultimate Beneficial Owner (UBO) product has been ordered and completed for the TP. If the Ultimate Beneficial Owner product has not been activated on your account, please speak with your Account Manager"
          : "Something went wrong, please try again. If the issue persists please contact support.";

      commit(mutationName.THIRD_PARTY.SET_THIRD_PARTY_UBO_ERROR, errorMessage, {
        root: true
      });
    } finally {
      commit(mutationName.THIRD_PARTY.SET_IS_UBO_DATA_LOADING, false, {
        root: true
      });
    }
  },
  async createThirdPartyFromUBO(
    { commit, rootState },
    { postData = {}, thirdPartyId = "" }
  ) {
    try {
      const { companyId } = rootState.company;

      commit(mutationName.THIRD_PARTY.SET_IS_CREATING_THIRD_PARTY, true, {
        root: true
      });
      commit(mutationName.THIRD_PARTY.SET_CREATE_THIRD_PARTY_ERROR, false, {
        root: true
      });
      await thirdPartyService.createThirdPartyFromUBO({
        postData,
        thirdPartyId,
        companyId
      });
      commit(
        mutationName.THIRD_PARTY.SET_IS_CREATING_THIRD_PARTY_SUCCESSFUL,
        true,
        {
          root: true
        }
      );
    } catch {
      commit(mutationName.THIRD_PARTY.SET_CREATE_THIRD_PARTY_ERROR, true, {
        root: true
      });
    } finally {
      commit(mutationName.THIRD_PARTY.SET_IS_CREATING_THIRD_PARTY, false, {
        root: true
      });
    }
  },
  async fetchThirdPartyTrainingList({ commit, rootState }, thirdPartyId) {
    const { tenantId } = rootState.company;
    if (tenantId) {
      const params = {
        tenantId,
        queryParameters: {
          [trainingsKeysAPIDTO.THIRD_PARTY_ID]: thirdPartyId,
          [trainingsKeysAPIDTO.INCLUDE_SUMMARY]: true
        }
      };
      try {
        commit(mutationName.THIRD_PARTY.SET_IS_LOADING_TRAINING_LIST, true, {
          root: true
        });
        const { data = {} } =
          await thirdPartyService.fetchThirdPartyTrainingList(params);

        commit(
          mutationName.THIRD_PARTY.SET_THIRD_PARTY_TRAINING_LIST,
          data?.trainings
        );
        commit(
          mutationName.THIRD_PARTY.SET_THIRD_PARTY_TRAINING_SUMMARY_LIST,
          data?.trainingSummaryList,
          { root: true }
        );

        if (!data.trainings?.length) {
          return infoMessages.thirdParty.noTrainingListFeedback;
        }
      } catch {
        const error = new Error(
          customErrorMessages.training.fetchTrainingError
        );
        throw error;
      } finally {
        commit(mutationName.THIRD_PARTY.SET_IS_LOADING_TRAINING_LIST, false, {
          root: true
        });
      }
    } else {
      return infoMessages.thirdParty.noTrainingListFeedback;
    }
  },
  async fetchThirdPartyTrainingDetails(
    { commit, rootState },
    { thirdPartyId, courseId }
  ) {
    const { tenantId } = rootState.company;
    const queryParameters = {
      [trainingsKeysAPIDTO.THIRD_PARTY_ID]: thirdPartyId,
      course_id: courseId
    };

    if (tenantId) {
      try {
        commit(
          mutationName.THIRD_PARTY.SET_IS_LOADING_THIRD_PARTY_TRAINING_DETAILS,
          true,
          { root: true }
        );
        const { data = {} } =
          await thirdPartyService.fetchThirdPartyTrainingDetails({
            tenantId,
            queryParameters
          });
        commit(
          mutationName.THIRD_PARTY.SET_THIRD_PARTY_TRAINING_DETAILS,
          data.data
        );
      } catch {
        const error = new Error(
          "Failed to retrieve the available third party training details."
        );
        throw error;
      } finally {
        commit(
          mutationName.THIRD_PARTY.SET_IS_LOADING_THIRD_PARTY_TRAINING_DETAILS,
          false,
          { root: true }
        );
      }
    } else {
      const error = new Error(infoMessages.thirdParty.noTrainingListFeedback);
      throw error;
    }
  },
  async fetchThirdPartyBulkUploadSampleFile(
    { rootState, commit },
    { fileName, filePath }
  ) {
    try {
      commit(
        mutationName.THIRD_PARTY
          .SET_IS_LOADING_THIRD_PARTY_BULK_UPLOAD_SAMPLE_FILE,
        true,
        {
          root: true
        }
      );
      return thirdPartyService.fetchThirdPartyBulkUploadSampleFile({
        filename: fileName,
        filepath: filePath,
        company_id: rootState.company.companyId,
        user_id: rootState.user.userId
      });
    } finally {
      commit(
        mutationName.THIRD_PARTY
          .SET_IS_LOADING_THIRD_PARTY_BULK_UPLOAD_SAMPLE_FILE,
        false,
        {
          root: true
        }
      );
    }
  },
  sendEmailForThirdPartyUpdateExcelSheet({ rootState, commit }) {
    try {
      commit(
        mutationName.THIRD_PARTY
          .SET_IS_LOADING_SEND_EMAIL_FOR_THIRD_PARTY_UPDATE_EXCEL_SHEET,
        true,
        {
          root: true
        }
      );
      return thirdPartyService.sendEmailForThirdPartyUpdateExcelSheet({
        postData: {
          companyId: rootState.company.companyId,
          companyName: rootState.company.companyName,
          email: rootState.user.email,
          memberId: rootState.user.userId
        }
      });
    } finally {
      commit(
        mutationName.THIRD_PARTY
          .SET_IS_LOADING_SEND_EMAIL_FOR_THIRD_PARTY_UPDATE_EXCEL_SHEET,
        false,
        {
          root: true
        }
      );
    }
  },
  sendEmailForThirdPartyFilteredExport(
    { rootState, commit },
    selectedThirdPartyIds = []
  ) {
    try {
      commit(
        mutationName.THIRD_PARTY
          .SET_IS_LOADING_SEND_EMAIL_FOR_THIRD_PARTY_FILTERED_EXPORT,
        true,
        {
          root: true
        }
      );
      return thirdPartyService.sendEmailForThirdPartyFilteredExport({
        postData: {
          companyId: rootState.company.companyId,
          email: rootState.user.email,
          thirdPartyIds: selectedThirdPartyIds,
          requestedDataItems: ["third_party"]
        }
      });
    } finally {
      commit(
        mutationName.THIRD_PARTY
          .SET_IS_LOADING_SEND_EMAIL_FOR_THIRD_PARTY_FILTERED_EXPORT,
        false,
        {
          root: true
        }
      );
    }
  },
  async fetchThirdPartyDetails({ rootState, commit }, thirdPartyId = "") {
    try {
      commit(
        mutationName.THIRD_PARTY.SET_IS_THIRD_PARTY_NEW_MODAL_EDITABLE,
        true,
        {
          root: true
        }
      );
      commit(
        mutationName.THIRD_PARTY.SET_IS_LOADING_THIRD_PARTY_DETAILS,
        true,
        {
          root: true
        }
      );
      commit(mutationName.THIRD_PARTY.SET_THIRD_PARTY_DETAILS_MESSAGE, "", {
        root: true
      });
      const { data = {} } = await thirdPartyService.fetchThirdPartyDetails({
        thirdPartyId,
        companyId: rootState.company.companyId,
        userId: rootState.user.userId
      });
      commit(
        mutationName.THIRD_PARTY.SET_THIRD_PARTY_DETAILS,
        data?.response || {},
        {
          root: true
        }
      );
    } catch ({ response }) {
      let message = customErrorMessages.generic.unexpectedError;
      if (hasStatus403(response)) {
        message = customErrorMessages.thirdParty.noThirdPartyDetailsPermissions;
      } else if (hasStatus404(response) || hasStatus422(response)) {
        message = customErrorMessages.thirdParty.invalidThirdPartyDetailsFilter;
      }
      commit(
        mutationName.THIRD_PARTY.SET_THIRD_PARTY_DETAILS_MESSAGE,
        message,
        {
          root: true
        }
      );
      commit(
        mutationName.THIRD_PARTY.SET_THIRD_PARTY_DETAILS,
        {},
        {
          root: true
        }
      );
    } finally {
      commit(
        mutationName.THIRD_PARTY.SET_IS_LOADING_THIRD_PARTY_DETAILS,
        false,
        {
          root: true
        }
      );
    }
  },
  async fetchThirdPartyRiskAlerts({ rootState, commit }, thirdPartyId = "") {
    const { tenantId } = rootState.company;
    try {
      commit(
        mutationName.THIRD_PARTY.SET_IS_THIRD_PARTY_RISK_ALERTS_LOADING,
        true,
        {
          root: true
        }
      );
      const { data = {} } = await thirdPartyService.fetchThirdPartyRiskAlerts(
        { tenantId, thirdPartyId },
        { root: true }
      );

      commit(mutationName.THIRD_PARTY.SET_THIRD_PARTY_RISK_ALERTS, data, {
        root: true
      });

      if (!data?.alertCategories?.length) {
        return infoMessages.thirdParty.noRiskAlerts;
      }
    } catch {
      commit(
        mutationName.THIRD_PARTY.SET_THIRD_PARTY_RISK_ALERTS,
        {},
        {
          root: true
        }
      );
      const error = new Error(
        customErrorMessages.thirdParty.fetchRiskAlertsError
      );
      throw error;
    } finally {
      commit(
        mutationName.THIRD_PARTY.SET_IS_THIRD_PARTY_RISK_ALERTS_LOADING,
        false,
        {
          root: true
        }
      );
    }
  }
};
