<template>
  <div class="base-grid-component-wrapper" :style="getParentStyles()">
    <div v-if="getListOptions()" class="icon-row-wrapper">
      <component
        v-for="(listItem, index) in getListOptions()"
        :key="index"
        v-bind="listItem.componentOptions"
        :is="listItem.component"
        data-test-id="base-grid-component-wrapper__component-list"
      >
        {{ listItem.slotData }}
      </component>
    </div>
    <component
      v-else-if="getComponent()"
      v-bind="getComponentOptions()"
      :is="getComponent()"
      data-test-id="base-grid-component-wrapper__component"
    >
      {{ getSlotData() }}
    </component>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseGridComponentWrapper",
  methods: {
    getComponentOptions() {
      return this.params?.value.componentOptions || null;
    },
    getComponent() {
      return this.params?.value?.component || null;
    },
    getSlotData() {
      return this.params?.value?.slotData || null;
    },
    getParentStyles() {
      return this.params?.value?.parentStyles || null;
    },
    getListOptions() {
      return this.params?.value?.listOptions || null;
    }
  }
});
</script>

<style lang="scss" scoped>
.base-grid-component-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  position: relative;
}
.icon-row-wrapper {
  display: flex;
  gap: $spacing4;
  align-items: center;
}
</style>
