<!-- eslint-disable vue/require-component-is -->
<template>
  <b-overlay :show="isLoading">
    <div v-if="!isLoading">
      <div v-for="attributeData in attributes" :key="attributeData.id">
        <template v-if="showCustomAttribute(attributeData)">
          <component
            :is="thirdPartyTypeComponent[attributeData.tp_type]"
            :attributeData="attributeData"
            :values="values[attributeData.id]"
            :user-options="platformUserList"
            :data-test-id="
              getCustomAttributesDataTestId(attributeData.tp_customatt)
            "
          />
        </template>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  attributeType,
  permissionTypes,
  searchStatus,
  getterName
} from "@/constants";
import InputGroup from "@/components/eb2/manageThirdParty/customAttributeComponent/input";
import CheckBox from "@/components/eb2/manageThirdParty/customAttributeComponent/checkbox";
import FileGroup from "@/components/eb2/manageThirdParty/customAttributeComponent/file";
import TextArea from "@/components/eb2/manageThirdParty/customAttributeComponent/textarea";
import DropDown from "@/components/eb2/manageThirdParty/customAttributeComponent/dropdown";
import Multiselect from "@/components/eb2/manageThirdParty/customAttributeComponent/multiselect";
import CountryList from "@/components/eb2/manageThirdParty/customAttributeComponent/countryList";
import HtmlString from "@/components/eb2/manageThirdParty/customAttributeComponent/htmlString";
import Radio from "@/components/eb2/manageThirdParty/customAttributeComponent/radio";
import User from "@/components/eb2/manageThirdParty/customAttributeComponent/user";
import permissionsMixin from "@/mixins/permissions/permissions.mixin";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ThirdPartyCustomAttributes",
  components: {
    InputGroup,
    CheckBox,
    FileGroup,
    TextArea,
    DropDown,
    Multiselect,
    CountryList,
    HtmlString,
    Radio,
    User
  },
  mixins: [permissionsMixin],
  props: {
    attributes: {
      type: Array,
      require: true
    },
    values: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      thirdPartyTypeComponent: {
        [attributeType.TEXT]: "InputGroup",
        [attributeType.INTEGER]: "InputGroup",
        [attributeType.DOUBLE]: "InputGroup",
        [attributeType.COLOR]: "InputGroup",
        [attributeType.EMAIL]: "InputGroup",
        [attributeType.URL]: "InputGroup",
        [attributeType.DATE]: "InputGroup",
        [attributeType.BOOLEAN]: "CheckBox",
        [attributeType.FILE]: "FileGroup",
        [attributeType.IMAGE]: "FileGroup",
        [attributeType.TEXTAREA]: "TextArea",
        [attributeType.DROPDOWN]: "DropDown",
        [attributeType.RATING]: "DropDown",
        [attributeType.MULTISELECT]: "Multiselect",
        [attributeType.COUNTRY_LIST]: "CountryList",
        [attributeType.HTML_STRING]: "HtmlString",
        [attributeType.RADIO]: "Radio",
        [attributeType.USER]: "User",
        [attributeType.MULTISELECT_COUNTRIES]: "Multiselect"
      }
    };
  },
  computed: {
    ...mapState({
      platformUserList: (state) => state.platformSettings.platformUserList
    }),
    ...mapGetters({
      isOpenCorporateDataEnabled:
        getterName.COMPANY.IS_OPEN_CORPORATE_DATA_ENABLED,
      isOrbiaOnboardingFormEnabled:
        getterName.COMPANY.IS_ORBIA_ONBOARDING_FORM_ENABLED
    }),
    hasOrbiaCustomFunctionality() {
      return (
        this.isOpenCorporateDataEnabled && this.isOrbiaOnboardingFormEnabled
      );
    }
  },
  methods: {
    showCustomAttribute(attributeData) {
      const hasPermission =
        this.hasPermission(
          permissionTypes.EDIT_CUSTOM_ATTRIBUTE_VALUE,
          attributeData.id
        ) && this.isThirdPartyCustomAttributeSearchable(attributeData);

      if (!hasPermission) {
        return false;
      }

      if (
        this.hasOrbiaCustomFunctionality &&
        attributeData?.is_orbia_attribute &&
        !attributeData?.show_orbia_attribute
      ) {
        return false;
      }

      return true;
    },
    isThirdPartyCustomAttributeSearchable({ tp_is_search }) {
      return parseInt(tp_is_search) === searchStatus.SEARCHABLE;
    },
    getCustomAttributesDataTestId(attributeName) {
      return `add-third-party-modal__additional-details--${attributeName
        .replace(/ /g, "-")
        .toLowerCase()}`;
    }
  }
};
</script>
