import {
  variableType,
  variableAssignmentType,
  variableScope,
  expectedDataMappingKeyDTO,
  expectedDataMappingKeyAPIDTO,
  validity
} from "@/constants";
import { makeUUID, isExpectedDataEntityType } from "@/utils";
import { makeValueAPIDTO } from "@/services/actions/dto/setStepDTO/setStep.dto";

const makeExpectedDataMappingProxyVariableEntityDTO = ({
  data = {},
  _makeUUID = makeUUID
} = {}) => {
  const localVariableData =
    data?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
      expectedDataMappingKeyAPIDTO.DATA
    ]?.[expectedDataMappingKeyAPIDTO.DATA] || {};
  return {
    [expectedDataMappingKeyDTO.ID]: _makeUUID(),
    [expectedDataMappingKeyDTO.TRIGGER_DATA]: {
      [expectedDataMappingKeyDTO.DATA]: {
        [expectedDataMappingKeyDTO.NAME]:
          localVariableData?.[expectedDataMappingKeyAPIDTO.NAME],
        [expectedDataMappingKeyDTO.ENTITY]:
          localVariableData?.[expectedDataMappingKeyAPIDTO.ENTITY],
        [expectedDataMappingKeyDTO.ENTITY_NAME]:
          localVariableData?.[expectedDataMappingKeyAPIDTO.ENTITY_NAME],
        [expectedDataMappingKeyDTO.PROXY_NAME]:
          localVariableData?.[expectedDataMappingKeyAPIDTO.PROXY_NAME],
        [expectedDataMappingKeyDTO.ATTRIBUTE_NAME]:
          localVariableData?.[expectedDataMappingKeyAPIDTO.ATTRIBUTE_NAME],
        [expectedDataMappingKeyDTO.CONTEXT]: {
          [expectedDataMappingKeyDTO.FORM_ID]:
            localVariableData?.[expectedDataMappingKeyAPIDTO.CONTEXT]?.[
              expectedDataMappingKeyAPIDTO.FORM_ID
            ]
        }
      },
      [expectedDataMappingKeyDTO.TYPE]: variableType.PROXY
    },
    [expectedDataMappingKeyDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
      data?.[expectedDataMappingKeyAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
  };
};

const makeExpectedDataMappingLiteralDTO = (expectedData) => {
  const type =
    expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
      expectedDataMappingKeyAPIDTO.DATA
    ]?.[expectedDataMappingKeyAPIDTO.TYPE];
  const value =
    expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
      expectedDataMappingKeyAPIDTO.DATA
    ]?.[expectedDataMappingKeyAPIDTO.DATA]?.[
      expectedDataMappingKeyAPIDTO.VALUE
    ];
  return {
    [expectedDataMappingKeyDTO.ID]: makeUUID(),
    [expectedDataMappingKeyDTO.TRIGGER_DATA]: {
      [expectedDataMappingKeyDTO.DATA]: {
        [expectedDataMappingKeyDTO.DATA]: {
          [expectedDataMappingKeyDTO.VALUE]:
            type === variableType.BOOLEAN ? `${value}` : value
        },
        [expectedDataMappingKeyDTO.TYPE]: type || variableType.STRING
      },
      [expectedDataMappingKeyDTO.TYPE]:
        expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
          expectedDataMappingKeyAPIDTO.TYPE
        ] || variableAssignmentType.LITERAL
    },
    [expectedDataMappingKeyDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
      expectedData?.[
        expectedDataMappingKeyAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME
      ] || ""
  };
};
const makeExpectedDataMappingLiteralAPIDTO = ({
  value = "",
  variableName = "",
  valueType = "",
  valueDataType = "",
  _makeValueAPIDTO = makeValueAPIDTO
} = {}) => ({
  [expectedDataMappingKeyAPIDTO.TRIGGER_DATA]: {
    [expectedDataMappingKeyAPIDTO.DATA]: {
      [expectedDataMappingKeyAPIDTO.DATA]: {
        [expectedDataMappingKeyAPIDTO.VALUE]: _makeValueAPIDTO({
          value,
          valueDataType,
          variableType,
          validity
        })
      },
      [expectedDataMappingKeyAPIDTO.TYPE]: valueDataType
    },
    [expectedDataMappingKeyAPIDTO.TYPE]: valueType
  },
  [expectedDataMappingKeyAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    variableName
});
const makeExpectedDataMappingVariableLocalDTO = (data) => {
  const localVariableData =
    data?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
      expectedDataMappingKeyAPIDTO.DATA
    ]?.[expectedDataMappingKeyAPIDTO.DATA]?.[
      expectedDataMappingKeyAPIDTO.DATA
    ] || [];

  return {
    [expectedDataMappingKeyDTO.ID]: makeUUID(),
    [expectedDataMappingKeyDTO.TRIGGER_DATA]: {
      [expectedDataMappingKeyDTO.DATA]: {
        [expectedDataMappingKeyDTO.DATA]: {
          [expectedDataMappingKeyDTO.DATA]: {
            [expectedDataMappingKeyDTO.NAME]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.NAME],
            [expectedDataMappingKeyDTO.SUB_TYPE]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.SUB_TYPE],
            [expectedDataMappingKeyDTO.IS_ENUM]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.IS_ENUM],
            [expectedDataMappingKeyDTO.IS_ARRAY]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.IS_ARRAY],
            [expectedDataMappingKeyDTO.IS_READ_ONLY]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.IS_READ_ONLY],
            [expectedDataMappingKeyDTO.IS_CUSTOM_ATTRIBUTE]:
              localVariableData?.[
                expectedDataMappingKeyAPIDTO.IS_CUSTOM_ATTRIBUTE
              ],
            [expectedDataMappingKeyDTO.IDENTIFIER]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.IDENTIFIER]
          },
          [expectedDataMappingKeyDTO.TYPE]:
            data?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
              expectedDataMappingKeyAPIDTO.DATA
            ]?.[expectedDataMappingKeyAPIDTO.DATA]?.[
              expectedDataMappingKeyAPIDTO.TYPE
            ] || ""
        },
        [expectedDataMappingKeyDTO.TYPE]:
          data?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
            expectedDataMappingKeyAPIDTO.DATA
          ]?.[expectedDataMappingKeyAPIDTO.TYPE] || variableScope.LOCAL
      },
      [expectedDataMappingKeyDTO.TYPE]:
        data?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
          expectedDataMappingKeyAPIDTO.TYPE
        ] || variableType.VARIABLE
    },
    [expectedDataMappingKeyDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
      data?.[expectedDataMappingKeyAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
  };
};
const makeExpectedDataMappingVariableLocalAPIDTO = ({
  variableName = "",
  value = "",
  valueType = "",
  valueDataType = ""
} = {}) => ({
  [expectedDataMappingKeyAPIDTO.TRIGGER_DATA]: {
    [expectedDataMappingKeyAPIDTO.DATA]: {
      [expectedDataMappingKeyAPIDTO.DATA]: {
        [expectedDataMappingKeyAPIDTO.DATA]: {
          [expectedDataMappingKeyAPIDTO.NAME]: value
        },
        [expectedDataMappingKeyAPIDTO.TYPE]: valueDataType
      },
      [expectedDataMappingKeyAPIDTO.TYPE]: valueType
    },
    [expectedDataMappingKeyAPIDTO.TYPE]: variableType.VARIABLE
  },
  [expectedDataMappingKeyAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    variableName
});
const makeExpectedDataMappingVariableEntityDTO = (expectedData) => {
  const localVariableData =
    expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
      expectedDataMappingKeyAPIDTO.DATA
    ]?.[expectedDataMappingKeyAPIDTO.DATA]?.[expectedDataMappingKeyAPIDTO.DATA];
  return {
    [expectedDataMappingKeyDTO.ID]: makeUUID(),
    [expectedDataMappingKeyDTO.TRIGGER_DATA]: {
      [expectedDataMappingKeyDTO.DATA]: {
        [expectedDataMappingKeyDTO.DATA]: {
          [expectedDataMappingKeyDTO.DATA]: {
            [expectedDataMappingKeyDTO.NAME]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.NAME],
            [expectedDataMappingKeyDTO.SUB_TYPE]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.SUB_TYPE],
            [expectedDataMappingKeyDTO.IS_ENUM]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.IS_ENUM],
            [expectedDataMappingKeyDTO.IS_ARRAY]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.IS_ARRAY],
            [expectedDataMappingKeyDTO.IS_READ_ONLY]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.IS_READ_ONLY],
            [expectedDataMappingKeyDTO.IS_CUSTOM_ATTRIBUTE]:
              localVariableData?.[
                expectedDataMappingKeyAPIDTO.IS_CUSTOM_ATTRIBUTE
              ],
            [expectedDataMappingKeyDTO.IDENTIFIER]:
              localVariableData?.[expectedDataMappingKeyAPIDTO.IDENTIFIER]
          },
          [expectedDataMappingKeyDTO.TYPE]:
            expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
              expectedDataMappingKeyAPIDTO.DATA
            ]?.[expectedDataMappingKeyAPIDTO.DATA]?.[
              expectedDataMappingKeyAPIDTO.TYPE
            ] || ""
        },
        [expectedDataMappingKeyDTO.TYPE]:
          expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
            expectedDataMappingKeyAPIDTO.DATA
          ]?.[expectedDataMappingKeyAPIDTO.TYPE] || variableScope.ENTITY
      },
      [expectedDataMappingKeyDTO.TYPE]:
        expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
          expectedDataMappingKeyAPIDTO.TYPE
        ] || variableType.VARIABLE
    },
    [expectedDataMappingKeyDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
      expectedData?.[
        expectedDataMappingKeyAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME
      ]
  };
};
const makeExpectedDataMappingEntityProxyPropertyAPIDTO = ({
  variableName,
  expectedData
}) => ({
  [expectedDataMappingKeyAPIDTO.TRIGGER_DATA]: {
    [expectedDataMappingKeyAPIDTO.DATA]: {
      [expectedDataMappingKeyAPIDTO.DATA]: {
        ...(!!expectedData && expectedData?.data?.data)
      },
      [expectedDataMappingKeyAPIDTO.TYPE]: variableType.PROXY
    },
    [expectedDataMappingKeyAPIDTO.TYPE]: variableType.VARIABLE
  },
  [expectedDataMappingKeyAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    variableName
});
const makeExpectedDataMappingProxyVariableAPIDTO = ({
  value = "",
  variableName = ""
} = {}) => ({
  [expectedDataMappingKeyAPIDTO.TRIGGER_DATA]: {
    [expectedDataMappingKeyAPIDTO.DATA]: {
      [expectedDataMappingKeyAPIDTO.NAME]: value
    },
    [expectedDataMappingKeyAPIDTO.TYPE]: variableType.PROXY
  },
  [expectedDataMappingKeyAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    variableName
});
const makeExpectedDataMappingProxyAPIDTO = ({
  variableName = "",
  value = "",
  expectedData = {},
  _makeExpectedDataMappingEntityProxyPropertyAPIDTO = makeExpectedDataMappingEntityProxyPropertyAPIDTO,
  _makeExpectedDataMappingProxyVariableAPIDTO = makeExpectedDataMappingProxyVariableAPIDTO
} = {}) => {
  if (Object.keys(expectedData).length) {
    return _makeExpectedDataMappingEntityProxyPropertyAPIDTO({
      variableName,
      expectedData
    });
  } else {
    return _makeExpectedDataMappingProxyVariableAPIDTO({
      value,
      variableName
    });
  }
};
const makeExpectedDataMappingVariableEntityAPIDTO = ({
  expectedData = {},
  variableName = ""
} = {}) => ({
  [expectedDataMappingKeyAPIDTO.TRIGGER_DATA]: {
    [expectedDataMappingKeyAPIDTO.DATA]: {
      [expectedDataMappingKeyAPIDTO.DATA]: {
        [expectedDataMappingKeyAPIDTO.DATA]: {
          ...(!!expectedData && expectedData?.data?.data)
        },
        [expectedDataMappingKeyAPIDTO.TYPE]: expectedData?.data?.type
      },
      [expectedDataMappingKeyAPIDTO.TYPE]: expectedData?.type
    },
    [expectedDataMappingKeyAPIDTO.TYPE]: variableType.VARIABLE
  },
  [expectedDataMappingKeyAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    variableName
});

const makeExpectedDataMappingVariableAPIDTO = ({
  value,
  variableName,
  valueDataType,
  expectedDataType,
  entities,
  _makeExpectedDataMappingVariableLocalAPIDTO = makeExpectedDataMappingVariableLocalAPIDTO,
  _makeExpectedDataMappingVariableEntityAPIDTO = makeExpectedDataMappingVariableEntityAPIDTO,
  _makeExpectedDataMappingProxyAPIDTO = makeExpectedDataMappingProxyAPIDTO,
  _isExpectedDataEntityType = isExpectedDataEntityType
}) => {
  const expectedData = entities.find(
    (entity) => entity.data.data.name === value
  );
  if (_isExpectedDataEntityType(expectedDataType)) {
    return _makeExpectedDataMappingProxyAPIDTO({
      value,
      variableName,
      expectedData
    });
  } else if (expectedData) {
    return _makeExpectedDataMappingVariableEntityAPIDTO({
      expectedData,
      expectedDataType,
      variableName
    });
  } else {
    return _makeExpectedDataMappingVariableLocalAPIDTO({
      value,
      variableName,
      valueDataType,
      valueType: variableScope.LOCAL
    });
  }
};
const makeExpectedDataMappingVariableDTO = ({
  expectedData,
  _makeExpectedDataMappingVariableLocalDTO = makeExpectedDataMappingVariableLocalDTO,
  _makeExpectedDataMappingVariableEntityDTO = makeExpectedDataMappingVariableEntityDTO,
  _makeExpectedDataMappingProxyVariableEntityDTO = makeExpectedDataMappingProxyVariableEntityDTO
} = {}) => {
  if (
    expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
      expectedDataMappingKeyAPIDTO.DATA
    ]?.[expectedDataMappingKeyAPIDTO.TYPE] === variableScope.LOCAL
  ) {
    return _makeExpectedDataMappingVariableLocalDTO(expectedData);
  } else if (
    expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
      expectedDataMappingKeyAPIDTO.DATA
    ]?.[expectedDataMappingKeyAPIDTO.TYPE] === variableScope.ENTITY
  ) {
    return _makeExpectedDataMappingVariableEntityDTO(expectedData);
  } else if (
    expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
      expectedDataMappingKeyAPIDTO.DATA
    ]?.[expectedDataMappingKeyAPIDTO.TYPE] === variableType.PROXY
  ) {
    return _makeExpectedDataMappingProxyVariableEntityDTO({
      data: expectedData
    });
  }
};

const makeNotificationEmailExpectedDataProxyDTO = ({
  expectedData,
  _makeUUID = makeUUID
} = {}) => ({
  [expectedDataMappingKeyDTO.ID]: _makeUUID(),
  [expectedDataMappingKeyDTO.TRIGGER_DATA]: {
    [expectedDataMappingKeyDTO.DATA]: {
      [expectedDataMappingKeyDTO.NAME]:
        expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
          expectedDataMappingKeyAPIDTO.DATA
        ]?.[expectedDataMappingKeyAPIDTO.NAME]
    },
    [expectedDataMappingKeyDTO.TYPE]:
      expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
        expectedDataMappingKeyAPIDTO.TYPE
      ] || variableType.PROXY
  },
  [expectedDataMappingKeyDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectedData?.[
      expectedDataMappingKeyAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME
    ] || ""
});

const makeExpectedDataMappingAPIDTO = ({
  expectedData,
  entities,
  _makeExpectedDataMappingVariableAPIDTO = makeExpectedDataMappingVariableAPIDTO,
  _makeExpectedDataMappingLiteralAPIDTO = makeExpectedDataMappingLiteralAPIDTO
} = {}) => {
  const { value: valueType } = expectedData.getSelectedValueTypeOption();

  if (valueType === variableAssignmentType.ENTITY) {
    return _makeExpectedDataMappingVariableAPIDTO({
      value: expectedData.componentValue,
      variableName: expectedData.expectedDataName,
      valueDataType: expectedData.valueDataType,
      expectedDataType: expectedData.expectedDataType,
      entities
    });
  } else if (valueType === variableAssignmentType.LITERAL) {
    return _makeExpectedDataMappingLiteralAPIDTO({
      value: expectedData.componentValue,
      variableName: expectedData.expectedDataName,
      valueDataType: expectedData.expectedDataType,
      valueType
    });
  }
};
const makeExpectedDataMappingDTO = ({
  expectedData,
  _makeExpectedDataMappingVariableDTO = makeExpectedDataMappingVariableDTO,
  _makeExpectedDataMappingLiteralDTO = makeExpectedDataMappingLiteralDTO,
  _makeNotificationEmailExpectedDataProxyDTO = makeNotificationEmailExpectedDataProxyDTO
}) => {
  if (
    expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
      expectedDataMappingKeyAPIDTO.TYPE
    ] === variableType.PROXY
  ) {
    return _makeNotificationEmailExpectedDataProxyDTO({ expectedData });
  } else if (
    expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
      expectedDataMappingKeyAPIDTO.TYPE
    ] === variableType.VARIABLE
  ) {
    return _makeExpectedDataMappingVariableDTO({ expectedData });
  } else if (
    expectedData?.[expectedDataMappingKeyAPIDTO.TRIGGER_DATA]?.[
      expectedDataMappingKeyAPIDTO.TYPE
    ] === variableAssignmentType.LITERAL
  ) {
    return _makeExpectedDataMappingLiteralDTO(expectedData);
  }
};

export {
  makeExpectedDataMappingDTO,
  makeExpectedDataMappingAPIDTO,
  makeExpectedDataMappingLiteralDTO,
  makeExpectedDataMappingLiteralAPIDTO,
  makeExpectedDataMappingVariableLocalDTO,
  makeExpectedDataMappingVariableLocalAPIDTO,
  makeExpectedDataMappingVariableEntityDTO,
  makeExpectedDataMappingVariableEntityAPIDTO,
  makeExpectedDataMappingVariableAPIDTO,
  makeExpectedDataMappingVariableDTO,
  makeExpectedDataMappingProxyVariableEntityDTO,
  makeExpectedDataMappingProxyAPIDTO,
  makeExpectedDataMappingProxyVariableAPIDTO,
  makeExpectedDataMappingEntityProxyPropertyAPIDTO
};
