import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import {
  makeCustomAttributeChartDataDTO,
  makeTasksStatusWidgetDataDTO,
  makeTasksAgeWidgetDataDTO,
  makeTasksOutcomesWidgetDataDTO,
  makeTasksAssigneeWidgetDataDTO,
  makeAllTrainingWidgetCountDTO,
  makeTrainingStatusWidgetDataDTO
} from "./dto/charts.dto";

const fetchPriChartData = (companyId) =>
  HTTP(
    "post",
    endpoints.CHART_PRI,
    {
      companyId
    },
    makeAuthorizationHeader()
  );

const fetchDdqRiskScoreData = (formId, companyId) =>
  HTTP(
    "get",
    `${endpoints.DASHBOARD_CHART_RISK_SCORE}?formId=${formId}&companyId=${companyId}`,
    null,
    makeAuthorizationHeader()
  );

const fetchTpStatData = (companyId, queryParams) =>
  HTTP(
    "get",
    `${endpoints.CHART_TP_STATE_DATA}/${companyId}`,
    null,
    makeAuthorizationHeader(),
    queryParams
  );

const fetchWorldMapChartsData = (companyId, queryParams) =>
  HTTP(
    "post",
    endpoints.CHART_COUNTRY,
    {
      companyId
    },
    makeAuthorizationHeader(),
    queryParams
  );

const fetchMonitoringAlerts = (postObj, queryParams) =>
  HTTP(
    "post",
    endpoints.CHART_MONITORING_ALERTS,
    postObj,
    makeAuthorizationHeader(),
    queryParams
  );

const fetchSanctionsAlertsCount = (companyId, queryParams) =>
  HTTP(
    "get",
    `${endpoints.CHART_SANCTIONS_ALERT}/${companyId}`,
    null,
    makeAuthorizationHeader(),
    queryParams
  );

const fetchIddPlusPostRemediationCount = (companyId) =>
  HTTP(
    "get",
    `${endpoints.CHART_IDD_PLUS_POST_REMEDIATION}/${companyId}`,
    null,
    makeAuthorizationHeader()
  );

const fetchIddPlusPreRemediationCount = (companyId) =>
  HTTP(
    "get",
    `${endpoints.CHART_IDD_PLUS_PRE_REMEDIATION}/${companyId}`,
    null,
    makeAuthorizationHeader()
  );

const fetchEddResultCount = (companyId) =>
  HTTP(
    "get",
    `${endpoints.CHART_EDD_RESULTS}/${companyId}`,
    null,
    makeAuthorizationHeader()
  );

const fetchTpCountries = (companyId, queryParams) =>
  HTTP(
    "get",
    `${endpoints.CHART_THIRD_PARTY_COUNTRY}/${companyId}`,
    null,
    makeAuthorizationHeader(),
    queryParams
  );

const fetchDashboardDdq = (postObj) =>
  HTTP(
    "post",
    endpoints.DASHBOARD_GET_DDQ_STATE,
    postObj,
    makeAuthorizationHeader()
  );

const fetchCustomAttributeChartData = async (companyId, customAttributeId) => {
  const { data } = await HTTP(
    "get",
    endpoints.CHART_CUSTOM_ATTRIBUTE_DATA(companyId, customAttributeId),
    null,
    makeAuthorizationHeader()
  );

  return makeCustomAttributeChartDataDTO(data?.data);
};

const fetchTasksStatusWidgetData = async ({ companyId, params }) => {
  const response = await HTTP(
    "get",
    endpoints.TASK_STATUS_WIDGET_DATA({ companyId }),
    null,
    makeAuthorizationHeader(),
    params
  );

  response.data = makeTasksStatusWidgetDataDTO(response?.data);

  return response;
};

const fetchTasksAgeWidgetData = async ({ companyId, params }) => {
  const response = await HTTP(
    "get",
    endpoints.TASK_AGE_WIDGET_DATA({ companyId }),
    null,
    makeAuthorizationHeader(),
    params
  );

  response.data = makeTasksAgeWidgetDataDTO(response?.data);

  return response;
};

const fetchTasksOutcomesWidgetData = async ({ companyId, params }) => {
  const response = await HTTP(
    "get",
    endpoints.TASK_OUTCOMES_WIDGET_DATA({ companyId }),
    null,
    makeAuthorizationHeader(),
    params
  );
  response.data = makeTasksOutcomesWidgetDataDTO(response?.data);

  return response;
};

const fetchTasksAssigneeWidgetData = async ({ companyId, params }) => {
  const response = await HTTP(
    "get",
    endpoints.TASK_ASSIGNEE_WIDGET_DATA({ companyId }),
    null,
    makeAuthorizationHeader(),
    params
  );

  response.data = makeTasksAssigneeWidgetDataDTO(response?.data);

  return response;
};

const fetchAllTrainingRegistrationCount = async ({ tenantId }) => {
  const response = await HTTP(
    "get",
    endpoints.ALL_TRAINING_REGISTRATION_COUNT,
    null,
    makeAuthorizationHeader({ tenantId })
  );

  response.data = makeAllTrainingWidgetCountDTO(response?.data);

  return response;
};

const fetchTrainingStatusWidgetData = async ({
  courseId = 0,
  tenantId = 0
} = {}) => {
  const response = await HTTP(
    "get",
    endpoints.TRAINING_STATUS_WIDGET_DATA({ courseId }),
    null,
    makeAuthorizationHeader({ tenantId })
  );

  response.data = makeTrainingStatusWidgetDataDTO(response?.data);
  return response;
};

export const chartsService = {
  fetchPriChartData,
  fetchDdqRiskScoreData,
  fetchTpStatData,
  fetchWorldMapChartsData,
  fetchMonitoringAlerts,
  fetchSanctionsAlertsCount,
  fetchIddPlusPostRemediationCount,
  fetchIddPlusPreRemediationCount,
  fetchEddResultCount,
  fetchTpCountries,
  fetchDashboardDdq,
  fetchCustomAttributeChartData,
  fetchTasksStatusWidgetData,
  fetchTasksAgeWidgetData,
  fetchTasksOutcomesWidgetData,
  fetchTasksAssigneeWidgetData,
  fetchAllTrainingRegistrationCount,
  fetchTrainingStatusWidgetData
};
