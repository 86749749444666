<template>
  <div class="rich-text-wrapper">
    <Label v-bind="labelOptions">
      <template v-for="(_, slot) in $slots">
        <template :slot="slot">
          <slot :name="slot" />
        </template>
      </template>
    </Label>
    <QuillEditor
      class="rich-text-wrapper--rich-text"
      v-bind="richTextOptions"
      v-model="richTextValue"
    />
    <InputErrorMessage :error="error" />
  </div>
</template>

<script>
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import Label from "@/atoms/Label/Label";
import { quillEditor } from "vue-quill-editor";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "RichText",
  props: {
    labelOptions: {
      type: Object,
      default: () => ({})
    },
    richTextOptions: {
      type: Object,
      default: () => ({})
    },
    error: {
      type: String,
      default: ""
    }
  },
  components: {
    QuillEditor: quillEditor,
    Label,
    InputErrorMessage
  },
  compatConfig: { MODE: 3 },
  setup(props) {
    const richTextValue = ref(props.richTextOptions.value);

    watch(
      () => props.richTextOptions.value,
      (value) => {
        richTextValue.value = value;
      }
    );

    return {
      richTextValue
    };
  }
});
</script>

<style lang="scss" scoped>
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";
@import "quill/dist/quill.bubble.css";

.rich-text-wrapper {
  & :deep(.ql-toolbar) {
    border-radius: $border-radius8 $border-radius8 0 0;
  }

  & :deep(.ql-container) {
    border-radius: 0 0 $border-radius8 $border-radius8;
  }
}
</style>
