import {
  breadcrumbRouteNames,
  routeNames,
  templates,
  urls,
  featurePermissionsKeys,
  userPermissionKeysDTO,
  routeKeys,
  getterName
} from "@/constants";
import store from "@/store";
import { hasPermission, hasValidCurrentCompanyIdInUrl } from "@/utils";
import rolesRoutes from "@/routes/roles/roles.routes";
import ultimateBeneficialOwnersProfileRoutes from "@/routes/ubo/ultimateBeneficialOwnersProfile.routes";
import permissionsRoutes from "@/routes/permissions/permissions.routes";
import userEmailTemplatesRoutes from "@/routes/userEmailTemplates/userEmailTemplates.routes";
import taskTemplatesRoutes from "@/routes/taskTemplates/taskTemplates.routes";
import tasksRoutes from "@/routes/tasks/tasks.routes";
import ThirdPartyProfile from "@/pages/eb2/thirdPartyEntityPage";
import ThirdPartyDetailsDnb from "@/components/eb2/manageThirdParty/ThirdPartyDetailsDnb";
import DDQOnboarding from "@/pages/eb2/ddqOnboardingView";
import UserFormPage from "@/pages/user-form";
import DownloadableList from "@/organisms/DownloadableList/DownloadableList";
import BIDashboardTemplate from "@/templates/BIDashboardTemplate/BIDashboardTemplate";
import EventLog from "@/components/eb2/EventLog/eventLog";
import EddReportType from "@/components/eb2/eddReportType";
import NotificationSettings from "@/components/eb2/platformSettings/notificationSettings";
import BrandingAndLinksSettings from "@/components/eb2/platformSettings/brandingAndLinksSettings";
import GeneralSettings from "@/components/eb2/platformSettings/generalSettings";
import AnalyticsOverview from "@/components/eb2/analytics/thirdPartyStatsComponent/analyticsTabChartComponent";
import AnalyticsQuestionare from "@/components/eb2/analytics/thirdPartyStatsComponent/stats";
import StatsComparative from "@/components/eb2/analytics/thirdPartyStatsComponent/statsComparative";
import EmailTemplate from "@/components/eb2/email/emailTemplate";
import DashboardViewPage from "@/components/eb2/analytics/thirdPartyStatsComponent/dashboardViewPage";
import GreenliteThirdParties from "@/molecules/GreenliteThirdParties/GreenliteThirdParties";
import Error404Page from "@/molecules/Error404/Error404";
import UltimateBeneficialOwnersTemplate from "@/templates/UltimateBeneficialOwnersTemplate/UltimateBeneficialOwnersTemplate";
import ActionsTemplate from "@/templates/ActionsTemplate/ActionsTemplate";
import VersionTemplate from "@/templates/VersionTemplate/VersionTemplate";
import AutomationsTemplate from "@/templates/AutomationsTemplate/AutomationsTemplate";
import ManageThirdParties from "@/organisms/ManageThirdParties/ManageThirdParties";
import Users from "@/organisms/Users/Users";
import CustomAttributes from "@/organisms/CustomAttribute/CustomAttributes";
import Actions from "@/organisms/Actions/Actions";
import AddEditAction from "@/organisms/AddEditAction/AddEditAction";
import Automations from "@/organisms/Automations/Automations";
import AddEditAutomation from "@/organisms/AddEditAutomation/AddEditAutomation";
import ManageThirdPartiesUserView from "@/organisms/ManageThirdParties/ManageThirdPartiesUserView";
import QuestionsReference from "@/organisms/QuestionsReference/QuestionsReference";
import InvalidDocumentFormatTemplate from "@/templates/InvalidDocumentFormatTemplate/InvalidDocumentFormatTemplate";
import AccessRestrictedTemplate from "@/templates/AccessRestrictedTemplate/AccessRestrictedTemplate";
import ThirdPartyBulkUpdateTemplate from "@/templates/ThirdPartyBulkUpdateTemplate/ThirdPartyBulkUpdateTemplate";
import ThirdPartyRiskAlerts from "@/organisms/ThirdPartyRiskAlerts/ThirdPartyRiskAlerts";

export default [
  {
    path: urls.USER_FORM_PAGE(),
    name: routeNames[routeKeys.USER_FORM_PAGE],
    props: true,
    component: UserFormPage,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: false
    }
  },
  {
    path: urls.DDQ_ONBOARDING(),
    name: routeNames.DDQ_ONBOARDING,
    component: DDQOnboarding,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.DDQ_ONBOARDING
        }
      ]
    }
  },
  {
    path: urls.THIRD_PARTY_PROFILE_COMPANY(),
    name: routeNames.THIRD_PARTY_PROFILE_COMPANY,
    component: ThirdPartyProfile,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.THIRD_PARTY_PROFILE
        }
      ]
    }
  },
  {
    path: urls.THIRD_PARTY_PROFILE(),
    name: routeNames.THIRD_PARTY_PROFILE,
    component: ThirdPartyProfile,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.THIRD_PARTY_PROFILE
        }
      ]
    }
  },
  {
    path: urls.THIRD_PARTY_CREATE,
    name: routeNames.THIRD_PARTY_CREATE,
    component: ThirdPartyDetailsDnb,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.THIRD_PARTY_CREATE
        }
      ]
    }
  },
  {
    path: urls.THIRD_PARTY_UPDATE(),
    name: routeNames.THIRD_PARTY_UPDATE,
    component: ThirdPartyDetailsDnb,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: (route) => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.THIRD_PARTY_PROFILE,
          link: urls.THIRD_PARTY_PROFILE_COMPANY(
            route?.params?.id,
            route?.params?.companyId
          )
        },
        {
          name: breadcrumbRouteNames.THIRD_PARTY_UPDATE
        }
      ]
    },
    beforeEnter(to, from, next) {
      if (hasValidCurrentCompanyIdInUrl(parseInt(to?.params?.companyId))) {
        next();
      } else {
        next(urls.ACCESS_RESTRICTED);
      }
    }
  },
  {
    path: urls.MANAGE_THIRD_PARTY,
    name: routeNames.MANAGE_THIRD_PARTY,
    component: ManageThirdParties,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.MANAGE_THIRD_PARTY
        }
      ]
    }
  },
  {
    path: urls.MANAGE_THIRD_PARTY_ID(),
    name: routeNames.MANAGE_THIRD_PARTY_ID,
    component: ManageThirdPartiesUserView,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.MANAGE_THIRD_PARTY_ID
        }
      ]
    },
    beforeEnter(to, from, next) {
      if (
        hasPermission({
          key: featurePermissionsKeys.VIEW_USER_VIEW,
          contextId: parseInt(to?.params?.id),
          contextKey: userPermissionKeysDTO.USER_VIEW_ID
        })
      ) {
        next();
      } else {
        next(urls.ACCESS_RESTRICTED);
      }
    }
  },
  {
    path: urls.ESG_THIRD_PARTIES,
    name: routeNames.ESG_THIRD_PARTIES,
    component: GreenliteThirdParties,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.ESG_THIRD_PARTIES
        }
      ]
    }
  },
  {
    path: urls.ULTIMATE_BENEFICIAL_OWNERS,
    name: routeNames.ULTIMATE_BENEFICIAL_OWNERS,
    component: UltimateBeneficialOwnersTemplate,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.ULTIMATE_BENEFICIAL_OWNERS
        }
      ]
    }
  },
  {
    path: urls.GREENLITE_THIRD_PARTIES,
    name: routeNames.GREENLITE_THIRD_PARTIES,
    redirect: () => urls.ESG_THIRD_PARTIES,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.GREENLITE_THIRD_PARTIES
        }
      ]
    }
  },
  {
    path: urls.ANALYTICS_OVERVIEW,
    name: routeNames.ANALYTICS_OVERVIEW,
    component: AnalyticsOverview,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.ANALYTICS_OVERVIEW
        }
      ]
    }
  },
  {
    path: urls.ANALYTICS_QUESTIONNAIRES_ID(),
    name: routeNames.ANALYTICS_QUESTIONNAIRES_ID,
    component: AnalyticsQuestionare,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: `${breadcrumbRouteNames.ANALYTICS_QUESTIONNAIRES_ID}`
        }
      ]
    }
  },
  {
    path: urls.ANALYTICS_QUESTIONNAIRES_COMPARATIVE(),
    name: routeNames.ANALYTICS_QUESTIONNAIRES_COMPARATIVE,
    component: StatsComparative,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: `${breadcrumbRouteNames.ANALYTICS_QUESTIONNAIRES_COMPARATIVE}`
        }
      ]
    }
  },
  {
    path: urls.EDD_REPORT_TYPE,
    name: routeNames.EDD_REPORT_TYPE,
    component: EddReportType,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.EDD_REPORT_TYPE
        }
      ]
    }
  },
  {
    path: urls.EVENT_LOG,
    name: routeNames.EVENT_LOG,
    component: EventLog,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.EVENT_LOG
        }
      ]
    }
  },
  {
    path: urls.EVENT_LOG_ID(),
    name: routeNames.EVENT_LOG_ID,
    component: EventLog,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.EVENT_LOG_ID
        }
      ]
    },
    beforeEnter(to, from, next) {
      if (
        hasPermission({
          key: featurePermissionsKeys.VIEW_USER_VIEW,
          contextId: parseInt(to?.params?.id),
          contextKey: userPermissionKeysDTO.USER_VIEW_ID
        })
      ) {
        next();
      } else {
        next(urls.ACCESS_RESTRICTED);
      }
    }
  },
  {
    path: urls.USER_SETTINGS,
    name: routeNames.USER_SETTINGS,
    component: Users,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        { name: breadcrumbRouteNames.USER_SETTINGS, link: urls.USER_SETTINGS }
      ]
    }
  },
  {
    path: urls.GENERAL_SETTINGS,
    name: routeNames.GENERAL_SETTINGS,
    component: GeneralSettings,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.GENERAL_SETTINGS,
          link: urls.GENERAL_SETTINGS
        }
      ]
    }
  },
  {
    path: urls.NOTIFICATION_SETTINGS,
    name: routeNames.NOTIFICATION_SETTINGS,
    component: NotificationSettings,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.NOTIFICATION_SETTINGS,
          link: urls.NOTIFICATION_SETTINGS
        }
      ]
    }
  },
  {
    path: urls.BRANDING_AND_LINKS_SETTINGS,
    name: routeNames.BRANDING_AND_LINKS_SETTINGS,
    component: BrandingAndLinksSettings,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.BRANDING_AND_LINKS_SETTINGS,
          link: urls.BRANDING_AND_LINKS_SETTINGS
        }
      ]
    }
  },
  {
    path: urls.CUSTOM_ATTRIBUTES,
    name: routeNames.CUSTOM_ATTRIBUTES,
    component: CustomAttributes,
    props: true,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.CUSTOM_ATTRIBUTES,
          link: urls.CUSTOM_ATTRIBUTES
        }
      ]
    }
  },
  {
    path: urls.EMAIL_TEMPLATE,
    name: routeNames.EMAIL_TEMPLATE,
    component: EmailTemplate,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.EMAIL_TEMPLATE,
          link: urls.EMAIL_TEMPLATE
        }
      ]
    }
  },
  {
    path: urls.LIST_OF_SAMPLE_EXCEL_FILES,
    name: routeNames.LIST_OF_SAMPLE_EXCEL_FILES,
    component: DownloadableList,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.LIST_OF_SAMPLE_EXCEL_FILES,
          link: urls.LIST_OF_SAMPLE_EXCEL_FILES
        }
      ]
    }
  },
  {
    path: urls.ANALYTICS_USER_VIEW_ID(),
    name: routeNames.ANALYTICS_USER_VIEW_ID,
    component: DashboardViewPage,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.ANALYTICS_USER_VIEW_ID
        }
      ]
    },
    beforeEnter(to, from, next) {
      if (
        hasPermission({
          key: featurePermissionsKeys.VIEW_USER_VIEW,
          contextId: parseInt(to?.params?.id),
          contextKey: userPermissionKeysDTO.USER_VIEW_ID
        })
      ) {
        next();
      } else {
        next(urls.ACCESS_RESTRICTED);
      }
    }
  },
  {
    path: urls.ACTIONS,
    name: routeNames.ACTIONS_WRAPPER,
    component: ActionsTemplate,
    children: [
      {
        path: "",
        name: routeNames.ACTIONS,
        component: Actions,
        meta: {
          requiresAuth: true,
          template: templates.EB360,
          isEb360HeaderRequired: true,
          isEB360SideBarRequired: true,
          breadcrumbs: () => [
            { name: breadcrumbRouteNames.HOME, link: urls.HOME },
            { name: breadcrumbRouteNames.ACTIONS, link: urls.ACTIONS }
          ]
        }
      },
      {
        path: urls.EDIT_ACTION,
        name: routeNames.EDIT_ACTION,
        redirect: () => urls.ACTIONS,
        meta: {
          requiresAuth: true,
          template: templates.EB360,
          isEb360HeaderRequired: true,
          isEB360SideBarRequired: true
        }
      },
      {
        path: urls.EDIT_ACTION_ID(),
        name: routeNames.EDIT_ACTION_ID,
        component: AddEditAction,
        meta: {
          requiresAuth: true,
          template: templates.EB360,
          isEb360HeaderRequired: true,
          isEB360SideBarRequired: true,
          breadcrumbs: () => [
            { name: breadcrumbRouteNames.HOME, link: urls.HOME },
            { name: breadcrumbRouteNames.ACTIONS, link: urls.ACTIONS },
            { name: breadcrumbRouteNames.EDIT_ACTION, link: urls.EDIT_ACTION }
          ]
        }
      },
      {
        path: urls.ADD_ACTION,
        name: routeNames.ADD_ACTION,
        component: AddEditAction,
        meta: {
          requiresAuth: true,
          template: templates.EB360,
          isEb360HeaderRequired: true,
          isEB360SideBarRequired: true,
          breadcrumbs: () => [
            { name: breadcrumbRouteNames.HOME, link: urls.HOME },
            { name: breadcrumbRouteNames.ACTIONS, link: urls.ACTIONS },
            { name: breadcrumbRouteNames.ADD_ACTION, link: urls.EDIT_ACTION }
          ]
        }
      },
      {
        path: urls.QUESTIONS_REFERENCE(),
        name: routeNames.QUESTIONS_REFERENCE,
        component: QuestionsReference,
        meta: {
          requiresAuth: true,
          template: templates.EB360,
          isEb360HeaderRequired: true,
          isEB360SideBarRequired: true,
          breadcrumbs: () => [
            { name: breadcrumbRouteNames.HOME, link: urls.HOME },
            { name: breadcrumbRouteNames.QUESTIONS_REFERENCE }
          ]
        },
        props: (route) => ({ id: route.params.id })
      }
    ]
  },
  {
    path: urls.AUTOMATIONS,
    name: routeNames.AUTOMATIONS_WRAPPER,
    component: AutomationsTemplate,
    children: [
      {
        path: "",
        name: routeNames.AUTOMATIONS,
        component: Automations,
        meta: {
          requiresAuth: true,
          template: templates.EB360,
          isEb360HeaderRequired: true,
          isEB360SideBarRequired: true,
          breadcrumbs: () => [
            { name: breadcrumbRouteNames.HOME, link: urls.HOME },
            { name: breadcrumbRouteNames.AUTOMATIONS, link: urls.AUTOMATIONS }
          ]
        }
      },
      {
        path: urls.EDIT_AUTOMATION,
        name: routeNames.EDIT_AUTOMATION,
        redirect: () => urls.AUTOMATIONS,
        meta: {
          requiresAuth: true,
          template: templates.EB360,
          isEb360HeaderRequired: true,
          isEB360SideBarRequired: true
        }
      },
      {
        path: urls.EDIT_AUTOMATION_ID(),
        name: routeNames.EDIT_AUTOMATION_ID,
        component: AddEditAutomation,
        meta: {
          requiresAuth: true,
          template: templates.EB360,
          isEb360HeaderRequired: true,
          isEB360SideBarRequired: true,
          breadcrumbs: () => [
            { name: breadcrumbRouteNames.HOME, link: urls.HOME },
            { name: breadcrumbRouteNames.AUTOMATIONS, link: urls.AUTOMATIONS },
            {
              name: breadcrumbRouteNames.EDIT_AUTOMATION,
              link: urls.EDIT_AUTOMATION
            }
          ]
        }
      },
      {
        path: urls.ADD_AUTOMATION,
        name: routeNames.ADD_AUTOMATION,
        component: AddEditAutomation,
        meta: {
          requiresAuth: true,
          template: templates.EB360,
          isEb360HeaderRequired: true,
          isEB360SideBarRequired: true,
          breadcrumbs: () => [
            { name: breadcrumbRouteNames.HOME, link: urls.HOME },
            { name: breadcrumbRouteNames.AUTOMATIONS, link: urls.AUTOMATIONS },
            {
              name: breadcrumbRouteNames.ADD_AUTOMATION,
              link: urls.ADD_AUTOMATION
            }
          ]
        }
      }
    ]
  },
  rolesRoutes,
  userEmailTemplatesRoutes,
  taskTemplatesRoutes,
  ...ultimateBeneficialOwnersProfileRoutes,
  ...permissionsRoutes,
  tasksRoutes,
  {
    path: urls.INVALID_DOCUMENT_FORMAT,
    name: routeNames.INVALID_DOCUMENT_FORMAT,
    component: InvalidDocumentFormatTemplate,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true
    }
  },
  {
    path: urls.ACCESS_RESTRICTED,
    name: routeNames.ACCESS_RESTRICTED,
    component: AccessRestrictedTemplate,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true
    }
  },
  {
    path: urls.PRODUCT_VERSION,
    name: routeNames.PRODUCT_VERSION,
    component: VersionTemplate,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.PRODUCT_VERSION,
          link: urls.PRODUCT_VERSION
        }
      ]
    }
  },
  {
    path: urls.BI_DASHBOARD(),
    name: routeNames.BI_DASHBOARD,
    component: BIDashboardTemplate,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.BI_DASHBOARD
        }
      ]
    },
    beforeEnter(to, from, next) {
      if (
        hasPermission({
          key: featurePermissionsKeys.VIEW_USER_VIEW,
          contextId: parseInt(to?.params?.id),
          contextKey: userPermissionKeysDTO.USER_VIEW_ID
        })
      ) {
        next();
      } else {
        next(urls.ACCESS_RESTRICTED);
      }
    }
  },
  {
    path: urls.BULK_UPDATE,
    name: routeNames.BULK_UPDATE,
    component: ThirdPartyBulkUpdateTemplate,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: () => [
        {
          name: breadcrumbRouteNames.HOME,
          link: urls.HOME
        },
        {
          name: breadcrumbRouteNames.BULK_UPDATE
        }
      ]
    }
  },
  {
    path: urls[routeKeys.RISK_ALERT_SUMMARY](),
    name: routeNames.RISK_ALERT_SUMMARY,
    component: ThirdPartyRiskAlerts,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: (route) => [
        {
          name: breadcrumbRouteNames.HOME,
          link: urls.HOME
        },
        {
          name: breadcrumbRouteNames.THIRD_PARTY_PROFILE,
          link: urls.THIRD_PARTY_PROFILE_COMPANY(
            route?.params?.id,
            route?.params?.compId
          )
        },
        {
          name: breadcrumbRouteNames.RISK_ALERT_SUMMARY
        }
      ]
    },
    beforeEnter(to, from, next) {
      if (store.getters[getterName.COMPANY.HAS_NEW_THIRD_PARTY_PROFILE]) {
        next();
      } else {
        next(urls.PAGE_NOT_FOUND);
      }
    }
  },
  {
    path: urls.PAGE_NOT_FOUND,
    name: routeNames.PAGE_NOT_FOUND,
    component: Error404Page,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true
    }
  },
  {
    path: urls.DEFAULT,
    redirect: () => urls.PAGE_NOT_FOUND
  }
];
