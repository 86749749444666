export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolna tabla"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolna tabla usklađenosti"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početna stranica"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učitavanje..."])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šabloni"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upitnici"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popunite upitnike za detaljnu analizu i dostavite dokumentaciju"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostavite upitnik"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrazac je dostavljen"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrđujem gore navedeno"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povratak na kontrolnu tablu"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavite"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum dodeljivanja:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum završetka:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzimanje"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZAVRŠENO"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U TOKU"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIJE POČELO"])},
    "linkUpModalHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребна је додатна радња"])},
    "linkUpModalBodyText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хвала вам што сте попунили Салесфорце образац за обавезну анализу треће стране."])},
    "linkUpModalBodyText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На основу достављених одговора, Салесфорце захтева да ваша организација попуни Салесфорце упитник за дужну пажњу треће стране."])},
    "linkUpModalBodyText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молимо Вас да попуните овај упитник што је пре могуће. Непопуњавање упитника може утицати на захтев ваше организације да ступи у пословни однос са Салесфорце-ом."])},
    "linkUpModalBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Започните Дуе Дилигенце упитник"])},
    "linkUpModalCancelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вратите се на Упитнике"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbrišite red"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredite red"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жао нам је, нема одговарајућих опција."])}
  },
  "training": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obuka"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pročitajte materijale za učenje i pripremite se za ispite"])},
    "trainingTite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Training Courses"])},
    "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code of Conduct"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odustani"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počnite"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavite"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U toku"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Završeno"])},
    "notComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije počelo"])},
    "toFirstSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to first slide"])},
    "toDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povratak na kontrolnu tablu"])},
    "slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing:"])},
    "nextSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Slide"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "startCertification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your certification"])},
    "trainingCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Completed"])},
    "proceedToExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning stage of the training complete. Proceed to examination."])},
    "fromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start From beginning"])},
    "continueTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Training"])},
    "ContinueText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to Continue from slide number"])},
    "ContinueText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or start from beginning"])},
    "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmite datoteku"])}
  },
  "certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikati"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položite ispit i steknite sertifikat"])},
    "noOfQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Questions"])},
    "examTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Time: "])},
    "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mins"])},
    "maxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Attempts"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" attempt(s)"])},
    "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" remaining"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "timeLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preostalo vreme"])},
    "guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuti."])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "summaryText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imate ukupno"])},
    "summaryText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pokušaja za celi ispit i njegova pitanja"])},
    "startNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POČNITE SADA"])},
    "doLate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uradiću to kasnije"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vratite se"])},
    "attemptsToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempts to date:"])},
    "remainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Attempts:"])},
    "maximumReach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of attempts. Please contact the compliance officer to discuss your options"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
    "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "backToQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vratite se"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing:"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "submitAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Answers"])},
    "answerOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer Overview"])},
    "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewed"])},
    "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered"])},
    "notAnswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not answered"])},
    "timeLimitText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a"])},
    "timeLimitText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute timed exam"])},
    "examAboutToStartText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your exam is about to start in"])},
    "examAboutToStartText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "startImmediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Immediately"])},
    "startExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Exam"])},
    "aboutToSubmitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to submit your answers. This will count towards the maximum attempts. If you are happy with your answers then submit."])},
    "aboutToSubmitTextSimple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to submit your exam. This action cannot be undone. Are you sure you want to submit?"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
    "noteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can go back and review your answers before you submit."])},
    "submitExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Exam"])},
    "cancelExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Exam"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "noAnsGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije dat odgovor"])},
    "cancelExamText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to cancel without completing your exam?"])},
    "cancelExamText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelling the exam would count towards your attempts."])},
    "cancelExamText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have"])},
    "cancelExamText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attempt(s) remaining"])},
    "examSubmittedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Submitted"])},
    "closeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odustani"])},
    "thankYouText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for Choosing Our Exam Portal, for further queries please contact our Admin."])},
    "examResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Result"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score:"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["out of"])},
    "yourResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Result:"])},
    "toPassExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Needed To Pass Exam:"])},
    "examStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Status:"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed"])},
    "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations you have passed!"])},
    "PlsTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again"])},
    "goToQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to question"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozorenje!"])},
    "blankAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Cannot Submit Blank Answer/s."])},
    "englishOriginalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Original"])}
  },
  "annual-certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikati"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdite višestruke sertifikate"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravo ćete napustiti stranicu. Sve nesačuvane promene biće izgubljene. Jeste li sigurni da želite napustiti stranicu?"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravo ćete predati svoj upitnik. Ova radnja se ne može poništiti. Jeste li sigurni da želite poslati?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaš obrazac je uspešno poslat."])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronađeni su jedna ili više grešaka ili polja koji nedostaju.  Pregledajte i ažurirajte po potrebi."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozorenje"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspešno sačuvano"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napredak nije sačuvan.  Molimo vas, pokušajte ponovo."])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ažuriranje uspešno"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generišemo vaš PDF..."])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzimanje PDF-a uspešno"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF nije preuzet, pokušajte ponovo"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одјава је успела"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Završena registracija"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ažurirajte profil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pošta"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt detalji"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ažurirajte lozinku"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezime"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt broj"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv radnog mesta"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trenutna šifra"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova šifra"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdite šifru"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite vaše ime"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite vaše prezime"])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite vaš kontakt broj"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite vaše radno mesto ili položaj"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite trenutnu lozinku"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite novu lozinku"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite potvrdu lozinke"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovo polje je obavezno"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinke moraju biti identične"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaš zahtev se obrađuje…"])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevucite i ispustite da otpremite sadržaj!"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...ili kliknite da odaberete datoteku sa svog računara"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv dokumenta"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta datoteke"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veličina datoteke (bajtovi)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proces"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbrišite"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obavezno"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodajte"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbrišite"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetujte"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ažuriranje"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbriši"])},
    "deleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeste li sigurni da želite izbrisati ovu stavku?"])},
    "deleteHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbrišite red"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
    "burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Taiwan"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
    "haitian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haitian Creole"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "khmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "lao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lao"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "portuguesebr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Brazilian"])},
    "portuguesemz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Mozambique"])},
    "portuguesepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Portugal"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "serbian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian"])},
    "sinhalese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
    "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "spanishmx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish Mexican"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevažeći format"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pošta je nevažeća"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevažeća trenutna lozinka."])}
  },
  "privacyStatement": {
    "msgTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politika privatnosti"])},
    "contactUsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The responsible use of personal data is a core value at Novartis. Novartis Group fully respects privacy laws and is subject to an internal framework of privacy rules and policies. The internal transfer of data is governed by Binding Corporate Rules, so called \"BCR\". BCR are a system of privacy principles, rules and tools intended to ensure the protection of personal data. This collection of rules represents today's best practice to meet the European Economic Area's (\"EEA\") data protection requirements for the transfer of personal data within a Group of companies. To be legally effective, the BCR have been approved by EEA Data Protection Agencies. BCR regulate the mechanism of transfer of data inside the Novartis Group of companies."])},
    "contactMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish to contact us regarding how we use your personal data or you wish to exercise your data privacy rights, please email us at"])}
  },
  "footerDashboard": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any information provided will be handled in accordance with Tecan's "])},
    "linkText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politika privatnosti"])},
    "fullTex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any information provided will be handled in accordance with Tecan's Privacy Policy."])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skladištenje u toku..."])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počnite"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sačuvaj"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavite"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otkaži"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odustani"])},
  "okBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbriši"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podnesi"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sačuvaj napredak"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulogujte se"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izlogujte se"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijavite se"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odjavite se"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ažuriranje"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
}