const thirdPartyKeysDTO = {
  NUMBER_OF_EMAILS: "numberOfEmails"
};

const thirdPartyKeysAPIDTO = {
  NUMBER_OF_EMAILS: "numberOfEmails"
};

const thirdPartyRiskAlertsDTO = {
  ALERT_CATEGORIES: "alertCategories",
  TOTAL_ALERTS: "totalAlerts",
  NAME: "name",
  CATEGORY_CODE: "categoryCode",
  COUNT: "count",
  ORGANISATION: "organisation",
  ASSOCIATE: "associate",
  UBO: "UBO",
  ORGANISATION_NAME: "name",
  ALERTS: "alerts",
  REMEDIATION_STATUS: "remediationStatus",
  CRITICAL: "critical",
  SERIOUS: "serious",
  MINOR: "minor",
  MODERATE: "moderate"
};

const thirdPartyRiskAlertsAPIDTO = {
  ALERT_CATEGORIES: "alert_categories",
  TOTAL_ALERTS: "total_alerts",
  NAME: "name",
  CATEGORY_CODE: "category_code",
  COUNT: "count",
  ORGANISATION: "organisation",
  ASSOCIATE: "associate",
  UBO: "ubo",
  ORGANISATION_NAME: "name",
  ALERTS: "alerts",
  REMEDIATION_STATUS: "remediation_status",
  CRITICAL: "critical",
  SERIOUS: "serious",
  MINOR: "minor",
  MODERATE: "moderate"
};

export {
  thirdPartyKeysDTO,
  thirdPartyKeysAPIDTO,
  thirdPartyRiskAlertsDTO,
  thirdPartyRiskAlertsAPIDTO
};
