import {
  actionKeys,
  actionStepType,
  expectedDataTypes,
  getterName,
  operations,
  triggerDataType,
  triggerStepKeys
} from "@/constants";
import triggerStepMixin from "@/organisms/TriggerStep/TriggerStep.mixin";
import { mapGetters } from "vuex";
import TriggerStep from "@/organisms/TriggerStep/TriggerStep";
import {
  getSelectedOption,
  isExpectedDataUniqueInstanceSubType
} from "@/utils";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";

export default {
  mixins: [triggerStepMixin],
  components: {
    TriggerStep
  },
  computed: {
    ...mapGetters({
      getSystemActions: getterName.ACTIONS.GET_SYSTEM_ACTIONS
    }),
    systemActionsOptions() {
      const systemActionsOptions = this.getSystemActions.map(
        ({ name, id }) => ({
          text: name,
          value: id
        })
      );

      return [...this.makeFirstOptionForSelect(), ...systemActionsOptions];
    }
  },
  methods: {
    isExpectedDataUniqueInstanceSubType,
    makeOptionsForSelect,
    makeNewTriggerStep(stepIndex = this.functionSteps.length - 1) {
      this.updateAllStepIndexes(operations.ADD, stepIndex);
      this.functionSteps.splice(
        stepIndex,
        0,
        this.makeTriggerStepAndExtendTriggerStepList(
          undefined,
          this.addBlockIndex
        )
      );
    },
    makeTriggerStepAndExtendTriggerStepList(
      { actionId = "", expectedDataMapping = [], comment = "" } = {},
      index
    ) {
      const expectedDataList = this.makeTriggerStepExpectedDataList(
        actionId,
        index,
        expectedDataMapping
      );
      const triggerStepInstance = this.makeTriggerStep({
        actionNameOptions: this.makeOptionsForSelect(
          actionId,
          this.systemActionsOptions
        ),
        expectDataList: expectedDataList,
        comment
      });
      this.triggerStepList.push({
        stepIndex: index,
        triggerStep: triggerStepInstance
      });

      return triggerStepInstance;
    },
    deleteTriggerStepIndexFromList(index) {
      this.triggerStepList = this.triggerStepList.filter(
        ({ stepIndex }) => stepIndex !== index
      );
    },
    deleteTriggerStepIdFromList(id) {
      this.triggerStepList = this.triggerStepList.filter(
        ({ triggerStep }) => triggerStep.id !== id
      );
    },
    makeSuccessTriggerStep(triggerStep, stepIndex) {
      return {
        stepType: actionStepType.TRIGGER,
        component: TriggerStep,
        componentOptions: this.makeTriggerStepAndExtendTriggerStepList(
          triggerStep,
          stepIndex
        )
      };
    },
    makeSuccessTriggerStepForIfBlock() {
      const { index: stepIndex, event } = this.successStepEvent;
      const successTriggerStep = this.makeSuccessTriggerStep(
        undefined,
        stepIndex
      );
      if (event.property === actionKeys.ELSE_BLOCK) {
        const elseBlocks = [
          ...(this.functionSteps[stepIndex]?.[event.property] || []),
          successTriggerStep
        ];
        this.functionSteps[stepIndex][event.property] = elseBlocks;
      } else {
        const successSteps = [
          ...(this.functionSteps[stepIndex]?.[event.property]?.[event.index]
            ?.successSteps || []),
          successTriggerStep
        ];

        this.functionSteps[stepIndex][event.property][event.index][
          actionKeys.SUCCESS_STEPS
        ] = successSteps;
      }
    },
    updateTriggerStepsIndexes(editType, index) {
      for (
        let triggerStepsIndex = 0;
        triggerStepsIndex < this.triggerStepList.length;
        triggerStepsIndex++
      ) {
        if (this.triggerStepList[triggerStepsIndex].stepIndex >= index) {
          if (
            editType === operations.ADD &&
            this.triggerStepList[triggerStepsIndex].stepIndex >= index
          ) {
            this.triggerStepList[triggerStepsIndex].stepIndex += 1;
          } else if (
            editType === operations.DELETE &&
            this.triggerStepList[triggerStepsIndex].stepIndex > index
          ) {
            this.triggerStepList[triggerStepsIndex].stepIndex -= 1;
          }
        }
      }
    },
    onTriggerStepChange(event, index) {
      if (event.property === triggerStepKeys.EXPECTED_DATA_MAPPING) {
        this.changeTriggerExpectedData(event, index);
      } else if (event.property === triggerStepKeys.ACTIONS) {
        this.changeTriggerActionName(event, index);
      }
    },
    getSearchValueInTriggerExpectedDataMapping({
      expectedDataMapping = [],
      name = ""
    } = {}) {
      if (expectedDataMapping?.length === 0) {
        return "";
      }

      const { triggeringData } =
        expectedDataMapping.find(
          ({ triggeredExpectedDataVariableName = "" } = {}) =>
            triggeredExpectedDataVariableName === name
        ) || {};

      if (triggeringData?.type === triggerDataType.PROXY) {
        return triggeringData.data.name;
      }

      if (triggeringData?.type === triggerDataType.IDENTIFIER) {
        return JSON.stringify(triggeringData.data.value);
      }

      if (triggeringData?.type === triggerDataType.LITERAL) {
        return triggeringData.data.data.value;
      }

      if (triggeringData?.type === triggerDataType.VARIABLE) {
        return triggeringData.data.data.data.name;
      }
    },
    makeTriggerStepExpectedDataOptions({
      options = [],
      index = 0,
      subType = "",
      uniqueType = ""
    } = {}) {
      if (options.length) {
        return options.map(({ text, value }) => ({
          text,
          value: JSON.stringify(value)
        }));
      }

      return this.getAllAvailableOptionsByStepIndex(index).filter((option) => {
        if (this.isExpectedDataUniqueInstanceSubType({ value: uniqueType })) {
          return option.subType === subType;
        } else {
          return option.uniqueType === uniqueType;
        }
      });
    },
    makeTriggerStepExpectedDataList(
      actionId = "",
      index = 0,
      expectedDataMapping = []
    ) {
      return this.getSystemActions
        .find(({ id }) => id === actionId)
        ?.expectedDataDefinition?.variables?.map(
          ({
            templateName = "",
            uniqueType = "",
            subType = "",
            name = "",
            options = []
          } = {}) => {
            const tempOptions = this.makeTriggerStepExpectedDataOptions({
              options,
              index,
              subType,
              uniqueType
            });
            const value = this.getSearchValueInTriggerExpectedDataMapping({
              expectedDataMapping,
              name
            });
            const dropDownOptions = this.makeOptionsForSelect(
              value,
              tempOptions
            );
            const searchValue =
              this.getSelectedOption(dropDownOptions)?.text || value;
            return {
              name,
              searchValue,
              options: dropDownOptions,
              uniqueType,
              templateName,
              subType,
              errorMessage: ""
            };
          }
        );
    },
    changeTriggerExpectedData(event) {
      const { triggerStep } = this.triggerStepList.find(
        ({ triggerStep }) => triggerStep.id === event.id
      );
      const expectedDataObj = triggerStep.expectDataList.find(
        ({ name }) => name === event.name
      );
      expectedDataObj.options = this.makeOptionsForSelect(
        event.event,
        expectedDataObj.options
      );
      expectedDataObj.searchValue = getSelectedOption(
        expectedDataObj.options
      )?.text;
    },
    changeTriggerActionName(event, index, expectedDataMapping = []) {
      const { triggerStep } = this.triggerStepList.find(
        ({ triggerStep }) => triggerStep.id === event.id
      );
      const expectedDataList = this.makeTriggerStepExpectedDataList(
        event.event,
        index,
        expectedDataMapping
      );

      this.setTriggerStep({
        actionNameOptions: this.makeOptionsForSelect(
          event.event,
          this.systemActionsOptions
        ),
        expectDataList: expectedDataList,
        step: triggerStep
      });
    },
    amendSuccessTriggerStep(event, stepIndex) {
      this.onTriggerStepChange(event.event, stepIndex);
    },
    updateAllSuccessTriggerStepsOptions() {
      this.triggerStepList.forEach(({ stepIndex, triggerStep }) => {
        this.updateTriggerStepExpectedDataList({
          triggerStep,
          stepIndex
        });
      });
    },
    updateTriggerStepExpectedDataList({
      triggerStep = {},
      stepIndex = 0
    } = {}) {
      const expectedDataList = triggerStep.expectDataList?.map(
        (expectedData) => {
          if (
            expectedData.uniqueType === expectedDataTypes.DDQ_FORM ||
            expectedData.uniqueType === expectedDataTypes.TRAINING_COURSE
          ) {
            return expectedData;
          } else {
            const options = this.makeTriggerStepExpectedDataOptions({
              index: stepIndex,
              subType: expectedData.subType,
              uniqueType: expectedData.uniqueType
            });
            return {
              ...expectedData,
              options: this.makeOptionsForSelect(
                expectedData.searchValue,
                options
              )
            };
          }
        }
      );
      this.setTriggerStep({
        expectDataList: expectedDataList,
        step: triggerStep
      });
    }
  }
};
