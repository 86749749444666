<template>
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />
    <!-- input content -->

    <b-collapse class="card mt-5" id="collapse-1" v-model="collapse">
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1'"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <div
        :class="
          $store.state.rightAlign
            ? 'card-body text-right'
            : 'card-body text-left'
        "
        :id="fieldData.id"
      >
        <h4 class="d-inline-flex">
          {{
            setEnumeratedFieldNumber({
              enableSectionFieldEnumeration:
                form1.enableSectionFieldEnumeration,
              sectionIndex,
              fieldIndex,
              fieldData,
              selectedLanguage
            })
          }}
        </h4>

        <p>{{ fieldData.description[selectedLanguage] }}</p>
        <div class="form-row">
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.addressDisabled"
          >
            <b-form-group
              :description="fieldData.addressHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.addressValidationMessage[selectedLanguage]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireAddress)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.addressLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.address.$model"
                :placeholder="
                  fieldData.addressPlaceholderText[selectedLanguage]
                "
                :state="
                  v$.form && v$.form.address.$dirty
                    ? !v$.form.address.$error
                    : null
                "
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.addressTwoDisabled"
          >
            <b-form-group
              :description="fieldData.addressTwoHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.addressTwoValidationMessage[selectedLanguage]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireAddressTwo)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.addressTwoLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.addressTwo.$model"
                :placeholder="
                  fieldData.addressTwoPlaceholderText[selectedLanguage]
                "
                :state="
                  v$.form && v$.form.addressTwo.$dirty
                    ? !v$.form.addressTwo.$error
                    : null
                "
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="form-row">
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.countryDisabled"
          >
            <b-form-group
              :description="fieldData.countryHelperText[selectedLanguage]"
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireCountry)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.countryLabel[selectedLanguage] }}
              </label>
              <v-select
                :disabled="isDisabled == 1"
                :class="
                  v$.form.country.$error ? 'border border-danger rounded' : null
                "
                v-model="v$.form.country.$model"
                :state="
                  v$.form && v$.form.country.$dirty
                    ? !v$.form.country.$error
                    : null
                "
                :options="optionsCountries"
                label="name"
                @update:modelValue="onSelected"
                @blur="v$.form.$touch()"
              >
                <template slot="option" slot-scope="option">
                  <country-flag
                    v-if="option.icon"
                    :country="option.icon"
                    size="small"
                  />
                  <span>{{ "&nbsp;" + option.name }}</span>
                </template>
              </v-select>
              <label
                v-if="
                  v$.form.country.$error &&
                  fieldData.validation.isRequireCountry &&
                  !form.country
                "
                class="text-danger"
                style="font-size: 80%"
                >{{
                  fieldData.countryValidationMessage[selectedLanguage]
                }}</label
              >
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.cityStateDisabled"
          >
            <b-form-group
              :description="fieldData.cityStateHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.cityStateValidationMessage[selectedLanguage]
              "
            >
              <label class="text-left">
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireCityState)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.cityStateLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.cityState.$model"
                :placeholder="
                  fieldData.cityStatePlaceholderText[selectedLanguage]
                "
                :state="
                  v$.form && v$.form.cityState.$dirty
                    ? !v$.form.cityState.$error
                    : null
                "
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
                id="inputState"
              ></b-form-input>

              <!-- <b-form-select
								:disabled="isDisabled == 1"
								id="inputState"
								v-model="v$.form.cityState.$model"
								:state="v$.form && v$.form.cityState.$dirty ? !v$.form.cityState.$error : null"
								@blur="v$.form.$touch()"
							>
								<option selected>Choose...</option>
								<option>...</option>
              </b-form-select>-->
            </b-form-group>
          </div>
        </div>
        <div class="form-row">
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.townDisabled"
          >
            <b-form-group
              :description="fieldData.townHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.townValidationMessage[selectedLanguage]
              "
            >
              <label class="text-left">
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireTown)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.townLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.town.$model"
                :placeholder="fieldData.townPlaceholderText[selectedLanguage]"
                :state="
                  v$.form && v$.form.town.$dirty ? !v$.form.town.$error : null
                "
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.zipCodeDisabled"
          >
            <b-form-group
              :description="fieldData.zipCodeHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.zipCodeValidationMessage[selectedLanguage]
              "
            >
              <label class="text-left">
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireZipCode)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.zipCodeLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.zipCode.$model"
                :placeholder="
                  fieldData.zipCodePlaceholderText[selectedLanguage]
                "
                :state="
                  v$.form && v$.form.zipCode.$dirty
                    ? !v$.form.zipCode.$error
                    : null
                "
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
                id="inputZip"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="form-row" v-if="showFreeForm">
          <div
            class="col-md-6"
            v-show="!fieldData.advance || !fieldData.advance.freeFormDisabled"
          >
            <b-form-group
              :description="fieldData.freeFormHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.freeFormValidationMessage[selectedLanguage]
              "
            >
              <label class="text-left">
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireFreeForm)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.freeFormLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :disabled="isDisabled == 1"
                type="text"
                v-model="v$.form.freeForm.$model"
                :placeholder="
                  fieldData.freeFormPlaceholderText[selectedLanguage]
                "
                :state="
                  v$.form && v$.form.freeForm.$dirty
                    ? !v$.form.freeForm.$error
                    : null
                "
                @input="checkFormProgress"
                @blur="v$.form.$touch()"
                id="inputZip"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-collapse>
    <!-- show advance field options -->
    <b-modal
      cancel-title="Close"
      ok-title="save"
      @ok="onSaveData"
      size="xl"
      v-model="showSettings"
      scrollable
      title="Advanced Settings"
    >
      <AdvanceSettings
        :fieldData="fieldData"
        v-on:toggle-field="showSettings = !showSettings"
        :sectionIndex="sectionIndex"
        :fieldIndex="fieldIndex"
      />
    </b-modal>
  </div>
</template>

<script>
import MenuButtons from "../menuButtons";
import AdvanceSettings from "@/components/workflowSettings/advancePersonalAddressSettings";
import { Helper } from "@/helpers";
import { useVuelidate } from "@vuelidate/core";
import { EventBus } from "@/event-bus.js";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import CountryCodes from "@/utils/countryCodesRiskScore.json";
import { routeKeys, routeNames } from "@/constants";
import { clone } from "lodash";

export default {
  name: "AddressCorporateGroup",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    MenuButtons,
    vSelect,
    AdvanceSettings
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    optionsCountries() {
      return CountryCodes[this.fieldData.selectedListTypeIndex?.value || 0]
        .countries;
    }
  },
  data() {
    return {
      showFreeForm: false,
      form: {
        address: null,
        addressTwo: null,
        town: null,
        cityState: null,
        zipCode: null,
        country: null,
        freeForm: null
      },
      postData: [],
      isHidden: false,
      condition: {},
      title: null,
      label: null,
      showSettings: false,
      collapse: true,
      isUpdtating: false,
      selectedRowIndex: null,
      isFocus: false
    };
  },

  mounted() {
    this.setNewConditions();
    this.setFreeFormFields();
    if (
      [
        routeNames[routeKeys.USER_FORM_PAGE],
        routeNames[routeKeys.USER_FORM],
        "MyCompanyView"
      ].includes(this.$route.name)
    ) {
      this.getDataForUser();
    }
  },

  validations() {
    return {
      form: {
        address: {
          [this.address]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireAddress
              ? required
              : !required
        },
        addressTwo: {
          [this.addressTwo]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireAddressTwo
              ? required
              : !required
        },
        town: {
          [this.town]:
            this.fieldData.validation && this.fieldData.validation.isRequireTown
              ? required
              : !required
        },
        cityState: {
          [this.cityState]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireCityState
              ? required
              : !required
        },
        zipCode: {
          [this.zipCode]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireZipCode
              ? required
              : !required
        },
        country: {
          [this.country]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireCountry
              ? required
              : !required
        },
        freeForm: {
          [this.freeForm]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireFreeForm
              ? required
              : !required
        }
      }
    };
  },

  created() {
    EventBus.on("onSubmitEndUserForm", () => {
      this.v$.form?.$touch();
      if (this.v$.form.$errors?.length && this.v$.form.$invalid) {
        this.isFocus = true;
        this.$store.state.validFields = false;
        this.fieldData.isInValid = true;
        return false;
      } else {
        this.isFocus = false;
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
        this.stTempData();
      }
    });

    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },

  methods: {
    setEnumeratedFieldNumber({
      enableSectionFieldEnumeration,
      sectionIndex,
      fieldIndex,
      fieldData,
      selectedLanguage
    }) {
      if (parseInt(enableSectionFieldEnumeration) === 1) {
        return `${sectionIndex + 1}.${fieldIndex + 1} ${
          fieldData.label[selectedLanguage]
        }`;
      }
      return fieldData.label[selectedLanguage];
    },
    setFreeFormFields() {
      Object.assign(this.fieldData, {
        freeFormLabel: {
          english: "Free Form"
        },
        freeFormPlaceholderText: {
          english: ""
        },
        freeFormHelperText: {
          english: ""
        },
        freeFormValidationMessage: {
          english: "This field is required"
        }
      });
      let validation = {
        ...this.fieldData.validation,
        isRequireFreeForm: this.fieldData.validation.isRequireFreeForm || false
      };
      Object.assign(this.fieldData, { validation });
      let advance = {
        ...this.fieldData.advance,
        freeFormDisabled: this.fieldData.advance.freeFormDisabled || true
      };
      Object.assign(this.fieldData, { advance });
      this.showFreeForm = true;
    },

    setNewConditions() {
      Object.assign(this.fieldData, {
        isInValid: this.fieldData.isInValid || null
      });
      Object.assign(this.fieldData, {
        isHidden: this.fieldData.isHidden || false
      });
      Object.assign(this.fieldData, {
        isConditional: this.fieldData.isConditional || 0
      });
      Object.assign(this.fieldData, {
        conditions:
          this.fieldData.conditions && this.fieldData.conditions.length
            ? this.fieldData.conditions
            : []
      });
      Object.assign(this.fieldData, {
        conditionalFields:
          this.fieldData.conditionalFields &&
          this.fieldData.conditionalFields.length
            ? this.fieldData.conditionalFields
            : []
      });
      Object.assign(this.fieldData, {
        sectionConditions:
          this.fieldData.sectionConditions &&
          this.fieldData.sectionConditions.length
            ? this.fieldData.sectionConditions
            : []
      });
    },
    onSelected(value) {
      this.country = value.name;
      this.form.country = value.name;
      this.checkFormProgress();
    },

    getDataForUser() {
      this.$store.state.getUserFormSubmitArray.map((data) => {
        if (data.elements && data.elements.length > 0) {
          data.elements.map((element) => {
            if (data.elementId === this.fieldData.id) {
              (this.form.address =
                element[
                  element.findIndex((x) => x.key == "address")
                ].postValue),
                (this.form.addressTwo =
                  element[
                    element.findIndex((x) => x.key == "addressTwo")
                  ].postValue),
                (this.form.town =
                  element[element.findIndex((x) => x.key == "town")].postValue);
              this.form.cityState =
                element[
                  element.findIndex((x) => x.key == "cityState")
                ].postValue;
              this.form.zipCode =
                element[element.findIndex((x) => x.key == "zipCode")].postValue;
              this.form.country =
                element[element.findIndex((x) => x.key == "country")].postValue;
              this.country =
                element[element.findIndex((x) => x.key == "country")].postValue;
              this.form.freeForm =
                element[
                  element.findIndex((x) => x.key == "freeForm")
                ].postValue;
            }
          });
        }
      });
    },

    setDataToUser() {
      if (
        [
          routeNames[routeKeys.USER_FORM_PAGE],
          routeNames[routeKeys.USER_FORM],
          "MyCompanyView"
        ].includes(this.$route.name)
      ) {
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements = this.postData;
          }
        });
      }
    },

    setValidation() {
      let validation = {
        isRequireAddress: true,
        isRequireAddressTwo: true,
        isRequireTown: true,
        isRequireCityState: true,
        isRequireZipCode: true,
        isRequireCountry: true
      };
      Object.assign(this.fieldData, { validation });
    },

    stTempData() {
      this.postData = [];
      if (
        [
          routeNames[routeKeys.USER_FORM_PAGE],
          routeNames[routeKeys.USER_FORM],
          "MyCompanyView"
        ].includes(this.$route.name)
      ) {
        let aa = [
          {
            postValue: this.form.address,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.addressPostName) ||
              "addressPostName",
            key: "address",
            label: this.fieldData.addressLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.addressTwo,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.addressTwoPostName) ||
              "addressTwoPostName",
            key: "addressTwo",
            label: this.fieldData.addressTwoLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.town,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.townPostName) ||
              "townPostName",
            key: "town",
            label: this.fieldData.townLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.cityState,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.cityStatePostName) ||
              "cityStatePostName",
            key: "cityState",
            label: this.fieldData.cityStateLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.zipCode,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.zipCodePostName) ||
              "zipCodePostName",
            key: "zipCode",
            label: this.fieldData.zipCodeLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.country,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.countryPostName) ||
              "countryPostName",
            key: "country",
            label: this.fieldData.countryLabel[this.selectedLanguage]
          },
          {
            postValue: this.form.freeForm,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.freeFormPostName) ||
              "freeFormPostName",
            key: "freeForm",
            label: this.fieldData.freeFormLabel[this.selectedLanguage]
          }
        ];
        this.postData.push(aa);
        this.setDataToUser();
      }
    },

    checkFormProgress() {
      const registerData = clone(this.$store.state.registerData);

      if (this.v$.form.$errors?.length && this.v$.form.$invalid) {
        delete registerData[this.fieldData.id];
        this.$store.state.registerData = registerData;
      } else {
        registerData[this.fieldData.id] = "personal-address";
        this.$store.state.registerData = registerData;
      }
      const numkeys = Object.keys(registerData).length;
      // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
      this.$store.state.progressValue = numkeys;
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings updated");
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>

<style></style>
