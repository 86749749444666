export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página Inicial"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paine de conformidade"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Página principal"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando..."])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Modelos"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionários"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preencher questionários de devidas diligências e fornecer documentação"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulário enviado "])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulário submetido"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmo as informações acima"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar ao Painel de Controle"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosseguir"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Vista geral"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de atribuição:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de conclusão:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Descarregar"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluir"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em Curso"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não iniciado"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir linha"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar linha"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpe, não há opções correspondentes."])}
  },
  "training": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treinamentos"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leia os materiais de aprendizagem e se prepare para os exames"])},
    "trainingTite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os Meus Cursos de Formação"])},
    "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de conduta"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reveja"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começar"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em Curso"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluir"])},
    "notComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em andamento"])},
    "toFirstSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir para o primeiro diapositivo"])},
    "toDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar ao Painel de Controlo"])},
    "slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diapositivo"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A visualizar:"])},
    "nextSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diapositivo Seguinte"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "startCertification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comece sua certificação"])},
    "trainingCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treinamento Concluído"])},
    "proceedToExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etapa de aprendizagem do treinamento concluída. Prossiga para o exame."])},
    "fromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começar do início"])},
    "continueTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar a Formação"])},
    "ContinueText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quer continuar a partir do Diapositivo Número"])},
    "ContinueText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou prefere começar do início?"])}
  },
  "certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Certificações"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer exame e realizar certificado"])},
    "noOfQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de questões"])},
    "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mins"])},
    "maxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo de tentativas"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tentativa(s)"])},
    "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remanescente"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começar"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente novamente"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falhou"])},
    "timeLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo restante"])},
    "guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientação"])},
    "guidanceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifique-se de ler o material de aprendizagem apropriado antes de concluir o exame."])},
    "guidanceText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifique-se de ler o material de aprendizagem apropriado antes de concluir o exame. Este exame levará cerca de"])},
    "guidanceText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutos."])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo"])},
    "summaryText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você tem um total de"])},
    "summaryText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tentativas para todo o exame e suas questões"])},
    "startNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começar Agora"])},
    "doLate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tratarei disto mais tarde"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regressar"])},
    "attemptsToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentativas:"])},
    "remainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentativas Restantes:"])},
    "maximumReach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você atingiu o número máximo de tentativas. Entre em contato com o diretor de conformidade para discutir suas opções"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questão"])},
    "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está bem"])},
    "backToQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar para Q"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendo:"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima"])},
    "submitAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar respostas"])},
    "answerOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral da resposta"])},
    "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista"])},
    "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respondidas"])},
    "notAnswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não respondido"])},
    "timeLimitText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isto é um"])},
    "timeLimitText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exame cronometrado de minuto"])},
    "examAboutToStartText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu exame está prestes a começar em"])},
    "examAboutToStartText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segundos"])},
    "startImmediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comece imediatamente"])},
    "startExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar exame"])},
    "aboutToSubmitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está prestes a enviar suas respostas. Isso contará para o máximo de tentativas. Se você estiver satisfeito com suas respostas, envie."])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota:"])},
    "noteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode voltar e revisar suas respostas antes de enviar."])},
    "submitExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar exame"])},
    "cancelExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar exame"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "noAnsGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma resposta dada"])},
    "cancelExamText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja cancelar sem concluir o exame?"])},
    "cancelExamText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar o exame contará para suas tentativas."])},
    "cancelExamText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você tem"])},
    "cancelExamText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentativas restantes"])},
    "examSubmittedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exame Enviado"])},
    "closeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perto"])},
    "thankYouText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrigado por escolher nosso portal de exames, para mais perguntas, entre em contato com nosso administrador."])},
    "examResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado de exame"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponto:"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fora de"])},
    "yourResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu resultado:"])},
    "toPassExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação necessária para passar no exame:"])},
    "examStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status do exame:"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passada"])},
    "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parabéns você passou!"])},
    "PlsTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, tente novamente"])},
    "goToQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir para a pergunta"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerta!"])},
    "blankAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não pode enviar respostas em branco."])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está prestes a sair da página. Qualquer mudança não salva será perdida. Você tem certeza de que deseja sair?"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está prestes a enviar seu questionário. Esta ação não pode ser desfeita. Você tem certeza de que deseja enviar?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O seu formulário foi enviado com sucesso. "])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foram encontrados um ou mais campos em falta, ou erros. Reveja e preencha os campos pendentes."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Alerta"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Êxito ao salvar"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Progresso não guardado. Tente novamente"])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Êxito ao atualizar"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Estamos a gerar o seu PDF..."])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Êxito ao descarregar PDF"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PDF não descarregado tente novamente"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Êxito ao terminar sessão"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro Completo"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   Atualizar Perfil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes de contato"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar senha"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeiro Nome"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de contato"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha atual"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Senha"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar senha"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite o seu primeiro nome"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite o seu sobrenome"])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite o seu número de contato"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite o seu cargo ou função"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite a senha atual"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite sua nova senha"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite Confirmar senha"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo é obrigatório"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As senhas devem ser idênticas"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pedido a ser processado..."])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arraste e Solte para carregar o conteúdo!"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["... ou clique para selecionar um arquivo do seu computador"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do ficheiro"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de ficheiro"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho do ficheiro (bytes)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acção"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo é obrigatório"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Repor"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Apagar"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
    "burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Taiwan"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
    "haitian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haitian Creole"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "khmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "lao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lao"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "portuguesebr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Brazilian"])},
    "portuguesemz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Mozambique"])},
    "portuguesepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Portugal"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "serbian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian"])},
    "sinhalese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
    "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "spanishmx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish Mexican"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato inválido"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail é inválido"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha atual inválida."])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" A salvar..."])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começar"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Apagar"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar o progresso do trabalho"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sessão"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminar sessão"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registar"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminar sessão"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])}
}