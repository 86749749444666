const companyFeatures = {
  DNB: "DnB",
  CORPORATE_DATA: "Corporate Data",
  UBO: "UBO",
  MANDATORY_CONTACT_NAME_AND_EMAIL: "Mandatory Contact Name and Email",
  ENABLE_ORBIA_ONBOARDING_FORM: "Enable Orbia onboarding form",
  OPEN_CORPORATE: "Open Corporate",
  PREMIUM_CORPORATE_DATA_PRODUCTS: "Premium Corporate Data Products",
  NEW_THIRD_PARTY_PROFILE: "New Third Party Profile"
};

const companyFeatureValues = {
  ENABLED: "1",
  DISABLED: "0"
};

export { companyFeatures, companyFeatureValues };
