export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Табло за управление"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Табло за съответствие"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареждане..."])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Образци"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въпросници"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попълнете въпросниците за надлежна проверка и предоставете документация"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпрати въпросника"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формулярът е изпратен"])},
    "concent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърждавам горното"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад към таблото"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължи"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ преглед"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на задаване:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на завършване:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтегли"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЗАВЪРШЕНО"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ПРОЦЕС НА ИЗПЪЛНЕНИЕ"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НЕ Е ЗАПОЧНАТО"])},
    "linkUpModalHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изисква се допълнително действие"])},
    "linkUpModalBodyText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарим ви, че попълнихте формуляра за надлежна проверка на Salesforce от трета страна."])},
    "linkUpModalBodyText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въз основа на предоставените отговори, Salesforce изисква вашата организация да попълни въпросника за надлежна проверка на трета страна на Salesforce."])},
    "linkUpModalBodyText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, попълнете този въпросник възможно най-скоро. Неуспехът да попълните въпросника може да засегне заявката на вашата организация да влезе в бизнес отношения със Salesforce."])},
    "linkUpModalBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Започнете въпросника за надлежна проверка"])},
    "linkUpModalCancelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Върнете се към въпросниците"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на ред"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирай реда"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За съжаление няма съвпадащи опции."])}
  },
  "training": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обучения"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прочети материалите за обучение и се подготви за изпити"])},
    "trainingTite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Training Courses"])},
    "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code of Conduct"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Затвори"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължаване"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В процес на изпълнение"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завършване"])},
    "notComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е започнато"])},
    "toFirstSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to first slide"])},
    "toDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад към таблото"])},
    "slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing:"])},
    "nextSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Slide"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "startCertification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your certification"])},
    "trainingCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Completed"])},
    "proceedToExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning stage of the training complete. Proceed to examination."])},
    "fromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start From beginning"])},
    "continueTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Training"])},
    "ContinueText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to Continue from slide number"])},
    "ContinueText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or start from beginning"])},
    "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтегляне на файл"])}
  },
  "certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертификати"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преминете изпита и получете сертификат"])},
    "noOfQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Questions"])},
    "examTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Time: "])},
    "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mins"])},
    "maxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Attempts"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" attempt(s)"])},
    "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" remaining"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "timeLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставащо време"])},
    "guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["минути."])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "summaryText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имате общо"])},
    "summaryText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["опита за целия изпит и неговите въпроси"])},
    "startNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЗАПОЧНЕТЕ СЕГА"])},
    "doLate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ще го направя по-късно"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връщане назад"])},
    "attemptsToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempts to date:"])},
    "remainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Attempts:"])},
    "maximumReach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of attempts. Please contact the compliance officer to discuss your options"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
    "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "backToQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връщане назад"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing:"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "submitAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Answers"])},
    "answerOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer Overview"])},
    "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewed"])},
    "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered"])},
    "notAnswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not answered"])},
    "timeLimitText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a"])},
    "timeLimitText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute timed exam"])},
    "examAboutToStartText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your exam is about to start in"])},
    "examAboutToStartText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "startImmediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Immediately"])},
    "startExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Exam"])},
    "aboutToSubmitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to submit your answers. This will count towards the maximum attempts. If you are happy with your answers then submit."])},
    "aboutToSubmitTextSimple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to submit your exam. This action cannot be undone. Are you sure you want to submit?"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
    "noteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can Връщане назад and review your answers before you submit."])},
    "submitExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Exam"])},
    "cancelExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Exam"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "noAnsGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма даден отговор"])},
    "cancelExamText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to cancel without completing your exam?"])},
    "cancelExamText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelling the exam would count towards your attempts."])},
    "cancelExamText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have"])},
    "cancelExamText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attempt(s) remaining"])},
    "examSubmittedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Submitted"])},
    "closeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Близо"])},
    "thankYouText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for Choosing Our Exam Portal, for further queries please contact our Admin."])},
    "examResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Result"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score:"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["out of"])},
    "yourResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Result:"])},
    "toPassExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Needed To Pass Exam:"])},
    "examStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Status:"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed"])},
    "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations you have passed!"])},
    "PlsTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again"])},
    "goToQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to question"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предупреждение!"])},
    "blankAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Cannot Submit Blank Answer/s."])},
    "englishOriginalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Original"])}
  },
  "annual-certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертификати"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удостоверяване на множество сертификати"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На път сте да напуснете страницата. Всички незапазени промени ще бъдат изгубени. Сигурни ли сте, че искате да напуснете?"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На път сте да изпратите Вашия въпросник. Това действие не може да бъде отменено. Сигурни ли сте, че искате да го изпратите?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият формуляр е подаден успешно."])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открити са едно или повече липсващи полета или грешки. Моля, прегледайте и актуализирайте според необходимостта."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предупреждение"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информацията не е запазена. Моля, опитайте отново."])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актуализирайте информацията"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерираме вашия PDF файл..."])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно изтегляне на PDF файл"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF файлът не е изтеглен. Моля, опитайте отново"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["файлът не е изтеглен. Моля, опитайте отново"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завършване на регистрацията"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актуализиране на профил"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данни за контакт"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актуализиране на парола"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собствено име"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилно име"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер на банкова сметка"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длъжност"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текуща парола"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова парола"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърдете паролата"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете Вашето собствено име"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете Вашето фамилно име"])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете Вашия телефонен номер за връзка"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете Вашата длъжност или позиция"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете текуща парола"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете нова парола"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете потвърждение на паролата"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това поле е задължително"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паролите трябва да са идентични"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашата заявка се обработва..."])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плъзнете и пуснете, за да качите съдържание!"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...или натиснете тук, за да изберете файл от Вашия компютър"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на файл"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип файл"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер на файла (в байтове)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действие"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изчистване"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изисква се"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изчистване"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нулиране"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актуализиране"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване"])},
    "deleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сигурни ли сте, че искате да изтриете този елемент?"])},
    "deleteHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на ред"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
    "burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Taiwan"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
    "haitian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haitian Creole"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "khmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "lao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lao"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "portuguesebr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Brazilian"])},
    "portuguesemz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Mozambique"])},
    "portuguesepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Portugal"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "serbian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian"])},
    "sinhalese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
    "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "spanishmx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish Mexican"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невалиден формат"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейлът е невалиден"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невалидна текуща парола."])}
  },
  "privacyStatement": {
    "msgTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy at Novartis"])},
    "contactUsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The responsible use of personal data is a core value at Novartis. Novartis Group fully respects privacy laws and is subject to an internal framework of privacy rules and policies. The internal transfer of data is governed by Binding Corporate Rules, so called \"BCR\". BCR are a system of privacy principles, rules and tools intended to ensure the protection of personal data. This collection of rules represents today's best practice to meet the European Economic Area's (\"EEA\") data protection requirements for the transfer of personal data within a Group of companies. To be legally effective, the BCR have been approved by EEA Data Protection Agencies. BCR regulate the mechanism of transfer of data inside the Novartis Group of companies."])},
    "contactMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish to contact us regarding how we use your personal data or you wish to exercise your data privacy rights, please email us at"])}
  },
  "footerDashboard": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any information provided will be handled in accordance with Tecan's "])},
    "linkText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика за поверителност"])},
    "fullTex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any information provided will be handled in accordance with Tecan's Privacy Policy."])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване..."])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължаване"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмяна"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Затвори"])},
  "okBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добре"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпращане"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване на информацията"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Излизане от системата"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистриране"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изход"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актуализиране"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профил"])}
}