<template>
  <div
    v-if="label"
    :class="['label', { 'label--hidden': isLabelHidden }]"
    data-test-id="label"
  >
    <label :for="id" class="label__label">
      <span class="label__label-text" data-test-id="label__label-text">
        {{ label }}
      </span>
      <span
        v-if="isRequired"
        class="label__label-required"
        data-test-id="label__label-required"
      >
        *
      </span>
      <slot name="description"></slot>
      <span v-if="includeColon">:</span>
    </label>
    <slot name="details"></slot>
  </div>
</template>

<script>
export default {
  name: "Label",
  props: {
    id: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    isLabelHidden: {
      type: Boolean,
      default: false
    },
    includeColon: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  &__label {
    width: 100%;

    &-text {
      font-weight: 600;
    }

    &-required {
      color: $spanish-red;
    }

    &-image {
      width: 30px;
    }
  }

  &--hidden {
    @include screen-reader-only;
  }
}
</style>
