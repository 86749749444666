<template>
  <section class="set-password">
    <div class="set-password__page-container">
      <TextBox
        :isVisible="feedbackObject.isVisible"
        :status="feedbackObject.status"
        :title="feedbackObject.title"
        :text="feedbackObject.text"
      >
        <router-link
          v-if="feedbackObject.status === modalVariants.SUCCESS"
          :to="urls.HOME"
        >
          Return to log in page
        </router-link>
      </TextBox>
      <Logo />
      <div class="set-password__description">
        <h3 class="set-password__description-title">{{ getPageTitle() }}</h3>
        <p class="set-password__description-text">
          {{ getPageDescription() }}
        </p>
      </div>
      <form class="set-password__form">
        <Input
          id="forgotPasswordEmail"
          name="forgotPasswordEmail"
          placeholder="eg. username@ethixbase.com"
          class="set-password__input"
          data-test-id="set-password__input"
          :value="forgotPasswordEmail"
          :error="forgotPasswordValidationMessage"
          :isDisabled="isEmailFieldDisabled"
          @change="onChangeInput('forgotPasswordEmail', $event)"
        />
        <b-button
          variant="outline-primary"
          class="set-password__cta"
          @click="onSubmit"
        >
          Submit
        </b-button>
      </form>
    </div>
  </section>
</template>

<script>
import {
  modalVariants,
  routeNames,
  urls,
  customErrorMessages,
  validationKeys
} from "@/constants";
import { authService } from "@/services";
import Logo from "@/organisms/Logo/Logo";
import { hasCorrectEmailFormat, isValidResponse, isRequired } from "@/utils";
import Input from "@/molecules/Input/Input";
import TextBox from "@/molecules/TextBox/TextBox";

export default {
  name: "SetPassword",
  components: {
    Logo,
    Input,
    TextBox
  },
  data() {
    return {
      urls,
      modalVariants,
      forgotPasswordEmail: "",
      forgotPasswordValidationMessage: "",
      feedbackObject: this.makeFeedbackObj(),
      isEmailFieldDisabled: false
    };
  },
  props: {
    userProvidedEmail: {
      type: String,
      default: ""
    }
  },
  mounted() {
    if (this.userProvidedEmail) {
      this.forgotPasswordEmail = this.userProvidedEmail;
      this.isEmailFieldDisabled = true;
    }
  },
  computed: {
    isSetPasswordRoute() {
      return (
        this.$route.name === routeNames.SET_PASSWORD ||
        this.$route.name === routeNames.SET_PASSWORD_EMAIL_ID
      );
    }
  },
  methods: {
    isRequired,
    hasCorrectEmailFormat,
    onChangeInput(inputName, val) {
      this[inputName] = val;
    },
    getPageTitle() {
      return this.isSetPasswordRoute ? "Set Password" : "Forgot Password?";
    },
    getPageDescription() {
      return this.isSetPasswordRoute
        ? "Please confirm your email address below and we will send you the link to set your password."
        : "Please enter your email address below and we will send you link to reset your password.";
    },
    makeFeedbackObj(title = "", text = "", status = "", isVisible = false) {
      return {
        text,
        title,
        status,
        isVisible
      };
    },
    validateEmail() {
      if (!this.isRequired(this.forgotPasswordEmail)) {
        this.forgotPasswordValidationMessage =
          customErrorMessages.login.forgotPasswordEmail[
            validationKeys.REQUIRED
          ];
        return false;
      }
      if (!this.hasCorrectEmailFormat(this.forgotPasswordEmail)) {
        this.forgotPasswordValidationMessage =
          customErrorMessages.login.forgotPasswordEmail[
            validationKeys.IS_VALID_EMAIL
          ];
        return false;
      }
      this.forgotPasswordValidationMessage = "";
      return true;
    },
    async onSubmit() {
      this.feedbackObject = this.makeFeedbackObj();
      if (this.validateEmail()) {
        await this.forgotPassword();
      }
    },
    async forgotPassword() {
      try {
        const response = await authService.forgotPassword({
          email: this.forgotPasswordEmail
        });
        this.forgotPasswordSuccessful(response);
      } catch (error) {
        this.setFeedbackObj(error);
      } finally {
        this.forgotPasswordEmail = "";
      }
    },
    forgotPasswordSuccessful(response) {
      if (!isValidResponse(response, "success")) {
        this.clearValidationMessage();
      }
      this.setFeedbackObj(response);
    },
    setFeedbackObj(response) {
      if (isValidResponse(response, "success")) {
        this.feedbackObject = this.makeFeedbackObj(
          "Success",
          "A link to reset your password has been sent to this email address, subject to it being registered in the platform.",
          modalVariants.SUCCESS,
          true
        );
      } else {
        this.feedbackObject = this.makeFeedbackObj(
          "Error",
          "Something went wrong, please try again in a few minutes or contact support.",
          modalVariants.WARNING,
          true
        );
      }
    },
    clearValidationMessage() {
      this.forgotPasswordValidationMessage = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.set-password {
  &__page-container {
    @include page-container;
    text-align: center;
    max-width: 500px;
  }

  &__form {
    display: flex;
    justify-content: space-between;
  }

  &__input {
    width: 100%;
    text-align: left;
  }

  &__cta {
    align-self: flex-start;
    margin-left: 20px;
    background: $robin-egg-blue;
    color: $white;
  }

  &__description {
    text-align: center;

    &-title {
      margin: 20px 0 10px;
      font-size: 16px;
      font-family: $font-family-sans-serif;
    }

    &-text {
      @include body-text;
      color: $black-olive;
    }
  }

  &:deep(.logo__image) {
    max-width: 260px;
    max-height: 260px;
  }
}
</style>
