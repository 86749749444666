<template>
  <div class="trigger-step">
    <Select
      class="trigger-step__action-to-trigger"
      label="Action to trigger"
      name="trigger-step__action-to-trigger"
      :id="`trigger-step__action-to-trigger--${id}`"
      data-test-id="trigger-step__action-to-trigger"
      :options="actionNameOptions"
      :error="actionNameErrorMessage"
      placeholder="Select..."
      @change="
        $emit('change', {
          property: triggerStepKeys.ACTIONS,
          id,
          event: $event
        })
      "
    />
    <BaseText
      v-if="actionInformation"
      data-test-id="trigger-step__action-to-trigger--information"
      :text="actionInformation"
      :theme="themes.SUCCESS_INVERSE"
    />
    <template v-if="isExpectDataListVisible">
      <BaseText
        class="trigger-step__expected-data"
        data-test-id="trigger-step__expected-data"
        text="Expected data for action"
      />
      <ul
        class="trigger-step__expected-data-list"
        data-test-id="trigger-step__expected-data-list"
      >
        <li
          v-for="(
            {
              name,
              options,
              uniqueType,
              templateName,
              searchValue,
              errorMessage,
              components
            },
            index
          ) in expectDataList"
          :key="`${name}-${index}`"
          class="trigger-step__expected-data-list-element"
          data-test-id="trigger-step__expected-data-list-element"
        >
          <BaseText
            class="trigger-step__expected-data-list-element-text"
            data-test-id="trigger-step__expected-data-list-element-text"
          >
            <span class="trigger-step__expected-data-list-element--bold">
              {{ name }}
            </span>
            ({{ getExpectedDataTypesText({ uniqueType, templateName }) }})
          </BaseText>
          <div class="trigger-step__expected-data-list-elements">
            <SearchSelect
              :label="name"
              isLabelHidden
              :name="toKebabWrapper(name, id)"
              :id="toKebabWrapper(name, id)"
              data-test-id="trigger-step__expected-data-name-mapping"
              :options="options"
              :searchValue="searchValue"
              :error="errorMessage"
              placeholder="Select..."
              @change="
                $emit('change', {
                  name,
                  property: triggerStepKeys.EXPECTED_DATA_MAPPING,
                  id,
                  event: $event
                })
              "
              @reset="
                $emit('reset', {
                  name,
                  property: triggerStepKeys.EXPECTED_DATA_MAPPING,
                  id,
                  event: $event
                })
              "
              @create="
                $emit('create', {
                  name,
                  property: triggerStepKeys.EXPECTED_DATA_MAPPING,
                  id,
                  event: $event
                })
              "
            />
            <component
              v-for="({ component, componentOptions, id }, index) in components"
              v-bind="componentOptions"
              :key="id"
              :is="component"
              class="trigger-step__expected-data-list-elements--dynamic"
              data-test-id="trigger-step__expected-data-list-elements--dynamic"
              :id="`trigger-step__expected-data-list-elements--dynamic-${index}`"
              name="trigger-step__expected-data-list-elements--dynamic"
            />
          </div>
        </li>
      </ul>
      <component
        v-for="{ component, componentOptions, id } in additionalComponentsList"
        v-bind="componentOptions"
        :key="id"
        :is="component"
      />
    </template>
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import Select from "@/molecules/Select/Select";
import SearchSelect from "@/molecules/SearchSelect/SearchSelect";
import { toKebab, isExpectedDataUniqueInstanceSubType } from "@/utils";
import { expectedDataTypesMapText, triggerStepKeys, themes } from "@/constants";

export default {
  name: "TriggerStep",
  components: {
    BaseText,
    Select,
    SearchSelect
  },
  props: {
    id: {
      type: Number,
      require: true
    },
    actionNameOptions: {
      type: Array,
      default: () => []
    },
    actionNameErrorMessage: {
      type: String,
      default: ""
    },
    actionInformation: {
      type: String,
      default: ""
    },
    expectDataList: {
      type: Array,
      default: () => []
    },
    additionalComponentsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      triggerStepKeys,
      themes
    };
  },
  computed: {
    isExpectDataListVisible() {
      return !!this.expectDataList.length;
    }
  },
  methods: {
    isExpectedDataUniqueInstanceSubType,
    toKebabWrapper(value, id) {
      return `trigger-step-expected-data-name-${toKebab(value)}-${id}`;
    },
    getExpectedDataTypesText({ uniqueType = "", templateName = "" } = {}) {
      if (this.isExpectedDataUniqueInstanceSubType({ value: uniqueType })) {
        return templateName;
      }
      return expectedDataTypesMapText[uniqueType] || uniqueType;
    }
  }
};
</script>

<style scoped lang="scss">
.trigger-step {
  &__expected-data-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &-element {
      display: flex;
      align-items: center;
      padding: 10px 0;

      &--bold {
        @include body-text-bold;
      }

      .trigger-step__expected-data-list-element-text {
        width: 250px;
        margin: 0;
        padding: 0 20px 0 0;
        align-self: flex-start;
      }

      .trigger-step__expected-data-list-elements {
        width: calc(100% - 250px);

        &--dynamic {
          padding-top: 20px;
        }
      }
    }
  }

  &__action-to-trigger {
    display: block;
  }
}
</style>
