import {
  expectedDataTypes,
  routeNames,
  expectedDataEntitiesTypes,
  expectedDataInstanceTypes
} from "@/constants";
import {
  getDuplicateIndexes,
  isRequired,
  isAlphaNumeric,
  isCustomAttributeRiskScale,
  isCustomAttributeStringList,
  isCustomAttributeMultiSelectStringList
} from "@/utils";

const validateExpectedDataKey = ({ index, expectedData, key, errorKey }) => {
  if (isRequired(expectedData?.[index]?.[key])) {
    expectedData[index][errorKey] = "";
    return false;
  } else {
    expectedData[index][errorKey] = "required";
    return true;
  }
};

const validateExpectedDataNameSpelling = ({ index, expectedData }) => {
  if (isAlphaNumeric(expectedData[index].expectedVariableName)) {
    expectedData[index].expectedVariableNameErrorMessage = "";
    return false;
  } else {
    expectedData[index].expectedVariableNameErrorMessage =
      "Please input only alpha numeric values";
    return true;
  }
};

const validateExpectedDataSubType = ({ index, expectedData }) => {
  const isSubTypeValidationRequired =
    expectedData[index].expectedVariableType === expectedDataTypes.DDQ_TASK ||
    expectedData[index].expectedVariableType ===
      expectedDataTypes.RISK_RATING ||
    expectedData[index].expectedVariableType === expectedDataTypes.STRING_LIST;

  if (isSubTypeValidationRequired) {
    if (isRequired(expectedData[index].expectedVariableSubType)) {
      expectedData[index].expectedVariableSubTypeErrorMessage = "";
      return false;
    } else {
      expectedData[index].expectedVariableSubTypeErrorMessage = "required";
      return true;
    }
  } else {
    return false;
  }
};

const validateUniqueNamesInExpectedData = (expectedData) => {
  const expectedDataNames = expectedData.map(
    ({ expectedVariableName }) => expectedVariableName
  );
  const indexOfExpectedDataDuplicates = getDuplicateIndexes(expectedDataNames);
  if (indexOfExpectedDataDuplicates.length) {
    for (const index of indexOfExpectedDataDuplicates) {
      expectedData[index].expectedVariableNameErrorMessage =
        "variable names must be unique";
    }
    return true;
  } else {
    return false;
  }
};

const validateExpectedData = ({
  expectedData = [],
  _validateExpectedDataKey = validateExpectedDataKey,
  _validateExpectedDataSubType = validateExpectedDataSubType,
  _validateUniqueNamesInExpectedData = validateUniqueNamesInExpectedData,
  _validateExpectedDataNameSpelling = validateExpectedDataNameSpelling
} = {}) => {
  let isExpectedDataInvalid = [];

  for (let index = 0; index < expectedData.length; index++) {
    expectedData[index].expectedVariableNameErrorMessage = "";
    expectedData[index].expectedVariableTypeErrorMessage = "";
    expectedData[index].expectedVariableSubTypeErrorMessage = "";

    const isExpectedVariableNameInvalid = _validateExpectedDataKey({
      index,
      expectedData,
      key: "expectedVariableName",
      errorKey: "expectedVariableNameErrorMessage"
    });
    isExpectedDataInvalid.push(isExpectedVariableNameInvalid);
    isExpectedDataInvalid.push(
      _validateExpectedDataKey({
        index,
        expectedData,
        key: "expectedVariableType",
        errorKey: "expectedVariableTypeErrorMessage"
      })
    );
    isExpectedDataInvalid.push(
      _validateExpectedDataSubType({ index, expectedData })
    );
    if (!isExpectedVariableNameInvalid) {
      isExpectedDataInvalid.push(
        _validateExpectedDataNameSpelling({ index, expectedData })
      );
    }
  }

  isExpectedDataInvalid.push(_validateUniqueNamesInExpectedData(expectedData));

  return isExpectedDataInvalid.includes(true);
};

const getLegendRouteLink = ({ ddqName = "", expectedDataDDQ = [] } = {}) => {
  const { formId } =
    expectedDataDDQ.find(({ name }) => name === ddqName)?.context || {};
  return {
    name: routeNames.QUESTIONS_REFERENCE,
    params: {
      id: formId
    }
  };
};

const hasExpectedDataVariableSubType = ({
  expectedVariableType = "",
  expectedVariableSubType = ""
} = {}) =>
  expectedVariableType === expectedDataTypes.DDQ_TASK &&
  !!expectedVariableSubType;

const makeExpectedDataBaseObj = () => ({
  expectedVariableName: "",
  expectedVariableType: "",
  expectedVariableSubType: "",
  expectedVariableNameErrorMessage: "",
  expectedVariableTypeErrorMessage: "",
  expectedVariableSubTypeErrorMessage: ""
});

const makeExpectedDataObjFromEntityType = ({
  expectedData = {},
  _makeExpectedDataBaseObj = makeExpectedDataBaseObj,
  expectedDataList = []
} = {}) => {
  const expectedDataObj = _makeExpectedDataBaseObj();
  expectedDataObj.expectedVariableName = expectedData?.name;
  expectedDataObj.expectedVariableType = expectedData?.entity;

  if (expectedData.entity === expectedDataEntitiesTypes.DDQ_TASK) {
    expectedDataObj.expectedVariableSubType = expectedDataList.find(
      ({ context = {} } = {}) => context?.formId === expectedData.context.formId
    )?.name;
  }
  return expectedDataObj;
};

const makeExpectedDataObjFromInstanceSubType = ({
  expectedData,
  _makeExpectedDataBaseObj = makeExpectedDataBaseObj,
  _isCustomAttributeRiskScale = isCustomAttributeRiskScale,
  _isCustomAttributeStringList = isCustomAttributeStringList,
  _isCustomAttributeMultiSelectStringList = isCustomAttributeMultiSelectStringList
}) => {
  const expectedDataObj = _makeExpectedDataBaseObj();
  expectedDataObj.expectedVariableName = expectedData?.name;
  expectedDataObj.expectedVariableSubType = expectedData.templateName;

  if (_isCustomAttributeRiskScale(expectedData?.subType)) {
    expectedDataObj.expectedVariableType =
      expectedDataInstanceTypes.RISK_RATING;
  } else if (_isCustomAttributeStringList(expectedData?.subType)) {
    expectedDataObj.expectedVariableType =
      expectedDataInstanceTypes.STRING_LIST;
  } else if (_isCustomAttributeMultiSelectStringList(expectedData?.subType)) {
    expectedDataObj.expectedVariableType =
      expectedDataInstanceTypes.MULTI_SELECT_STRING_LIST;
  }

  return expectedDataObj;
};

const makeExpectedDataObjFromPrimitiveType = ({
  expectedData,
  _makeExpectedDataBaseObj = makeExpectedDataBaseObj
}) => {
  const expectedDataObj = _makeExpectedDataBaseObj();
  expectedDataObj.expectedVariableName = expectedData?.name;
  expectedDataObj.expectedVariableType = expectedData.uniqueType;

  return expectedDataObj;
};

export {
  validateExpectedDataKey,
  validateExpectedDataNameSpelling,
  validateExpectedDataSubType,
  validateUniqueNamesInExpectedData,
  validateExpectedData,
  getLegendRouteLink,
  hasExpectedDataVariableSubType,
  makeExpectedDataBaseObj,
  makeExpectedDataObjFromEntityType,
  makeExpectedDataObjFromInstanceSubType,
  makeExpectedDataObjFromPrimitiveType
};
