export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පුවරුව"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අනුකූලතා පුවරුව"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුල් පිටුව"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රවේශනය කරමින්…"])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ආකෘති"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රශ්නාවලිය"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නිසිප්‍රශ්නාවලිය පුරවා ලියකියවිලි සැපයීම"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රශ්නාවලිය ඉදිරිපත් කිරීම"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මම ඉහත කරුණු තහවුරු කරන්නෙමි"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මම ඉහත කරුණු තහවුරු කරන්නෙමි"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පුවරුව වෙත නැවත පැමිණිම"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉදිරියට යන්න"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සමාලෝචනය"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යොමු කර ඇති දිනය:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නිම කල දිනය:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["බාගන්න"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නිම කරන ලදි"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සිදුකරමින් පවතී"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නිම කරන ලදි"])},
    "linkUpModalHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Action Required"])},
    "linkUpModalBodyText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for completing the Salesforce Third Party Due Diligence Intake Form."])},
    "linkUpModalBodyText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the responses provided, Salesforce requires that your organization complete the Salesforce Third Party Due Diligence Questionnaire."])},
    "linkUpModalBodyText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete this questionnaire as soon as possible. Failure to complete the questionnaire may affect your organization’s request to enter into a business relationship with Salesforce."])},
    "linkUpModalBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin the Due Diligence Questionnaire"])},
    "linkUpModalCancelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to Questionnaires"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පේළිය මකන්න"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පේලිය සංස්කරණය කරන්න"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සමාවන්න, ගැලපෙන විකල්ප නැත."])}
  },
  "training": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පුහුණු වීම්"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉගෙනුම් ද්‍රව්‍ය කියවා විභාග සඳහා සූදානම් වන්න"])},
    "trainingTite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Training Courses"])},
    "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code of Conduct"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වහන්න"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සමාලෝචනය"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ආරම්භ කරන්න"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉදිරියට යන්න"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සිදුකෙරෙමින් පවතී"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සම්පුර්ණයි"])},
    "notComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ආරම්භ කර නැත"])},
    "toFirstSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to first slide"])},
    "toDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පුවරුව වෙත නැවත පැමිණිම"])},
    "slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing:"])},
    "nextSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Slide"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "startCertification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your certification"])},
    "trainingCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Completed"])},
    "proceedToExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning stage of the training complete. Proceed to examination."])},
    "fromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start From beginning"])},
    "continueTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Training"])},
    "ContinueText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to Continue from slide number"])},
    "ContinueText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or start from beginning"])},
    "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ගොනුව බාගන්න"])}
  },
  "certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සහතික කිරීම"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විභාගයක් කර සහතිකය ලබාගන්න"])},
    "noOfQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Questions"])},
    "examTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Time: "])},
    "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mins"])},
    "maxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Attempts"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" attempt(s)"])},
    "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" remaining"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "timeLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Left"])},
    "guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes."])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "summaryText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a total of"])},
    "summaryText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attempts for the entire exam and its questions"])},
    "startNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["START NOW"])},
    "doLate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'll do it later"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
    "attemptsToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempts to date:"])},
    "remainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Attempts:"])},
    "maximumReach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of attempts. Please contact the compliance officer to discuss your options"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
    "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "backToQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing:"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "submitAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Answers"])},
    "answerOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer Overview"])},
    "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewed"])},
    "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered"])},
    "notAnswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not answered"])},
    "timeLimitText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a"])},
    "timeLimitText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute timed exam"])},
    "examAboutToStartText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your exam is about to start in"])},
    "examAboutToStartText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "startImmediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Immediately"])},
    "startExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Exam"])},
    "aboutToSubmitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to submit your answers. This will count towards the maximum attempts. If you are happy with your answers then submit."])},
    "aboutToSubmitTextSimple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to submit your exam. This action cannot be undone. Are you sure you want to submit?"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
    "noteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can go back and review your answers before you submit."])},
    "submitExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Exam"])},
    "cancelExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Exam"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "noAnsGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Answer Given"])},
    "cancelExamText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to cancel without completing your exam?"])},
    "cancelExamText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelling the exam would count towards your attempts."])},
    "cancelExamText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have"])},
    "cancelExamText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attempt(s) remaining"])},
    "examSubmittedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Submitted"])},
    "closeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වසන්න"])},
    "thankYouText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for Choosing Our Exam Portal, for further queries please contact our Admin."])},
    "examResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Result"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score:"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["out of"])},
    "yourResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Result:"])},
    "toPassExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Needed To Pass Exam:"])},
    "examStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Status:"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed"])},
    "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations you have passed!"])},
    "PlsTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again"])},
    "goToQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to question"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert!"])},
    "blankAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Cannot Submit Blank Answer/s."])},
    "englishOriginalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Original"])}
  },
  "annual-certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifications"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certify Multiple Certifications"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබ පිටුවෙන් ඉවත් වීමට ආසන්නය. සුරකින නොලද වෙනස්කම් ඇතොත් ඒවා නැති වනු ඇත. ඔබට ඉවත් විය යුතුමද?"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබ ප්‍රශ්නාවලිය ඉදිරිපත් කිරීමට ආසන්නය. මෙම ක්‍රියාව වෙනස් කල නොහැකිය. ඔබට ඉදිරිපත් කිරීමට අවශ්‍ය බව විශ්වාසද?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබේ පෝරමය සාර්ථකව ඉදිරිපත් කර ඇත."])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නොපිරවූ කොටස් හෝ දෝශ එකක් හෝ වැඩි ගණනක් හමුවිය. අවශ්‍ය පරිදි සමාලෝචනය සහ යාවත්කාලීන කරන්න."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දැනුම් දීම"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සුරැකීම සාර්ථකයි"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රගතිය සුරැකීම නොකර ඇත. කරුණාකර නැවත උත්සාහ කරන්න."])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යාවත්කාලීනය සාර්ථකයි"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපි ඔබේ PDF සකසමින් සිටී…"])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF බාගැනීම සාර්ථකයි"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF බාගැනීම අසාර්ථකයි කරුණාකර නැවත උත්සාහ කරන්න."])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉවත්වීම සාර්ථකයි"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ලියාපදිංචිය සම්පූර්ණ කරන්න"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" පැතිකඩ යාවත්කාලීන කරන්න"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඊ මේල්"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සබඳතා තොරතුරු"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුරපදය යාවත්කාලීන කරන්න"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුල් නම"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අවසන් නම"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සබඳතා අංකය"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["රැකියා තනතුර"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වර්තමාන මුරපදය"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නව මුරපදය"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුරපදය තහවුරු කරන්න"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබේ මුල් නම ඇතුලත් කරන්න"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබේ වාසගම/අවසන් නම ඇතුලත් කරන්න "])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබේ සබඳතා අංකය ඇතුලත් කරන්න"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබේ වෘත්තීය තනතුරු හෝ කාර්යය ඇතුලත් කරන්න"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වර්තමාන මුරපදය ඇතුලත් කරන්න"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නව මුරපදය ඇතුලත් කරන්න"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුරපදය තහවුරු කිරීම ඇතුලත් කරන්න"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මෙම ක්ෂේත්‍රය අවශ්‍යයි"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුරපදයන් එකම විය යුතුයි"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබේ ඉල්ලීම ක්‍රියාත්මක වෙමින් පවතී.."])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අන්තර්ගතය උඩුගත කිරීම සඳහා ඇද හෙළන්න"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..හෝ ඔබේ පරිගණකයෙන් ලිපිගොනුවක් තෝරාගැනීම සඳහා ක්ලික් කරන්න."])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ගොනු නාමය"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ගොනු වර්ගය"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ගොනු ප්‍රමාණය (බයිට්)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ක්‍රියාමාර්ගය"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මකන්න"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අවශ්‍යයි"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["එකතු කරන්න"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මකන්න"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නැවත සැකසීම"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යාවත්කාලීන කරන්න"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉවත් කරන්න"])},
    "deleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබට මෙම අයිතමය ඉවත් කිරීමට අවශ්‍යයද?"])},
    "deleteHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පේළිය මකන්න"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
    "burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Taiwan"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
    "haitian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haitian Creole"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "khmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "lao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lao"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "portuguesebr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Brazilian"])},
    "portuguesemz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Mozambique"])},
    "portuguesepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Portugal"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "serbian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian"])},
    "sinhalese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
    "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "spanishmx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish Mexican"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වලංගු නොවන ක්‍රමයකි"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඊ මේල් එක වලංගු නැත"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වලංගු නොවන වත්මන් මුරපදයකි."])}
  },
  "privacyStatement": {
    "msgTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["රහස්‍යභාවය ප්‍රතිපත්තිය"])},
    "contactUsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The responsible use of personal data is a core value at Novartis. Novartis Group fully respects privacy laws and is subject to an internal framework of privacy rules and policies. The internal transfer of data is governed by Binding Corporate Rules, so called \"BCR\". BCR are a system of privacy principles, rules and tools intended to ensure the protection of personal data. This collection of rules represents today's best practice to meet the European Economic Area's (\"EEA\") data protection requirements for the transfer of personal data within a Group of companies. To be legally effective, the BCR have been approved by EEA Data Protection Agencies. BCR regulate the mechanism of transfer of data inside the Novartis Group of companies."])},
    "contactMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish to contact us regarding how we use your personal data or you wish to exercise your data privacy rights, please email us at"])}
  },
  "footerDashboard": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any information provided will be handled in accordance with Tecan's "])},
    "linkText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "fullTex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any information provided will be handled in accordance with Tecan's Privacy Policy."])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සුරකිමින්…"])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ආරම්භ කරන්න"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සුරකින්න"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉදිරියට යන්න"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අවලංගු කරන්න"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වහන්න"])},
  "okBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["හරි"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉවත් කරන්න"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සංස්කරණය කරන්න"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉදිරිපත් කරන්න"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රගතිය සුරකින්න"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රවේශ වන්න"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉවත් වන්න"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පුරන්න"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉවත් වන්න"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යාවත්කාලීන කරන්න"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පැතිකඩ"])}
}