import {
  actionKeys,
  actionKeysApi,
  actionStepType,
  actionStepSubType
} from "@/constants";
import {
  makeIfStatementDTO,
  makeIfStepAPIDTO
} from "@/services/actions/dto/ifStatementDTO/ifStatement.dto";
import {
  makeSetStepAPIDTO,
  makeSetStepDTO
} from "@/services/actions/dto/setStepDTO/setStep.dto";
import {
  makeEndStepDTO,
  makeEndStepAPIDTO
} from "@/services/actions/dto/endStepDTO/endStep.dto";
import {
  makeTriggerStepAPIDTO,
  makeTriggerStepDTO
} from "@/services/actions/dto/triggerStepDTO/triggerStep.dto";
import {
  makeNotificationEmailStepDTO,
  makeNotificationEmailStepAPIDTO
} from "@/services/actions/dto/notificationEmailDTO/notificationEmail.dto";
import {
  makeExpectedDataPayloadAPIDTO,
  makeExpectedDataPayloadDTO
} from "@/services/expectedData/dto/expectedData.dto";
import {
  makeTaskStepDTO,
  makeTaskStepAPIDTO
} from "@/services/actions/dto/taskStepDTO/taskStep.dto";

const makeStepDTO = (functionStep) => {
  if (functionStep[actionKeysApi.STEP_TYPE] === actionStepType.SET) {
    return makeSetStepDTO({ functionStep });
  } else if (functionStep[actionKeysApi.STEP_TYPE] === actionStepType.IF) {
    return makeIfStatementDTO({ functionStep });
  } else if (functionStep[actionKeysApi.STEP_TYPE] === actionStepType.END) {
    return makeEndStepDTO(functionStep);
  } else if (functionStep[actionKeysApi.STEP_TYPE] === actionStepType.TRIGGER) {
    return makeTriggerStepDTO({ functionStep });
  } else if (functionStep[actionKeysApi.STEP_TYPE] === actionStepType.TASK) {
    return makeTaskStepDTO({ functionStep });
  } else if (
    functionStep[actionKeysApi.SPECIFICATION]?.[actionKeysApi.TYPE] ===
    actionStepSubType.EMAIL
  ) {
    return makeNotificationEmailStepDTO({ functionStep });
  }
};

const makeStepAPIDTO = (functionStep, entities, functionSteps) => {
  if (functionStep[actionKeys.STEP_TYPE] === actionStepType.SET) {
    return makeSetStepAPIDTO({ functionStep, entities, functionSteps });
  } else if (functionStep[actionKeys.STEP_TYPE] === actionStepType.IF) {
    return makeIfStepAPIDTO(functionStep, entities, functionSteps);
  } else if (functionStep[actionKeys.STEP_TYPE] === actionStepType.END) {
    return makeEndStepAPIDTO(functionStep);
  } else if (functionStep[actionKeys.STEP_TYPE] === actionStepType.TRIGGER) {
    return makeTriggerStepAPIDTO({ functionStep, entities, functionSteps });
  } else if (functionStep[actionKeys.STEP_TYPE] === actionStepSubType.EMAIL) {
    return makeNotificationEmailStepAPIDTO({ functionStep, entities });
  } else if (functionStep[actionKeys.STEP_TYPE] === actionStepType.TASK) {
    return makeTaskStepAPIDTO({ functionStep, entities });
  }
};

const makeActionDTO = ({
  id,
  name,
  description,
  expected_data_definition,
  function_body,
  is_active,
  is_licensed,
  created_at,
  updated_at,
  is_system,
  _makeExpectedDataPayloadDTO = makeExpectedDataPayloadDTO,
  _makeStepDTO = makeStepDTO
} = {}) => ({
  [actionKeys.ID]: id,
  [actionKeys.NAME]: name,
  [actionKeys.DESCRIPTION]: description,
  ..._makeExpectedDataPayloadDTO({
    expectedDataDefinition: expected_data_definition
  }),
  [actionKeys.FUNCTION_BODY]:
    function_body?.map((functionStep) => _makeStepDTO(functionStep)) || [],
  [actionKeys.IS_ACTIVE]: is_active,
  [actionKeys.IS_LICENSED]: is_licensed,
  [actionKeys.CREATED_AT]: created_at,
  [actionKeys.UPDATED_AT]: updated_at,
  [actionKeys.IS_SYSTEM]: is_system
});

const makeActionAPIDTO = ({
  companyId,
  action,
  entities,
  expectedData,
  _makeExpectedDataAPIDTOPayload = makeExpectedDataPayloadAPIDTO,
  _makeStepAPIDTO = makeStepAPIDTO
} = {}) => ({
  [actionKeysApi.NAME]: action[actionKeys.NAME],
  [actionKeysApi.DESCRIPTION]: action[actionKeys.DESCRIPTION],
  [actionKeysApi.IS_ACTIVE]: action[actionKeys.IS_ACTIVE],
  [actionKeysApi.FUNCTION_BODY]:
    action[actionKeys.FUNCTION_BODY]?.map((functionStep, _, functionSteps) =>
      _makeStepAPIDTO(functionStep, entities, functionSteps)
    ) || [],
  ..._makeExpectedDataAPIDTOPayload({
    companyId,
    expectedDataList: action.variables,
    storeExpectedData: expectedData
  })
});

export { makeStepDTO, makeStepAPIDTO, makeActionDTO, makeActionAPIDTO };
