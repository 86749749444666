<template>
  <Modal
    title="Forgot your password?"
    class="forgot-password-modal"
    data-test-id="forgot-password-modal"
    @close-modal="closeModal"
  >
    <template v-slot>
      <div class="forgot-password-modal__content">
        <BaseText
          text="Please enter your email address below and we will send you a link to reset your password."
        />
        <form class="forgot-password__form">
          <Input
            id="forgotPasswordEmail"
            name="forgotPasswordEmail"
            label="Email address"
            placeholder="eg. username@ethixbase.com"
            class="forgot-password-modal__input"
            data-test-id="forgot-password-modal__input"
            :is-required="false"
            :value="forgotPasswordEmail"
            :error="forgotPasswordValidationMessage"
            :include-colon="false"
            @change="forgotPasswordEmail = $event"
          />
        </form>
        <b-toast
          class="forgot-password-modal__toast"
          :visible="hasApiError || isForgotPasswordSuccessful"
          :variant="feedback.variant"
          :title="feedback.title"
          :auto-hide-delay="timers.SLOW_READABLE"
        >
          {{ feedback.message }}
        </b-toast>
      </div>
    </template>
    <template v-slot:left>
      <CallToAction
        class="forgot-password-modal__button"
        :theme="themes.SECONDARY"
        icon="eb-icon-times"
        value="Cancel"
        hasIconWithText
        :icon-size="12"
        @click="closeModal"
      />
    </template>
    <template v-slot:right>
      <CallToAction
        class="forgot-password-modal__button"
        value="Submit"
        icon="eb-icon-angle-right"
        data-test-id="forgot-password-modal__button"
        :hasIconWithText="
          !hasApiError && !isForgotPasswordSuccessful && !isLoading
        "
        :is-error="hasApiError"
        :is-success="isForgotPasswordSuccessful"
        :is-loading="isLoading"
        :icon-size="12"
        @click="onSubmitClick"
      />
    </template>
  </Modal>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import {
  themes,
  customErrorMessages,
  validationKeys,
  timers,
  infoMessages,
  urls
} from "@/constants";
import { hasCorrectEmailFormat, isRequired } from "@/utils";
import { authService } from "@/services";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import BaseText from "@/atoms/BaseText/BaseText";
import Modal from "@/molecules/Modal/Modal";
import Input from "@/molecules/Input/Input";

export default defineComponent({
  name: "ForgotPasswordModal",
  components: { BaseText, CallToAction, Modal, Input },
  compatConfig: {
    MODE: 3
  },
  setup() {
    const router = useRouter();
    const forgotPasswordEmail = ref("");
    const forgotPasswordValidationMessage = ref("");
    const isForgotPasswordSuccessful = ref(false);
    const hasApiError = ref(false);
    const isLoading = ref(false);
    const feedback = reactive({
      variant: "",
      title: "",
      message: ""
    });

    const closeModal = () => {
      router.push(urls.LOGIN);
    };

    const validateEmail = () => {
      if (!isRequired(forgotPasswordEmail.value)) {
        forgotPasswordValidationMessage.value =
          customErrorMessages.login.forgotPasswordEmail[
            validationKeys.REQUIRED
          ];
        return false;
      }

      if (!hasCorrectEmailFormat(forgotPasswordEmail.value)) {
        forgotPasswordValidationMessage.value =
          customErrorMessages.login.forgotPasswordEmail[
            validationKeys.IS_VALID_EMAIL
          ];
        return false;
      }

      forgotPasswordValidationMessage.value = "";
      return true;
    };

    const resetFeedbackStates = () => {
      setTimeout(() => {
        if (isForgotPasswordSuccessful.value) {
          closeModal();
        }

        hasApiError.value = false;
        isForgotPasswordSuccessful.value = false;
      }, timers.SLOW_READABLE);
    };

    const setFeedbackMessageAndVariant = () => {
      if (hasApiError.value) {
        feedback.variant = "danger";
        feedback.title = "";
        feedback.message =
          customErrorMessages.generic[validationKeys.UNEXPECTED_ERROR];
      } else if (isForgotPasswordSuccessful.value) {
        feedback.variant = "success";
        feedback.title = "Success";
        feedback.message = infoMessages.forgotPassword.success;
      }
    };

    const forgotPassword = async () => {
      try {
        isLoading.value = true;

        await authService.forgotPassword({
          email: forgotPasswordEmail.value
        });

        isForgotPasswordSuccessful.value = true;
      } catch (error) {
        hasApiError.value = true;
      } finally {
        isLoading.value = false;
        setFeedbackMessageAndVariant();
        resetFeedbackStates();
      }
    };

    const onSubmitClick = () => {
      if (validateEmail()) {
        forgotPassword();
      }
    };

    return {
      closeModal,
      onSubmitClick,
      themes,
      forgotPasswordEmail,
      forgotPasswordValidationMessage,
      hasApiError,
      isForgotPasswordSuccessful,
      isLoading,
      timers,
      feedback,
      validateEmail,
      resetFeedbackStates,
      setFeedbackMessageAndVariant,
      forgotPassword
    };
  }
});
</script>

<style scoped lang="scss">
.forgot-password-modal {
  &__button {
    display: flex;
    align-items: center;
    gap: $spacing8;
    height: fit-content;
    width: 105px;
    border-radius: $spacing20;
    padding: $spacing8 $spacing16;
  }

  &__toast {
    z-index: 99999;
  }

  &__input {
    max-width: 320px;
  }

  &:deep(.modal) {
    max-width: 763px;
  }
}
</style>
