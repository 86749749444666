import {
  customAttributeRiskScales,
  customAttributeRiskScalesTextMap
} from "@/constants/ratings.constant";

const variableSubType = {
  COUNTRY: "country",
  NUMERIC_11_POINT_SCALE: customAttributeRiskScales.ELEVEN_POINT_SCALE,
  ETHIXBASE_RISK_SCALE: customAttributeRiskScales.ETHIXBASE_RISK_SCALE,
  NUMERIC_10_POINT_SCALE: customAttributeRiskScales.TEN_POINT_SCALE,
  FOUR_POINT_SCALE: customAttributeRiskScales.FOUR_POINT_SCALE
};

const variablePrimitiveType = {
  BOOLEAN: "boolean",
  NUMERIC: "numeric",
  STRING: "string"
};

const variableType = {
  EXPRESSION: "expression",
  CONCATENATION: "concatenation",
  VARIABLE: "variable",
  PROXY: "proxy",
  DATE_TIME: "datetime",
  ...variablePrimitiveType,
  ...variableSubType
};

const variableTypeText = {
  EXPRESSION: "Calculation",
  CONCATENATION: "Concatenation",
  NUMERIC: "Number",
  STRING: "String",
  BOOLEAN: "Boolean",
  PROXY: "Representation",
  DATE_TIME: "Date time"
};

const variableAssignmentType = {
  LITERAL: "literal",
  ENTITY: "entity",
  NO_VALUE: "noValue"
};

const variableScope = {
  LOCAL: "local",
  ENTITY: "entity"
};

const actionStepType = {
  END: "END",
  IF: "IF",
  SET: "SET",
  TRIGGER: "TRIGGER",
  NOTIFICATION: "NOTIFICATION",
  TASK: "TASK"
};

const actionStepSubType = {
  EMAIL: "email"
};

const actionStepTitleMap = {
  [actionStepType.SET]: "SET step",
  [actionStepType.IF]: "IF step",
  [actionStepType.END]: "END step",
  [actionStepType.TRIGGER]: "TRIGGER step",
  [actionStepSubType.EMAIL]: "EMAIL step",
  [actionStepType.TASK]: "TASK step"
};

const ifBlockSuccessStepType = {
  SET: "IF_SET",
  END: "IF_END",
  TRIGGER: "IF_TRIGGER",
  EMAIL: "IF_EMAIL",
  TASK: "IF_TASK"
};

const valueStepType = {
  VARIABLE: "variable",
  LITERAL: "literal"
};

const calculationStepType = {
  LOGIC: "logic",
  ...valueStepType
};

const variableTypeToDisplayTextMap = {
  [variableType.NUMERIC]: variableTypeText.NUMERIC,
  [variableType.BOOLEAN]: variableTypeText.BOOLEAN,
  [variableType.STRING]: variableTypeText.STRING,
  [variableType.EXPRESSION]: variableTypeText.EXPRESSION,
  [variableType.CONCATENATION]: variableTypeText.CONCATENATION,
  [variableType.PROXY]: variableTypeText.PROXY,
  [variableType.DATE_TIME]: variableTypeText.DATE_TIME
};

const variableDisplayTextToTypeMap = {
  [variableTypeText.NUMERIC]: variableType.NUMERIC,
  [variableTypeText.BOOLEAN]: variableType.BOOLEAN,
  [variableTypeText.STRING]: variableType.STRING,
  [variableTypeText.EXPRESSION]: variableType.EXPRESSION,
  [variableType.CONCATENATION]: variableTypeText.CONCATENATION,
  [variableTypeText.PROXY]: variableType.PROXY,
  [variableTypeText.DATE_TIME]: variableType.DATE_TIME
};

const inputType = {
  NUMBER: "number",
  STRING: "text"
};

const variableTypeToInputTypeMap = {
  [variableType.NUMERIC]: inputType.NUMBER,
  [variableType.STRING]: inputType.STRING
};

const expectedDataTypesText = {
  TRAINING_COURSE: "Training course",
  TRAINING_ASSIGNMENT: "Training assignment",
  THIRD_PARTY: "Third party",
  IDD_IDD_PLUS: "IDD / IDD+",
  DDQ_FORM: "DDQ form",
  DDQ_TASK: "DDQ",
  STRING_LIST: "List (of Strings)",
  RISK_RATING: "Risk rating",
  STRING: "String",
  NUMERIC: "Double",
  BOOLEAN: "Boolean",
  DATETIME: "Date",
  COUNTRY: "Country",
  MULTI_SELECT_COUNTRY: "Multi select country",
  MULTI_SELECT_STRING_LIST: "Multi select string"
};

const unsupportedExpectedDataSources = {
  USER: "user"
};

const expectedDataEntitiesTypes = {
  THIRD_PARTY: "third-party",
  IDD_IDD_PLUS: "instant-due-diligence-report",
  DDQ_TASK: "ddq-task",
  DDQ_FORM: "ddq-form",
  TRAINING_ASSIGNMENT: "training-assignment",
  TRAINING_COURSE: "training-course"
};

const expectedDataPrimitiveTypes = {
  STRING: "string",
  NUMERIC: "numeric",
  BOOLEAN: "boolean",
  DATETIME: "datetime",
  COUNTRY: "country",
  MULTI_SELECT_COUNTRY: "multi-select-country"
};

const expectedDataInstanceTypes = {
  STRING_LIST: "string-list",
  MULTI_SELECT_STRING_LIST: "multi-string-list",
  RISK_RATING: "risk-rating"
};

const expectedDataTypes = {
  ...expectedDataEntitiesTypes,
  ...expectedDataPrimitiveTypes,
  ...expectedDataInstanceTypes
};

const expectedDataTypesMapText = {
  [expectedDataTypes.DDQ_FORM]: expectedDataTypesText.DDQ_FORM,
  [expectedDataEntitiesTypes.TRAINING_COURSE]:
    expectedDataTypesText.TRAINING_COURSE,
  [expectedDataEntitiesTypes.TRAINING_ASSIGNMENT]:
    expectedDataTypesText.TRAINING_ASSIGNMENT,
  [expectedDataEntitiesTypes.THIRD_PARTY]: expectedDataTypesText.THIRD_PARTY,
  [expectedDataEntitiesTypes.IDD_IDD_PLUS]: expectedDataTypesText.IDD_IDD_PLUS,
  [expectedDataEntitiesTypes.DDQ_TASK]: expectedDataTypesText.DDQ_TASK,
  [expectedDataInstanceTypes.STRING_LIST]: expectedDataTypesText.STRING_LIST,
  [expectedDataInstanceTypes.MULTI_SELECT_STRING_LIST]:
    expectedDataTypesText.MULTI_SELECT_STRING_LIST,
  [expectedDataInstanceTypes.RISK_RATING]: expectedDataTypesText.RISK_RATING,
  [customAttributeRiskScales.TEN_POINT_SCALE]:
    customAttributeRiskScalesTextMap.TEN_POINT_SCALE,
  [customAttributeRiskScales.ETHIXBASE_RISK_SCALE]:
    customAttributeRiskScalesTextMap.ETHIXBASE_RISK_SCALE,
  [customAttributeRiskScales.FOUR_POINT_SCALE]:
    customAttributeRiskScalesTextMap.FOUR_POINT_SCALE,
  [customAttributeRiskScales.ELEVEN_POINT_SCALE]:
    customAttributeRiskScalesTextMap.ELEVEN_POINT_SCALE,
  [expectedDataPrimitiveTypes.STRING]: expectedDataTypesText.STRING,
  [expectedDataPrimitiveTypes.NUMERIC]: expectedDataTypesText.NUMERIC,
  [expectedDataPrimitiveTypes.BOOLEAN]: expectedDataTypesText.BOOLEAN,
  [expectedDataPrimitiveTypes.DATETIME]: expectedDataTypesText.DATETIME,
  [expectedDataPrimitiveTypes.COUNTRY]: expectedDataTypesText.COUNTRY,
  [expectedDataPrimitiveTypes.MULTI_SELECT_COUNTRY]:
    expectedDataTypesText.MULTI_SELECT_COUNTRY
};

const triggerDataType = {
  PROXY: "proxy",
  USER: "user-provided",
  IDENTIFIER: "identifier",
  LITERAL: "literal",
  VARIABLE: "variable",
  PARAMETER_MATCH: "parameter-match",
  TRAINING_COURSE: "training-course",
  DDQ_FORM: "ddq-form"
};

const actionTypes = {
  SYSTEM_ACTIONS: 1,
  CUSTOM_ACTIONS: 0
};

const actionTypesText = {
  [actionTypes.SYSTEM_ACTIONS]: "System",
  [actionTypes.CUSTOM_ACTIONS]: "User"
};

const automationsTriggerDataTypes = {
  EVENT_PARAMETER: "event-parameter",
  DDQ_FORM: "ddq-form",
  USER_PROVIDED: "user-provided",
  IDENTIFIER: "identifier",
  TRAINING_COURSE: "training-course"
};

const permissionTypes = {
  EDIT_CUSTOM_ATTRIBUTE_VALUE: "edit-custom-attribute-value"
};

const userViewType = {
  THIRD_PARTY: 1,
  EVENT_LOG: 2,
  USER_VIEW_ANALYTICS: 3,
  BI_DASHBOARD: 4
};

const userViewTypeText = {
  ENTITY_LIST: "EntityList",
  EVENT_LOG: "EventLog",
  DASHBOARD: "Dashboard",
  BI_DASHBOARD: "BIDashboard"
};

const userViewTypeTextMapInteger = {
  [userViewType.THIRD_PARTY]: "EntityList",
  [userViewType.EVENT_LOG]: "EventLog",
  [userViewType.USER_VIEW_ANALYTICS]: "Dashboard",
  [userViewType.BI_DASHBOARD]: "BIDashboard"
};

const userViewTypeMapText = {
  [userViewTypeText.ENTITY_LIST]: 1,
  [userViewTypeText.EVENT_LOG]: 2,
  [userViewTypeText.DASHBOARD]: 3,
  [userViewTypeText.BI_DASHBOARD]: 4
};

const endOperations = {
  ROUND: "round"
};

const endOperationsPerType = {
  [variableType.NUMERIC]: [endOperations.ROUND]
};

const menuItemTypes = {
  DEFAULT: "default",
  ASIDE: "aside"
};

const destinationTypes = {
  EMAIL: "email",
  ROLE: "role",
  USER: "user",
  VARIABLE: "variable"
};

const taskTemplatesExpectedDataTriggerType = {
  USER_INPUT: "user-input",
  EXPECTED_DATA: "expected-data"
};

const fileTypes = {
  EXCEL_XLSX:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
};

export {
  variableSubType,
  variablePrimitiveType,
  variableType,
  variableTypeText,
  variableAssignmentType,
  variableScope,
  actionStepType,
  actionStepSubType,
  actionStepTitleMap,
  ifBlockSuccessStepType,
  calculationStepType,
  valueStepType,
  variableTypeToDisplayTextMap,
  variableDisplayTextToTypeMap,
  inputType,
  variableTypeToInputTypeMap,
  expectedDataInstanceTypes,
  expectedDataPrimitiveTypes,
  expectedDataEntitiesTypes,
  expectedDataTypes,
  expectedDataTypesText,
  expectedDataTypesMapText,
  triggerDataType,
  actionTypes,
  actionTypesText,
  automationsTriggerDataTypes,
  permissionTypes,
  userViewType,
  userViewTypeText,
  userViewTypeTextMapInteger,
  userViewTypeMapText,
  endOperations,
  endOperationsPerType,
  menuItemTypes,
  destinationTypes,
  taskTemplatesExpectedDataTriggerType,
  fileTypes,
  unsupportedExpectedDataSources
};
