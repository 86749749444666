export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablero"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablero de Cumplimiento"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página de Inicio"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando…"])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuestionarios"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete los cuestionarios de diligencia debida y proporcione documentación"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar Cuestionario"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario enviado"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmo lo anterior"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al Tablero"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visión General"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha Asignada:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Finalización:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En progreso"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No empezado"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar fila"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar fila"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, no hay opciones que coincidan."])}
  },
  "training": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrenamientos"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lea los materiales de aprendizaje y prepárese para los exámenes"])},
    "trainingTite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis cursos de formación"])},
    "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de Conducta"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisión"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comienzo"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En progreso"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa"])},
    "notComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En progreso"])},
    "toFirstSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a la primera diapositiva"])},
    "toDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al panel"])},
    "slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diapositiva"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visita:"])},
    "nextSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente diapositiva"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima"])},
    "startCertification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicie su certificación"])},
    "trainingCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrenamiento completado"])},
    "proceedToExamination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etapa de aprendizaje de la formación completa. Proceder al examen."])},
    "fromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empezar desde el principio"])},
    "continueTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar entrenando"])},
    "ContinueText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Le gustaría continuar desde el número de diapositiva?"])},
    "ContinueText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o empezar desde el principio"])}
  },
  "certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificaciones"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tome el examen y reciba el certificado"])},
    "noOfQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas sobre números"])},
    "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutos"])},
    "maxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intentos máximos"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" intento(s)"])},
    "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" restante"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empezar"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inténtalo de nuevo"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fallar"])},
    "timeLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo restante"])},
    "guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guia"])},
    "guidanceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de haber leído el material de aprendizaje apropiado antes de completar el examen."])},
    "guidanceText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de haber leído el material de aprendizaje adecuado antes de completar el examen. Este examen durará unos"])},
    "guidanceText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutos."])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])},
    "summaryText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un total de"])},
    "summaryText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intentos para todo el examen y sus preguntas"])},
    "startNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empezar ahora"])},
    "doLate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lo haré después"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "attemptsToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intentos hasta la fecha:"])},
    "remainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intentos restantes:"])},
    "maximumReach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha alcanzado el número máximo de intentos. Comuníquese con el oficial de cumplimiento para discutir sus opciones."])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta"])},
    "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
    "backToQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visita:"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "submitAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentar respuestas"])},
    "answerOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de respuestas"])},
    "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista"])},
    "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestada"])},
    "notAnswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No respondido"])},
    "timeLimitText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto es un"])},
    "timeLimitText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["examen cronometrado por minutos"])},
    "examAboutToStartText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su examen está a punto de comenzar en"])},
    "examAboutToStartText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segundos"])},
    "startImmediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empieza inmediatamente"])},
    "startExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar examen"])},
    "aboutToSubmitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está a punto de enviar sus respuestas. Esto contará para el máximo de intentos. Si está satisfecho con sus respuestas, envíelas."])},
    "aboutToSubmitTextSimple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está a punto de enviar su examen. Esta acción no se puede deshacer. ¿Está seguro que desea enviar el examen?"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota:"])},
    "noteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede regresar y revisar sus respuestas antes de enviar."])},
    "submitExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar examen"])},
    "cancelExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar examen"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "noAnsGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin respuesta dada"])},
    "cancelExamText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está seguro de que desea cancelar sin completar su examen?"])},
    "cancelExamText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar el examen contará para sus intentos."])},
    "cancelExamText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes"])},
    "cancelExamText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intentos restantes"])},
    "examSubmittedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examen enviado"])},
    "closeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
    "thankYouText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por elegir nuestro portal de exámenes. Para más consultas, comuníquese con nuestro administrador."])},
    "examResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado del examen"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación:"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fuera de"])},
    "yourResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu resultado:"])},
    "toPassExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntaje necesario para aprobar el examen:"])},
    "examStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del examen:"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprobado"])},
    "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felicidades has pasado!"])},
    "PlsTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inténtalo de nuevo"])},
    "goToQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a la pregunta"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerta!"])},
    "blankAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede enviar respuestas en blanco."])},
    "englishOriginalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original en inglés"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está a punto de abandonar la página. Los cambios no se guardarán. Está seguro/a que quiere irse?"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está a punto de enviar su cuestionario. Esta acción no se puede deshacer. ¿Está seguro/a que quiere enviar?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su formulario ha sido enviado con éxito."])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno o más campos faltantes o errores encontrados. Por favor revise y actualice según sea necesario."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerta"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso guardado"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso no guardado. Inténtalo de nuevo por favor."])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso actualizado"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos actualizando el PDF…"])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF descargado con éxito"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF no descargado, por favor intente otra vez"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesión cerrada con éxito"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar la Inscripción"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización del perfil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de Contacto"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar Contraseña"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Contacto"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña Actual"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Contraseña"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar Contraseña"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su Nombre de Pila"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su Appellido"])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su Número de Teléfono"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su Cargo"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su Contraseña Actual"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese Nueva Contraseña"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese Nueva Contraseña"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es requerido"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las contraseñas deben ser idénticas"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se está procesando su solicitud…"])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrastre y suelte para cargar contenido!"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["... o haga clic para seleccionar un archivo de su computadora."])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de archivo"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de archivo"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño de archivo (bytes)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es requerido"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiniciar"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimine"])},
    "deleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que quiere eliminar este elemento? "])},
    "deleteHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimine"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
    "burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Taiwan"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
    "haitian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haitian Creole"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "khmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "lao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lao"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "portuguesebr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Brazilian"])},
    "portuguesemz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Mozambique"])},
    "portuguesepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Portugal"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "serbian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian"])},
    "sinhalese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
    "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "spanishmx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish Mexican"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato inválido"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El correo electrónico es invalido"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actual no válida."])}
  },
  "footerDashboard": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier información proporcionada será tratada de conformidad con la "])},
    "linkText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidad"])},
    "fullTex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier información proporcionada será tratada de conformidad con la Política de Privacidad de Tecan."])}
  },
  "annual-certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificaciones Anuales"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaración de cumplimiento de las leyes anticorrupción"])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se guarda…"])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empezar"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigue"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
  "okBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimine"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edite"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar el avance"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectar"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])}
}