const themes = {
  NONE: "none",
  INVERSE: "inverse",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SECONDARY_INVERSE: "secondary-inverse",
  SUCCESS: "success",
  SUCCESS_WHITE: "success-white",
  SUCCESS_INVERSE: "success-inverse",
  SUCCESS_INVERSE_WHITE: "success-inverse-white",
  SUCCESS_PILL: "success-pill",
  SUCCESS_PILL_INVERSE: "success-pill-inverse",
  WARNING: "warning",
  WARNING_INVERSE: "warning-inverse",
  ERROR: "error",
  ERROR_INVERSE: "error-inverse",
  STRONG_ERROR: "strong-error",
  STRONG_ERROR_INVERSE: "strong-error-inverse",
  MUTED_GREEN: "muted-green",
  MUTED_GREEN_INVERSE: "muted-green-inverse",
  STRONG_GREEN: "strong-green",
  STRONG_GREEN_INVERSE: "strong-green-inverse",
  MUTED_YELLOW: "muted-yellow",
  MUTED_YELLOW_INVERSE: "muted-yellow-inverse",
  VERY_STRONG: "very-strong",
  VERY_STRONG_INVERSE: "very-strong-inverse",
  STRONG: "strong",
  STRONG_INVERSE: "strong-inverse",
  MODERATE: "moderate",
  WEAK: "weak",
  GREY: "grey",
  LIGHT_GREY: "light-grey",
  LIGHT_GREY_INVERSE: "light-grey-inverse",
  DARK_GREY: "dark-grey",
  DARK_GREY_INVERSE: "dark-grey-inverse",
  ETHIXBASE_PRIMARY: "ethixbase-primary",
  ETHIXBASE_PRIMARY_INVERSE: "ethixbase-primary-inverse",
  INFO: "info",
  INFO_INVERSE: "info-inverse",
  GRADIENT_TWILIGHT_BLUE: "gradient-twilight-blue",
  GRADIENT_TWILIGHT_BLUE_INVERSE: "gradient-twilight-blue-inverse",
  GRADIENT_GREEN: "gradient-green",
  GRADIENT_GREEN_INVERSE: "gradient-green-inverse",
  GRADIENT_ORANGE: "gradient-orange",
  GRADIENT_ORANGE_INVERSE: "gradient-orange-inverse",
  GRADIENT_BLUE: "gradient-blue",
  GRADIENT_BLUE_INVERSE: "gradient-blue-inverse",
  GRADIENT_GREY: "gradient-grey",
  GRADIENT_GREY_INVERSE: "gradient-grey-inverse",
  GRADIENT_RED: "gradient-red",
  GRADIENT_RED_INVERSE: "gradient-red-inverse"
};

const typographySize = {
  BODY_TEXT: "body-text",
  BODY_TEXT_SMALL: "body-text--small",
  BODY_TEXT_SMALL_BOLD: "body-text--small-bold",
  BODY_TEXT_ITALIC: "body-text--italic",
  BODY_TEXT_BOLD: "body-text--bold",
  LARGE_TEXT: "large-text",
  LARGE_TEXT_BOLD: "large-text--bold",
  EXTRA_LARGE_TEXT: "extra-large-text",
  EXTRA_LARGE_TEXT_BOLD: "extra-large-text--bold",
  DISPLAY_TEXT: "display-text",
  HEADING_ONE: "heading--one",
  HEADING_ONE_BOLD: "heading--one-bold",
  HEADING_TWO: "heading--two",
  HEADING_TWO_BOLD: "heading--two-bold",
  HEADING_THREE: "heading--three",
  HEADING_THREE_BOLD: "heading--three-bold",
  HEADING_FOUR: "heading--four",
  HEADING_FOUR_BOLD: "heading--four-bold"
};

const sizes = {
  DEFAULT: "",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large"
};

const space = {
  NONE: "none",
  SMALL: "small",
  MODERATE: "moderate",
  LARGE: "large"
};

const border = {
  NONE: "none",
  SOLID: "solid",
  DASHED: "dashed",
  DOTTED: "dotted",
  SOLID_OUTLINE: "solid-outline"
};

const chartStyles = {
  TITLE: {
    fontSize: "1.4rem",
    fontWeight: "400",
    color: "#000000",
    fill: "#000000",
    fontFamily: "Roboto, sans-serif;"
  }
};

const iconSizes = {
  DEFAULT: 16,
  SMALL: 12,
  MEDIUM: 20,
  LARGE: 25
};

const dropdownPosition = {
  BOTTOM_RIGHT: "bottom-right",
  TOP_LEFT: "top-left"
};

export {
  space,
  sizes,
  typographySize,
  themes,
  border,
  chartStyles,
  iconSizes,
  dropdownPosition
};
