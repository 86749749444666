const authEndpoints = {
  AUTHENTICATION: "authenticate/eb360",
  FORGOT_PASSWORD: "forgot/password",
  CHANGE_PASSWORD: "v1/change/password"
};

const chartEndpoints = {
  CHART_PRI: "chart/pri",
  CHART_COUNTRY: "chart/country",
  CHART_MONITORING_ALERTS: "chart/monitoring/alerts",
  CHART_TP_STATE_DATA: "chart/tpstatdata",
  CHART_SANCTIONS_ALERT: "chart/sanctions/alerts",
  CHART_IDD_PLUS_PRE_REMEDIATION: "chart/iddplus/preremediation",
  CHART_IDD_PLUS_POST_REMEDIATION: "chart/iddplus/postremediation",
  CHART_EDD_RESULTS: "chart/edd/results",
  CHART_THIRD_PARTY_COUNTRY: "chart/thirdparty/country",
  CHART_GET_THIRD_PARTY_IDD_QUESTION: "chart/getthirdpartiesddqquestion",
  CHART_GET_THIRD_PARTY_IDD_QUESTION_EXCEL:
    "chart/getthirdpartiesddqquestioninexcel",
  CHART_CUSTOM_ATTRIBUTE_DATA: (companyId, customAttributeId) =>
    `v1/${companyId}/custom-attributes/${customAttributeId}`,
  CUSTOM_ATTRIBUTES_THIRD_PARTIES_LIST: ({
    companyId,
    customAttributeId,
    value
  }) =>
    `v1/${companyId}/custom-attributes/${customAttributeId}/${value}/third-parties`
};

const chartTableEndpoints = {
  CHART_THIRD_PARTY_COUNTRY_TABLE: "chart/thirdparty/country/table",
  CHART_EDD_RESULTS_TABLE: "chart/edd/results/table",
  CHART_IDD_PLUS_PRE_REMEDIATION_TABLE: "chart/iddplus/preremediation/table",
  CHART_IDD_PLUS_POST_REMEDIATION_TABLE: "chart/iddplus/postremediation/table",
  CHART_SANCTIONS_ALERT_TABLE: "chart/sanctions/alerts/table",
  DASHBOARD_RISK_SCORE_TABLE: "dashboard/riskscore/table"
};

const dashboardEndpoints = {
  DASHBOARD_CHART_RISK_SCORE: "dashboard/riskscore",
  DASHBOARD_GET_DDQ_STATE: "dashboard/getddqstate",
  DASHBOARD_RISK_SCORE_TABLE: "dashboard/riskscore/table"
};

const platformDetailsEndpoints = {
  PLATFORM_VIEW_DETAILS: ({ userViewId, companyId }) =>
    `v1/platform/view/detail/${userViewId}/${companyId}`,
  PLATFORM_VIEW_DELETE: "v1/platform/view/delete",
  PLATFORM_VIEW_UPDATE: (id) => `v1/platform/view/update/${id}`,
  PLATFORM_USERS_LIST_ALL: "platform/users/list/all",
  PLATFORM_USERS_LIST: "platform/users/list",
  PLATFORM_PYRAMID_TOKEN: ({ companyId, domain }) =>
    `v1/platform/${companyId}/pyramid-token?domain=${domain}`,
  PRODUCT_VERSIONS_LIST: "v1/versions"
};

const thirdPartyEndpoints = {
  THIRD_PARTY_UPLOAD_LOGO: "thirdparty/uploadlogo",
  THIRD_PARTY_DETAIL: "thirdparty/detail",
  THIRD_PARTY_SAVE_CONTACT: "thirdparty/savecontract",
  THIRD_PARTY_UPDATE_THIRD_PARTY: "thirdparty/update/thirdparty",
  THIRD_PARTY_CONTACT_LIST: "thirdparty/contacts/list",
  THIRD_PARTY_QUESTION_REPORT: "thirdparty/getddqquestionreport",
  THIRD_PARTIES_WITH_PRI: "v1/getthirdPartieswithapri",
  THIRD_PARTY_FILTERS_THIRD_PARTIES: "thirdparty/filters/thirdparties",
  THIRD_PARTY_BULK_UPLOAD_SAMPLE_FILE: "thirdparty/bulk/upload/samplefile",
  THIRD_PARTY_BULK_UPDATE: "thirdparty/bulk/update",
  THIRD_PARTY_MANAGE_DOCUMENTS: "thirdparty/managedocuments",
  THIRD_PARTY_DELETE_DOCUMENT: "thirdparty/deletedocumentfortp",
  THIRD_PARTY_UPLOAD_DOCUMENT: "thirdparty/uploaddocument",
  THIRD_PARTY_T_COMPLIANCE_ORDER_TYPE_PRODUCT: (productId) =>
    `thirdparty/product/order/types/${productId}`,
  THIRD_PARTY_UPLOAD_ORDER_REPORT: "thirdparty/uploadproductreport",
  THIRD_PARTY_PRODUCTS: (thirdPartyId) => `thirdparty/product/${thirdPartyId}`,
  UPDATE_AUTO_MSQ_STATUS: "thirdparty/updateautomsqstatus",
  COMPANY_ORGANIZATIONS_SEARCH: (companyId) =>
    `v1/corporate-data/companies/${companyId}/organisations/search`,
  COMPANY_ORGANIZATIONS_CORPORATE_DATA: (companyId, organisationId) =>
    `v1/corporate-data/companies/${companyId}/organisations/${organisationId}`,
  ORGANIZATIONS_CHANGE_LOGS_DATA: (organisationId) =>
    `v1/corporate-data/monitoring/organisations/${organisationId}/logs`,
  COMPANY_IDENTIFIER_TYPES: (companyId, type) =>
    `v1/eraf/companies/${companyId}/types/${type}/identifiers`,
  THIRD_PARTY_FORMS_LANGUAGES_LIST: (companyId) =>
    `v1/getformslanguageslist/${companyId}`,
  THIRD_PARTY_OC_STATES_LIST: "v1/get-oc-states-list",
  THIRD_PARTY_HEADER_CONFIG_DATA: (companyId, memberId) =>
    `v1/get-header-config/${companyId}/${memberId}`,
  THIRD_PARTY_EDD_CLIENT_ALL_PACKAGES: (companyId) =>
    `thirdparty/edd/client/all/packages/${companyId}`,
  CREATE_THIRD_PARTY: ({ companyId = "", thirdPartyId = "" }) =>
    `ubo/company/${companyId}/third-party/${thirdPartyId}`,
  SEND_EMAIL_FOR_THIRD_PARTY_BULK_UPDATE_EXCEL_SHEET:
    "thirdparty/download-template-for-bulk-update",
  SEND_EMAIL_FOR_FILTERED_THIRD_PARTY_LIST: "thirdparty/filtered-export",
  THIRD_PARTY_RISK_ALERTS: (thirdPartyId) =>
    `thirdparty/${thirdPartyId}/risk/alerts`
};

const platformSettingsEndpoints = {
  PLATFORM_SETTINGS: "platform-settings",
  FETCH_PLATFORM_SETTINGS_DATA: "thirdparty/getplatformsettingdata",
  UPDATE_PLATFORM_SETTING_DATA: "thirdparty/updateplatformsettingdata"
};

const permissionEndpoints = {
  COMPANY_PERMISSIONS: (companyId) =>
    `v1/platform/${companyId}/permissions/features`,
  COMPANY_USER_PERMISSIONS: (companyId) =>
    `v1/platform/${companyId}/permissions/user`,
  COMPANY_USER_PERMISSIONS_SUBSET: (companyId) =>
    `v1/rnp/companies/${companyId}/permissions/user/list`
};

const companyEndpoints = {
  SELECT_COMPANY: "v1/clients/change/selected/company",
  GET_COMPANIES: (userId) => `get/user/accounts/${userId}`,
  UPDATE_COMPANY_USER: "edit/platform/user",
  ADD_COMPANY_USER: "add/platform/user",
  DELETE_COMPANY_USER: "delete/platform/user",
  COMPANY_VOUCHERS: "thirdparty/remaining/vouchers",
  COMPANY_FEATURES: (companyId) => `${companyId}/platform_features`
};

const actionsEndpoints = {
  ACTIONS: (companyId) => `v1/eraf/companies/${companyId}/actions`,
  ACTION: (companyId, id) => `v1/eraf/companies/${companyId}/actions/${id}`,
  AMEND_ACTION: (companyId, actionId) =>
    `v1/eraf/companies/${companyId}/actions/${actionId}`,
  CLONE_ACTION: (companyId, actionId) =>
    `v1/eraf/companies/${companyId}/actions/${actionId}/clone`
};

const expectedDataEndpoints = {
  FETCH_PARSED_ACTIONS_DATA: (companyId) =>
    `v1/eraf/companies/${companyId}/actions/expected-data/parse`,
  FETCH_PROXY_EXPECTED_DATA: (companyId) =>
    `v1/eraf/companies/${companyId}/actions/proxy-variable/parse`,
  FETCH_EXPECTED_DATA: (companyId = "") =>
    `v1/eraf/companies/${companyId}/actions/expected-data/entities`
};

const automationsEndpoints = {
  AUTOMATIONS: (companyId) => `v1/eraf/companies/${companyId}/automations`,
  AMEND_AUTOMATION: (companyId, automationId) =>
    `v1/eraf/companies/${companyId}/automations/${automationId}`,
  AUTOMATION_CONDITIONS: (companyId) =>
    `v1/eraf/companies/${companyId}/conditions`
};

const customAttributesEndpoints = {
  CUSTOM_ATTRIBUTES_TYPES_GET: "thirdparty/custom/attributes/get/types",
  CUSTOM_ATTRIBUTES_GET: "thirdparty/custom/attributes/get",
  CUSTOM_ATTRIBUTES_TOGGLE_STATUS: "thirdparty/custom/attributes/update/status",
  CUSTOM_ATTRIBUTE_CREATE: "thirdparty/custom/attributes/create",
  CUSTOM_ATTRIBUTE_UPDATE: "thirdparty/custom/attributes/update",
  CUSTOM_ATTRIBUTE_DELETE: "thirdparty/custom/attributes/delete",
  CUSTOM_ATTRIBUTE_SEQUENCE: "thirdparty/custom/attributes/sequence",
  CUSTOM_ATTRIBUTE_WIDGETS_LIST: (companyId) =>
    `v1/${companyId}/widgets/custom-attributes`
};

const userEndpoints = {
  USER_HOME_PAGES: (companyId, userId) =>
    `v1/all/active/pages/${companyId}/${userId}`,
  GET_ROLES: (companyId) => `v1/rnp/companies/${companyId}/roles`,
  GET_ROLE: (companyId, roleId) =>
    `v1/rnp/companies/${companyId}/roles/${roleId}`,
  CREATE_ROLE: (companyId) => `v1/rnp/companies/${companyId}/roles`,
  UPDATE_ROLE: (companyId, roleId) =>
    `v1/rnp/companies/${companyId}/roles/${roleId}`,
  DELETE_ROLE: (companyId, roleId) =>
    `v1/rnp/companies/${companyId}/roles/${roleId}`
};

const currenciesEndpoint = {
  CURRENCIES_GET: "v1/get/currencies"
};

const esgRatingEndpointsWithOutApiEndpoint = {
  ESG_EXPORT_REPORTS: `../thirdparty/thirdpartyesgdatainexcel`
};

const esgRatingEndpoints = {
  ESG_OVERALL_RATINGS: (companyId) => `v1/esg/companies/${companyId}/ratings`,
  ESG_RESILIENCE_STRENGTH: (companyId) =>
    `v1/esg/companies/${companyId}/resilience`,
  ESG_RISK_SEVERITY: (companyId) => `v1/esg/companies/${companyId}/risk`,
  ESG_THIRD_PARTY_LIST_OF_REPORTS: ({ companyId, thirdPartyId }) =>
    `v1/esg/companies/${companyId}/third-parties/${thirdPartyId}/reports`,
  ESG_THIRD_PARTY_SINGLE_REPORT: ({ companyId, thirdPartyId, reportId }) =>
    `v1/esg/companies/${companyId}/third-parties/${thirdPartyId}/reports/${reportId}`,
  ESG_STATUS: (companyId) => `v1/esg/companies/${companyId}/status`,
  ESG_THIRD_PARTIES: (companyId, params = "") =>
    `v1/esg/companies/${companyId}/third-parties${params}`,
  ESG_SCORE_BY_RISK_AND_RESILIENCE: (companyId) =>
    `v1/esg/companies/${companyId}/score-by-risk-and-resilience`,
  ESG_RISK_ALERTS: ({ companyId, thirdPartyId, params = "" }) =>
    `v1/esg/companies/${companyId}/third-parties/${thirdPartyId}/alerts${params}`,
  ...esgRatingEndpointsWithOutApiEndpoint
};

const uboEndpoints = {
  UBO_THIRD_PARTY_LIST: `ubos`,
  UBO_ENTITY_DATA: `ubo`,
  UBO_DUE_DILIGENCE_REPORTS: ({ companyId, thirdPartyId }) =>
    `v1/companies/${companyId}/third-parties/${thirdPartyId}/idds`
};

const userEmailTemplatesEndpoints = {
  USER_EMAIL_TEMPLATES: (companyId) =>
    `v1/companies/${companyId}/email-templates`,
  USER_EMAIL_TEMPLATE: (companyId, templateId) =>
    `v1/companies/${companyId}/email-templates/${templateId}`,
  CLONE_USER_EMAIL_TEMPLATE: (companyId, templateId) =>
    `v1/companies/${companyId}/email-templates/${templateId}/clone`
};

const taskTemplatesEndpoints = {
  TASK_TEMPLATES: (companyId) =>
    `v1/eraf/companies/${companyId}/task-templates`,
  TASK_TEMPLATE: ({ companyId, id }) =>
    `v1/eraf/companies/${companyId}/task-templates/${id}`,
  CLONE_TASK_TEMPLATE: ({ companyId, id }) =>
    `v1/eraf/companies/${companyId}/task-templates/${id}/clone`,
  TASK_STATUS_WIDGET_DATA: ({ companyId = "" } = {}) =>
    `v1/companies/${companyId}/tasks/status`,
  TASK_AGE_WIDGET_DATA: ({ companyId = "" } = {}) =>
    `v1/companies/${companyId}/tasks/age`,
  TASK_OUTCOMES_WIDGET_DATA: ({ companyId = "" } = {}) =>
    `v1/companies/${companyId}/tasks/outcomes`,
  TASK_ASSIGNEE_WIDGET_DATA: ({ companyId = "" } = {}) =>
    `v1/companies/${companyId}/tasks/assignee`
};

const onboardingTasksEndpoints = {
  ONBOARDING_TASKS: `v1/mytpp/tasks`,
  ONBOARDING_TASK: (taskId) => `v1/mytpp/task/${taskId}`
};

const tasksEndpoints = {
  FETCH_TASKS: (companyId) => `v1/eraf/companies/${companyId}/tasks/list`,
  TASK: ({ companyId, taskId }) =>
    `v1/eraf/companies/${companyId}/tasks/${taskId}`,
  COMPLETE_TASK: ({ companyId, taskId }) =>
    `v1/eraf/companies/${companyId}/tasks/${taskId}/select-outcome`,
  REOPEN_TASK: ({ companyId, taskId }) =>
    `v1/eraf/companies/${companyId}/tasks/${taskId}/re-open`
};

const trainingEndpoints = {
  ADD_USER_TRAINING_LEARNER: "v1/training/learners",
  THIRD_PARTY_TRAINING_LIST: `v1/training/courses`,
  THIRD_PARTY_TRAINING: "v1/training/course",
  USER_TRAINING_LIST: "v1/training/users/managed-courses",
  REMOVE_TRAINING_LEARNER: "v1/training/delete/learners",
  ALL_TRAINING_REGISTRATION_COUNT: "v1/training/courses/registrations/count",
  TRAINING_STATUS_WIDGET_DATA: ({ courseId = "" } = {}) =>
    `v1/training/course/${courseId}/status`
};

const languageEndpoints = {
  FETCH_LANGUAGES: "v1/languages/list"
};

const productEndpoints = {
  FETCH_PRODUCT_LIST: "v1/pnp/products",
  ASSIGN_AVAILABLE_PRODUCT_TO_PLATFORM: "v1/pnp/courses/assign"
};

const widgetEndpoints = {
  FETCH_WIDGET_LIST: "v1/platform/view/get/widgets"
};

export const endpoints = {
  ...authEndpoints,
  ...chartEndpoints,
  ...dashboardEndpoints,
  ...platformDetailsEndpoints,
  ...thirdPartyEndpoints,
  ...customAttributesEndpoints,
  ...userEndpoints,
  ...chartTableEndpoints,
  ...currenciesEndpoint,
  ...esgRatingEndpoints,
  ...actionsEndpoints,
  ...platformSettingsEndpoints,
  ...automationsEndpoints,
  ...companyEndpoints,
  ...permissionEndpoints,
  ...uboEndpoints,
  ...userEmailTemplatesEndpoints,
  ...expectedDataEndpoints,
  ...onboardingTasksEndpoints,
  ...taskTemplatesEndpoints,
  ...tasksEndpoints,
  ...trainingEndpoints,
  ...languageEndpoints,
  ...productEndpoints,
  ...widgetEndpoints
};
