<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <div class="mt-3">
        <!-- filter btn -->
        <b-button
          :style="
            isComparativeFilterApply
              ? 'background-color: #4D9DE0;color:#fff'
              : ''
          "
          class="mr-2 mb-1"
          size="sm"
          variant="outline-dark"
          @click="advanceFilterForms = !advanceFilterForms"
        >
          <i aria-hidden="true" class="fa fa-filter"></i>
        </b-button>
        <!-- filter btn -->

        <!-- download btn -->
        <b-button
          :disabled="!isComparativeFilterApply"
          class="mr-2 mb-1"
          size="sm"
          variant="outline-dark"
          @click="printPageContent"
        >
          <i aria-hidden="true" class="fa fa-print"></i>
        </b-button>
        <!-- download btn -->
      </div>
    </div>
    <!-- filter collapse start -->
    <b-collapse v-model="advanceFilterForms">
      <b-card class="mb-2 bg-light">
        <b-row class="text-center">
          <b-col cols="12">
            <h4>{{ $t("eb360.manageThirdParties.filterResult") }}</h4>

            <div class="text-right">
              <!-- riskScoreChartLoading &&  -->
              <b-btn
                :disabled="riskScoreChartLoading"
                class="mx-1"
                variant="outline-danger"
                @click="onResetFilters"
                >Clear Filters
              </b-btn>
              <b-btn
                :disabled="
                  !form.dateSentFrom ||
                  !form.dateSentTo ||
                  !form.countType ||
                  !form.dateSentFromCompare ||
                  !form.dateSentToCompare
                "
                class="mx-1"
                variant="outline-success"
                @click="onApplyFilters"
                >Apply
              </b-btn>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-row>
              <b-col cols="6">
                <DatePicker
                  :labelOptions="{ label: 'Date Sent From', isRequired: false }"
                  :datePickerOptions="{
                    value: form.dateSentFrom,
                    enableTimePicker: false,
                    maxDate: maxDate,
                    'onUpdate:modelValue': (value) => {
                      form.dateSentFrom = makeDateToMatchFormat({
                        stringifiedDate: new Date(value).toISOString(),
                        dateFormat: eb360DateFormat.DEFAULT_DATE_FORMAT_HYPHEN
                      });
                    }
                  }"
                />
              </b-col>
              <b-col cols="6">
                <DatePicker
                  :labelOptions="{ label: 'Date Sent To', isRequired: false }"
                  :datePickerOptions="{
                    value: form.dateSentTo,
                    enableTimePicker: false,
                    maxDate: maxDate,
                    disabled: !form.dateSentFrom,
                    minDate: form.dateSentFrom,
                    'onUpdate:modelValue': (value) => {
                      form.dateSentTo = makeDateToMatchFormat({
                        stringifiedDate: new Date(value).toISOString(),
                        dateFormat: eb360DateFormat.DEFAULT_DATE_FORMAT_HYPHEN
                      });
                    }
                  }"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Count Type:">
              <v-select
                v-model="form.countType"
                :clearable="false"
                :options="countTypeOptions"
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <DatePicker
              :labelOptions="{ label: 'Date Sent From', isRequired: false }"
              :datePickerOptions="{
                value: form.dateSentFromCompare,
                enableTimePicker: false,
                maxDate: maxDate,
                disabled: !form.dateSentTo,
                'onUpdate:modelValue': (value) => {
                  form.dateSentFromCompare = makeDateToMatchFormat({
                    stringifiedDate: new Date(value).toISOString(),
                    dateFormat: eb360DateFormat.DEFAULT_DATE_FORMAT_HYPHEN
                  });
                }
              }"
            />
          </b-col>
          <b-col cols="4">
             <DatePicker
              :labelOptions="{ label: 'Date Sent From', isRequired: false }"
              :datePickerOptions="{
                value: form.dateSentToCompare,
                enableTimePicker: false,
                maxDate: maxDate,
                disabled: !form.dateSentFromCompare,
                'onUpdate:modelValue': (value) => {
                  form.dateSentToCompare = makeDateToMatchFormat({
                    stringifiedDate: new Date(value).toISOString(),
                    dateFormat: eb360DateFormat.DEFAULT_DATE_FORMAT_HYPHEN
                  });
                }
              }"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <!-- filter collapse end -->

    <!-- filter banner -->
    <b-alert
      v-model="isComparativeFilterApply"
      class="mb-3 text-left"
      style="margin: 0px 0px 1px 0px; background: #4d9de0"
      variant="warning"
    >
      <b style="color: white">You are viewing filtered data.</b>
    </b-alert>
    <!-- filter banner -->

    <b-overlay :show="isPrintLoader">
      <div
        v-if="isComparativeFilterApply"
        id="analytics"
        style="
          height: calc(100vh - 13rem);
          overflow-y: auto;
          overflow-x: hidden;
        "
      >
        <!--form data start  -->
        <div v-if="isComparativeFilterApply" style="display: flex">
          <h5 class="text-left mb-3 ml-4" style="font-size: 17px">
            <font-awesome-icon icon="chart-bar" />
            {{ questionareTitles }} Comparative
          </h5>
        </div>
        <div
          class="mt-3 mb-2x d-flex"
          style="overflow-x: auto; overflow-y: hidden"
        >
          <DdqCompareTotalCountCard
            :avgDdqStatPercent="avgDdqStatPercent"
            :isPrintContent="isPrintContent"
            :overallValues="overallValues"
          />
          <DdqCompareInProgressCountCard
            :avgDdqStatPercent="avgDdqStatPercent"
            :isPrintContent="isPrintContent"
            :overallValues="overallValues"
          />
          <DdqCompareCompletedCountCard
            :avgDdqStatPercent="avgDdqStatPercent"
            :isPrintContent="isPrintContent"
            :overallValues="overallValues"
          />
          <DdqCompareNotStartedCountCard
            :avgDdqStatPercent="avgDdqStatPercent"
            :isPrintContent="isPrintContent"
            :overallValues="overallValues"
          />
          <DdqCompareAlternateCountCard
            :avgDdqStatPercent="avgDdqStatPercent"
            :isPrintContent="isPrintContent"
            :overallValues="overallValues"
          />
        </div>
        <!--form data end  -->

        <b-row class="mt-3">
          <!-- graphical representation of form data start -->
          <b-col cols="6">
            <div v-if="!isLoading">
              <AverageSupplierRiskComparativeComponent
                :avgRiskScorePercent="avgRiskScorePercent"
                :riskOpt="riskOpt"
                :riskScoreChartLoading="riskScoreChartLoading"
              />
            </div>
            <div v-else class="text-center">
              <b-spinner variant="primary"></b-spinner>
            </div>
          </b-col>
          <b-col :style="isPrintContent ? 'margin-bottom:500px' : ''" cols="6">
            <div v-if="!isLoading">
              <SupplierRiskComparativeComponent
                :avgSupplierRiskDistributionScorePercent="
                  avgSupplierRiskDistributionScorePercent
                "
                :supplierRiskBarChartOptions="supplierRiskBarChartOptions"
              />
            </div>
          </b-col>
          <!-- graphical representation of form data end -->

          <!-- bar chart of form data start -->
          <b-col
            v-for="(sec, sIndex) in chartConfig.totalSections"
            :key="sIndex"
            class="mt-3"
            cols="6"
          >
            <DdqComparativeGraph :sec="sec" />
          </b-col>

          <b-col
            :style="isPrintContent ? 'margin-top:400px' : ''"
            class="mt-2"
            cols="6"
          >
            <b-row v-if="!isLoading">
              <b-col cols="12">
                <EndStateDistributionComparativeComponent
                  :ddqStatBarChartOptions="ddqStatBarChartOptions"
                />
              </b-col>
            </b-row>
          </b-col>
          <!-- bar chart of form data end -->
        </b-row>
        <ThirdPartyAnalysisModalTable
          :company-id="companyId"
          :modal-title="mapDrillDownOptions.modalTitle"
          :query-specific-data="mapDrillDownOptions.querySpecificData"
          :table-id="mapDrillDownOptions.tableId"
          :table-title="mapDrillDownOptions.tableTitle"
          @hideModal="hideModal"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ThirdPartyAnalysisModalTable from "@/organisms/ThirdPartyAnalysisModalTable/ThirdPartyAnalysisModalTable";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import { HTTP, makeAuthorizationHeader, makeDateToMatchFormat } from "@/utils";
import EB2ColorPallete from "@/utils/eb2ColorPallete.json";
import { EventBus } from "@/event-bus.js";
import AverageSupplierRiskComparativeComponent from "./analyticsComponents/averageSupplierRiskComparativeComponent.vue";
import SupplierRiskComparativeComponent from "./analyticsComponents/supplierRiskComparativeComponent.vue";
import DdqComparativeGraph from "./analyticsComponents/ddqComparativeGraph.vue";
import EndStateDistributionComparativeComponent from "./analyticsComponents/EndStateDistributionComparativeComponent.vue";
import DdqCompareTotalCountCard from "./analyticsComponents/ddqCompareTotalCountCard.vue";
import DdqCompareInProgressCountCard from "./analyticsComponents/ddqCompareInProgressCountCard.vue";
import DdqCompareCompletedCountCard from "./analyticsComponents/ddqCompareCompletedCountCard.vue";
import DdqCompareNotStartedCountCard from "./analyticsComponents/ddqCompareNotStartedCountCard.vue";
import DdqCompareAlternateCountCard from "./analyticsComponents/ddqCompareAlternateCountCard.vue";
import { queryObjectKeys, localStorageItem, eb360DateFormat } from "@/constants";
import { mapState } from "vuex";
import DatePicker from "@/molecules/DatePicker/DatePicker";

export default {
  name: "DashboardStats",
  components: {
    AverageSupplierRiskComparativeComponent,
    SupplierRiskComparativeComponent,
    DdqComparativeGraph,
    EndStateDistributionComparativeComponent,
    DdqCompareTotalCountCard,
    DdqCompareInProgressCountCard,
    DdqCompareCompletedCountCard,
    DdqCompareNotStartedCountCard,
    DdqCompareAlternateCountCard,
    ThirdPartyAnalysisModalTable,
    DatePicker
  },
  data() {
    return {
      eb360DateFormat,
      isPrintContent: false,
      isPrintLoader: false,
      maxDate: new Date(),
      thirdPartyRisk: null,
      form: {
        dateSentFrom: null,
        dateSentTo: null,
        dateSentFromCompare: null,
        dateSentToCompare: null,
        countType: [
          {
            value: "0",
            text: "Only Most Recent",
            label: "Only Most Recent"
          }
        ]
      },
      countTypeOptions: [
        {
          value: "0",
          text: "Only Most Recent",
          label: "Only Most Recent"
        },
        {
          value: "1",
          text: "All",
          label: "All"
        }
      ],
      supplierRiskBarDateFrom: [],
      supplierRiskBarChartOptions: {
        chart: {
          type: "bar",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: ""
        },
        colors: ["#3ddc97", "#F7A35C", "#e71d36", "#eeeeee"],
        subtitle: {
          text: ""
        },
        xAxis: {
          categories: [],
          title: {
            text: ""
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
            align: "high"
          },
          labels: {
            overflow: "justify"
          }
        },
        tooltip: {
          valueSuffix: "%"
        },
        plotOptions: {
          groupPadding: 0.2,
          pointWidth: 14,
          pointPadding: 0.2,
          bar: {
            dataLabels: {
              enabled: true,
              formatter() {
                return `${this.y}%`;
              }
            }
          },
          series: {
            pointWidth: 10,
            groupPadding: 0.2,
            pointPadding: 0.01
          }
        },
        credits: {
          enabled: false
        },
        series: []
      },
      ddqStatBarChartOptions: {
        chart: {
          type: "bar",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: ""
        },
        subtitle: {
          text: ""
        },
        colors: [
          "#b4c7e7",
          "#4d9de0",
          "#f2f2f2",
          "#363537",
          "#515254",
          "#a3a3ac"
        ],
        xAxis: {
          categories: [],
          title: {
            text: ""
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
            align: "high"
          },
          labels: {
            overflow: "justify"
          }
        },
        tooltip: {
          valueSuffix: "%"
        },
        plotOptions: {
          bar: {
            groupPadding: 0.2,
            pointWidth: 14,
            pointPadding: 0.2,
            dataLabels: {
              enabled: true,
              formatter() {
                return `${this.y}%`;
              }
            }
          },
          series: {
            pointWidth: 10,
            groupPadding: 0.2,
            pointPadding: 0.01
          }
        },
        credits: {
          enabled: false
        },
        series: []
      },
      barChartOptions: {
        chart: {
          type: "bar",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: null
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          borderWidth: 1
        },
        credits: {
          enabled: false
        },
        series: []
      },
      advanceFilterForms: true,
      isComparativeFilterApply: false,
      questionareFormId: null,
      breadcrumbItems: [
        {
          text: "Home",
          active: true
        },
        {
          text: "Analytics",
          active: true
        },
        {
          text: "Questionnaire",
          active: true
        }
      ],
      riskScoreChartLoading: false,
      mapCharts: false,
      mapChartOptions: {
        chart: {
          borderWidth: 0,
          map: worldMap,
          height: 600,
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: "Third Party Business Operations",
          style: {
            fontSize: "17px"
          }
        },
        subtitle: {
          text: ""
        },
        credits: {
          enabled: false
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: "bottom"
          }
        },
        series: []
      },
      riskOpt: [],
      riskScoreChartOptions: {
        chart: {
          type: "solidgauge",
          backgroundColor: null,
          height: 300,
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        exporting: {
          enabled: false
        },
        title: {
          text: "Avg. Third Party Risk Score",
          style: {
            fontSize: "17px"
          }
        },
        tooltip: {
          enabled: false
        },
        pane: {
          center: ["50%", "50%"],
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: "#EEE",
            innerRadius: "60%",
            outerRadius: "100%",
            shape: "arc"
          }
        },
        yAxis: {
          min: 0,
          max: 10,
          stops: [],
          labels: {
            enabled: false,
            y: 16
          },
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            y: -70
          }
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true
            }
          }
        },
        series: []
      },
      supplierRiskChartOptions: {
        chart: {
          type: "bar",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: "Third Party Risk",
          style: {
            fontSize: "17px"
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b>"
        },
        subtitle: {
          text: ""
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        xAxis: {
          categories: ["Date1", "Date2"],
          title: {
            text: ""
          }
        },
        colors: [
          EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
          EB2ColorPallete.EB2ColorPallete.chatsAmberColor,
          EB2ColorPallete.EB2ColorPallete.chatsRedColor,
          EB2ColorPallete.EB2ColorPallete.chatsGrayColor
        ],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        },
        series: []
      },
      perPage: 10,
      currentPage: 1,
      configModalShow: false,
      showSectionTitle: false,
      showQuestionTitle: false,
      chartConfig: {
        totalSections: []
      },
      ddqQuestionChartDates: null,
      overallValues: [100, 15, 30, 20, 100],
      max: 100,
      formsOptions: [],
      isLoading: false,
      layoutOptions: [
        {
          val: 12,
          key: 1
        },
        {
          val: 6,
          key: 2
        }
      ],
      selectedDDq: null,
      selectedDDqTitle: null,
      overallStats: {},
      selectedLayout: {
        val: 6,
        key: 2
      },
      selectedChartType: "Bar",
      fields: [
        {
          key: "name",
          label: "Options"
        },
        {
          key: "y",
          label: "Count"
        }
      ],
      questionareTitles: null,
      compareDate: null,
      riskScoreData: null,
      avgRiskScorePercent: null,
      avgDdqStatPercent: null,
      avgSupplierRiskDistributionScorePercent: null,
      supplierRiskThirdParties: null,
      isLoadingExport: false,
      ddqQuestionStatAvg: null,
      mapDrillDownOptions: {
        tableTitle: "",
        tableId: "",
        modalTitle: "",
        querySpecificData: null
      }
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId
    })
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.questionareTitles = null;
        (this.form = {
          dateSentFrom: null,
          dateSentTo: null,
          dateSentFromCompare: null,
          dateSentToCompare: null,
          countType: [
            {
              value: "0",
              text: "Only Most Recent",
              label: "Only Most Recent"
            }
          ]
        }),
          (this.isComparativeFilterApply = false);
        this.advanceFilterForms = true;
        this.questionareFormId = this.$route.params.id;
        this.getAllForms();
      }
    }
  },
  async mounted() {
    await this.$router.isReady();
    let filteredFormData = JSON.parse(
      localStorage.getItem(localStorageItem.COMPARE_ANALYTICS)
    );
    if (filteredFormData) {
      this.form = filteredFormData;
      this.advanceFilterForms = false;
    }
    this.questionareFormId = this.$route.params.id;
    this.getAllForms();
  },

  methods: {
    makeDateToMatchFormat,
    hideModal() {
      this.mapDrillDownOptions.tableTitle = "";
      this.mapDrillDownOptions.tableId = "";
      this.mapDrillDownOptions.modalTitle = "";
      this.mapDrillDownOptions.querySpecificData = null;
    },
    showHideCardForPrint() {
      this.isPrintContent = true;
      this.isPrintLoader = true;
    },
    printPageContent() {
      this.showHideCardForPrint();
      setTimeout(() => {
        this.isPrintLoader = false;
        if (this.isPrintContent) {
          let originalContents = document.body.innerHTML;
          document.body.innerHTML =
            document.getElementById("analytics").innerHTML;
          window.print();
          document.body.innerHTML = originalContents;
          window.location.reload();
        }
      }, 3000);
    },
    onApplyFilters() {
      let filterObj = {
        dateSentFrom: this.form.dateSentFrom,
        dateSentTo: this.form.dateSentTo,
        countType: this.form.countType,
        dateSentFromCompare: this.form.dateSentFromCompare,
        dateSentToCompare: this.form.dateSentToCompare,
        path: this.$route.path
      };
      localStorage.setItem(
        localStorageItem.COMPARE_ANALYTICS,
        JSON.stringify(filterObj)
      );
      this.getAllForms();
      this.advanceFilterForms = false;
      if (this.form.dateSentFromCompare && this.form.dateSentToCompare) {
        this.isComparativeFilterApply = true;
      }
    },
    onResetFilters() {
      this.form = {
        dateSentFrom: null,
        dateSentTo: null,
        dateSentFromCompare: null,
        dateSentToCompare: null,
        countType: [
          {
            value: "0",
            text: "Only Most Recent",
            label: "Only Most Recent"
          }
        ]
      };
      localStorage.removeItem(localStorageItem.COMPARE_ANALYTICS);
      this.getAllForms();
      this.isComparativeFilterApply = false;
    },
    async getSupplierRiskBarChartData(formId) {
      this.supplierRiskBarChartOptions.series = [];
      this.riskScoreChartLoading = true;
      let formData = {
        companyId: this.companyId,
        formId,
        count_type: this.form.countType.value,
        campare_date: this.compareData ? this.compareData : []
      };
      try {
        this.riskScoreChartLoading = true;
        let result = await HTTP(
          "post",
          "chart/getddqriskscorestatcompare",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data.status == 1 && result.status == 200) {
          const self = this;

          this.avgSupplierRiskDistributionScorePercent = result.data.avg_per;
          this.supplierRiskBarChartOptions = {
            chart: {
              type: "bar",
              zooming: {
                mouseWheel: {
                  enabled: false
                }
              }
            },
            title: {
              text: ""
            },
            colors: ["#3ddc97", "#F7A35C", "#e71d36", "#eeeeee"],
            subtitle: {
              text: ""
            },
            xAxis: {
              categories: result.data.date,
              title: {
                text: ""
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: "",
                align: "high"
              },
              labels: {
                overflow: "justify"
              }
            },
            tooltip: {
              valueSuffix: "%"
            },
            plotOptions: {
              groupPadding: 0.2,
              pointWidth: 14,
              pointPadding: 0.2,
              bar: {
                dataLabels: {
                  enabled: true,
                  formatter() {
                    return `${this.y}%`;
                  }
                }
              },
              series: {
                pointWidth: 10,
                groupPadding: 0.2,
                pointPadding: 0.01,
                cursor: "pointer",
                point: {
                  events: {
                    click() {
                      const userOptionName = result.data.response.find(
                        (x) => x.name === this.series.userOptions.name
                      );
                      const thirdParties = userOptionName.thirdParties[this.x];

                      self.mapDrillDownOptions.tableTitle =
                        this.series.userOptions.name;
                      self.mapDrillDownOptions.modalTitle =
                        "Third Party Risk Distribution By End State %";
                      self.mapDrillDownOptions.querySpecificData = thirdParties;
                      self.mapDrillDownOptions.tableId =
                        "supplierRiskDistribution";
                    }
                  }
                }
              }
            },

            credits: {
              enabled: false
            },
            series: result.data.response
          };
          this.riskScoreChartLoading = false;
          this.supplierRiskThirdParties = result.data.thirdparties;
        }
      } catch (err) {
        this.riskScoreChartLoading = false;
        return [err];
      }
    },
    async getRiskScoreChartData() {
      this.riskScoreData = [];
      this.riskScoreChartLoading = true;
      let formData = {
        companyId: this.companyId,
        formId: this.questionareFormId,
        count_type: this.form.countType.value,
        campare_date: this.compareData
      };
      try {
        this.riskScoreChartLoading = true;
        let result = await HTTP(
          "post",
          "chart/getddqaverageriskscorecompare",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data.status == 1) {
          this.avgRiskScorePercent = result.data.avg_per;
          this.riskScoreData = result.data.response;
          this.riskOpt = [];
          this.riskScoreData.map((item, index) => {
            this.riskOpt.push({
              chart: {
                type: "solidgauge",
                backgroundColor: null,
                height: 300,
                zooming: {
                  mouseWheel: {
                    enabled: false
                  }
                }
              },
              credits: {
                enabled: false
              },
              exporting: {
                enabled: false
              },
              title: {
                text: `Avg. Third Party Risk Score Period ${index + 1}`,
                style: {
                  fontSize: "17px"
                }
              },
              tooltip: {
                enabled: false
              },
              pane: {
                center: ["50%", "50%"],
                startAngle: -90,
                endAngle: 90,
                background: {
                  backgroundColor: "#EEE",
                  innerRadius: "60%",
                  outerRadius: "100%",
                  shape: "arc"
                }
              },
              yAxis: {
                min: 0,
                max: 10,
                stops: [
                  [
                    10,
                    item >= 8
                      ? EB2ColorPallete.EB2ColorPallete.chatsRedColor
                      : item >= 5 && item <= 7
                      ? EB2ColorPallete.EB2ColorPallete.chatsAmberColor
                      : EB2ColorPallete.EB2ColorPallete.chatsGreenColor
                  ]
                ],
                labels: {
                  enabled: false,
                  y: 16
                },
                lineWidth: 0,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                  y: -70
                }
              },
              plotOptions: {
                solidgauge: {
                  dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                  }
                }
              },
              series: [
                {
                  name: "Risk Score",
                  data: [item],
                  dataLabels: {
                    format: `${'<div style="text-align:center"><span style="font-size:25px">'}${item.toFixed(
                      0
                    )}</span><br/></div>`
                  }
                }
              ]
            });
          });
          this.riskScoreChartLoading = false;
        } else {
          this.riskOpt.push({
            chart: {
              type: "solidgauge",
              backgroundColor: null,
              height: 300,
              zooming: {
                mouseWheel: {
                  enabled: false
                }
              }
            },
            credits: {
              enabled: false
            },
            exporting: {
              enabled: false
            },
            title: {
              text: "Avg. Third Party Risk Score",
              style: {
                fontSize: "17px"
              }
            },
            tooltip: {
              enabled: false
            },
            pane: {
              center: ["50%", "50%"],
              startAngle: -90,
              endAngle: 90,
              background: {
                backgroundColor: "#EEE",
                innerRadius: "60%",
                outerRadius: "100%",
                shape: "arc"
              }
            },
            yAxis: {
              min: 0,
              max: 10,
              stops: [],
              labels: {
                enabled: false,
                y: 16
              },
              lineWidth: 0,
              tickWidth: 0,
              minorTickInterval: null,
              tickAmount: 2,
              title: {
                y: -70
              }
            },
            plotOptions: {
              solidgauge: {
                dataLabels: {
                  y: 5,
                  borderWidth: 0,
                  useHTML: true
                }
              }
            },
            series: []
          });
          this.riskScoreChartLoading = false;
        }
        if (this.form.dateSentFromCompare && this.form.dateSentToCompare) {
          // this.advanceFilterForms = false
          this.isComparativeFilterApply = true;
        }
      } catch (err) {
        this.riskScoreChartLoading = false;
        return [err];
      }
    },
    async worldMapChartsData(formId) {
      this.mapChartOptions.series = [];
      this.riskScoreChartLoading = true;
      let formData = {
        companyId: this.companyId,
        formId,
        dateSentFrom: this.form.dateSentFrom,
        dateSentTo: this.form.dateSentTo,
        count_type: this.form.countType.value
      };
      try {
        this.riskScoreChartLoading = true;
        let result = await HTTP(
          "post",
          "chart/getddqqcoutrystat",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data.status == 1 && result.status == 200) {
          this.mapChartOptions.series.push(
            {
              name: "Countries",
              color: "#E0E0E0",
              enableMouseTracking: false
            },
            {
              type: "mapbubble",
              name: "Third Parties",
              point: {
                events: {
                  click() {
                    EventBus.emit(
                      "onShowDDqMapTable",
                      this.country,
                      this.thirdParties,
                      "Third Party Business Operations"
                    );
                  }
                }
              },
              color: "#6BDC99",
              joinBy: ["iso-a2", "code"],
              data: result.data.data,
              minSize: 4,
              maxSize: "12%",
              tooltip: {
                pointFormat: "{point.country}: {point.z}"
              }
            }
          );
          this.riskScoreChartLoading = false;
        } else {
          this.mapChartOptions.series.push(
            {
              name: "Countries",
              color: "#E0E0E0",
              enableMouseTracking: false
            },
            {
              type: "mapbubble",
              name: "Third Parties",
              color: "#6BDC99",
              joinBy: ["iso-a2", "code"],
              data: [],
              minSize: 4,
              maxSize: "12%"
            }
          );
          this.riskScoreChartLoading = false;
          this.mapCharts = true;
        }
      } catch (err) {
        this.riskScoreChartLoading = false;
        return [err];
      }
    },
    async getDdqstat() {
      this.isLoading = true;
      this.overallValues = [];
      if (
        this.form.dateSentFrom &&
        this.form.dateSentTo &&
        this.form.dateSentFromCompare &&
        this.form.dateSentToCompare
      ) {
        this.compareData = [
          {
            dateSentFrom: this.form.dateSentFrom ? this.form.dateSentFrom : [],
            dateSentTo: this.form.dateSentTo ? this.form.dateSentTo : []
          },
          {
            dateSentFrom: this.form.dateSentFromCompare
              ? this.form.dateSentFromCompare
              : [],
            dateSentTo: this.form.dateSentToCompare
              ? this.form.dateSentToCompare
              : []
          }
        ];
      } else if (this.form.dateSentFrom && this.form.dateSentTo) {
        this.compareData = [
          {
            dateSentFrom: this.form.dateSentFrom ? this.form.dateSentFrom : [],
            dateSentTo: this.form.dateSentTo ? this.form.dateSentTo : []
          }
        ];
      } else {
        this.compareData = [];
      }
      let formData = {
        companyId: this.companyId,
        type: this.selectedDDq.type,
        formId: this.questionareFormId,
        count_type: this.form.countType.value,
        campare_date: this.compareData
      };
      try {
        let result = await HTTP(
          "post",
          "chart/getddqstatcompare",
          formData,
          makeAuthorizationHeader()
        );
        this.getRiskScoreChartData();
        this.getSupplierRiskBarChartData(this.questionareFormId);
        if (result?.data?.data) {
          const self = this;
          this.overallValues = result?.data?.data;
          this.avgDdqStatPercent = result.data.avg_per;
          this.ddqStatBarChartOptions = {
            chart: {
              type: "bar",
              zooming: {
                mouseWheel: {
                  enabled: false
                }
              }
            },
            title: {
              text: ""
            },
            subtitle: {
              text: ""
            },
            colors: [
              "#b4c7e7",
              "#4d9de0",
              "#f2f2f2",
              "#363537",
              "#515254",
              "#a3a3ac"
            ],
            xAxis: {
              categories: result.data.date,
              title: {
                text: ""
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: "",
                align: "high"
              },
              labels: {
                overflow: "justify"
              }
            },
            tooltip: {
              valueSuffix: "%"
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                  formatter() {
                    return `${this.y}%`;
                  }
                }
              },
              series: {
                pointWidth: 10,
                groupPadding: 0.2,
                pointPadding: 0.01,
                cursor: "pointer",
                point: {
                  events: {
                    click() {
                      const userOptionName = result.data.chart_data.find(
                        (x) => x.name === this.series.userOptions.name
                      );
                      const thirdParties = userOptionName.thirdParties[this.x];

                      self.mapDrillDownOptions.tableTitle =
                        this.series.userOptions.name;
                      self.mapDrillDownOptions.modalTitle =
                        "Third Parties By End State";
                      self.mapDrillDownOptions.querySpecificData = {
                        thirdParties
                      };
                      self.mapDrillDownOptions.tableId =
                        queryObjectKeys.THIRD_PARTY_DDQ_QUESTIONS;
                    }
                  }
                }
              }
            },

            credits: {
              enabled: false
            },
            series: result.data.chart_data
          };
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },
    async getAllForms() {
      this.riskScoreChartLoading = true;
      try {
        let result = await HTTP(
          "post",
          "chart/getallforms",
          {
            companyId: this.companyId
          },
          makeAuthorizationHeader()
        );
        if (result.data.data) {
          this.formsOptions = result.data.data;
          this.selectedDDq = this.formsOptions[0];
          this.selectedDDqTitle = this.selectedDDq.title;
          this.getDdqstat();
          this.getSingleDdq();
          this.riskScoreChartLoading = false;
        }
      } catch (err) {
        this.riskScoreChartLoading = false;
        return [err];
      }
    },

    onSelectDDq(val) {
      this.selectedDDq = val;
      this.getDdqstat();
      this.getSingleDdq();
    },

    async getSingleDdq() {
      if (
        this.form.dateSentFrom &&
        this.form.dateSentTo &&
        this.form.dateSentFromCompare &&
        this.form.dateSentToCompare
      ) {
        this.compareData = [
          {
            dateSentFrom: this.form.dateSentFrom ? this.form.dateSentFrom : [],
            dateSentTo: this.form.dateSentTo ? this.form.dateSentTo : []
          },
          {
            dateSentFrom: this.form.dateSentFromCompare
              ? this.form.dateSentFromCompare
              : [],
            dateSentTo: this.form.dateSentToCompare
              ? this.form.dateSentToCompare
              : []
          }
        ];
      } else if (this.form.dateSentFrom && this.form.dateSentTo) {
        this.compareData = [
          {
            dateSentFrom: this.form.dateSentFrom ? this.form.dateSentFrom : [],
            dateSentTo: this.form.dateSentTo ? this.form.dateSentTo : []
          }
        ];
      } else {
        this.compareData = [];
      }
      this.chartConfig = {
        totalSections: []
      };
      let formData = {
        companyId: this.companyId,
        type: this.selectedDDq.type,
        formId: this.questionareFormId,
        count_type: this.form.countType.value,
        campare_date: this.compareData
      };
      try {
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "chart/getddqquestionstatcompare",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data.response.sections) {
          this.questionareTitles = result.data.form_title;
          this.ddqQuestionChartDates = result.data.date;
          this.ddqQuestionStatAvg = result.data.avg_per;
          result.data.response.sections.map((section) => {
            section.map((sectionData) => {
              let dd = {
                title: sectionData.sectionTitle,
                section: [sectionData]
              };
              this.chartConfig.totalSections.push(dd);
            });
          });
          this.isLoading = false;
          this.newDataSet();
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },

    newDataSet() {
      this.chartConfig.totalSections.map((ss) => {
        ss.section.map((q) => {
          const colors = [];
          q.data.map((d) => {
            colors.push(
              d.custom_values
                ? d.custom_values.color
                  ? d.custom_values.color
                  : "#3DDc97"
                : "#3DDc97"
            );
          });
          const data = {
            shortTitle: q.questionTableAbbreviation,
            sectionTitle: q.sectionTitle,
            questionTitle: q.questionTitle,
            chart: {
              type: "bar",
              zooming: {
                mouseWheel: {
                  enabled: false
                }
              }
            },
            title: {
              text: ""
            },
            colors,
            credits: {
              enabled: false
            },
            xAxis: {
              categories: this.ddqQuestionChartDates,
              title: {
                text: ""
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: "",
                align: "high"
              },
              labels: {
                overflow: "justify"
              }
            },
            tooltip: {
              valueSuffix: "%"
            },
            plotOptions: {
              bar: {
                groupPadding: 0.2,
                pointWidth: 14,
                pointPadding: 0.2,
                dataLabels: {
                  enabled: true,
                  formatter() {
                    return `${this.y}%`;
                  }
                }
              },
              series: {
                pointWidth: 10,
                groupPadding: 0.2,
                pointPadding: 0.01,
                cursor: "pointer",
                point: {
                  click() {
                    const userOptionsName = q.data.find(
                      (x) => x.name === this.series.userOptions.name
                    );
                    const thirdParties = userOptionsName.thirdParties[this.x];

                    self.mapDrillDownOptions.tableTitle =
                      this.series.userOptions.name;
                    self.mapDrillDownOptions.modalTitle =
                      q.questionTableAbbreviation;
                    self.mapDrillDownOptions.querySpecificData = {
                      thirdParties
                    };
                    self.mapDrillDownOptions.tableId =
                      queryObjectKeys.THIRD_PARTY_DDQ_QUESTIONS;
                  }
                }
              }
            },
            series: q.data
          };
          Object.assign(
            q,
            { series: data },
            { avgPer: this.ddqQuestionStatAvg }
          );
        });
      });
    },

    onSelectLayout(e) {
      this.selectedLayout = e;
      this.toggleRender();
    },

    onSelectChart(type, sIndex, index) {
      const section =
        this.chartConfig.totalSections[sIndex].section[index].data;
      this.selectedChartType = type;
      section[section.length - 1].chart.type = type.toLowerCase();

      this.toggleRender();
    },

    toggleRender() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
    }
  },
  beforeRouteUpdate(_, __, next) {
    localStorage.removeItem(localStorageItem.COMPARE_ANALYTICS);
    next();
  },
  beforeRouteLeave(_, __, next) {
    localStorage.removeItem(localStorageItem.COMPARE_ANALYTICS);
    next();
  }
};
</script>
<style lang="scss">
.select-custom .vs__dropdown-toggle {
  height: calc(1.5em + 0.5rem) !important;
}

.nav-tabs .nav-link {
  font-weight: bold;
  color: $black;
  border-bottom: 3px solid $white !important;
}

.nav-tabs li.nav-item {
  margin-right: 25px;
}

.statsCard .card-title {
  font-size: 17px !important;
}

.font-size-text {
  font-size: 17px !important;
}

.highcharts-title {
  font-family: system-ui;
  font-weight: 500;
}
</style>
