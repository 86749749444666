import { isRequired, hasCorrectEmailFormat } from "@/utils";
import { customErrorMessages } from "@/constants";

const validateName = (name) => {
  if (!isRequired(name)) {
    return customErrorMessages.generic.required;
  }
  return "";
};

const validateEmail = (email) => {
  if (!isRequired(email)) {
    return customErrorMessages.generic.required;
  } else if (!hasCorrectEmailFormat(email)) {
    return customErrorMessages.login.forgotPasswordEmail.isValidEmail;
  }
  return "";
};

const validateLanguage = (language) => {
  if (!isRequired(language)) {
    return customErrorMessages.generic.required;
  }
  return "";
};

export { validateName, validateEmail, validateLanguage };
