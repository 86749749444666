<template>
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />

    <!-- input content -->

    <b-collapse class="card mt-5" id="collapse-1" v-model="collapse">
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1'"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <div class="mt-3" :id="fieldData.id">
        <h4 class="d-inline-flex">
          {{
            setEnumeratedFieldNumber({
              enableSectionFieldEnumeration:
                form1.enableSectionFieldEnumeration,
              sectionIndex,
              fieldIndex,
              fieldData,
              selectedLanguage
            })
          }}
        </h4>
        <p>{{ fieldData.description[selectedLanguage] }}</p>

        <Checkbox
          v-show="
            !fieldData.isNaFeature || parseInt(fieldData.isNaFeature) === 0
          "
          v-model="fieldData.isNa"
          @update:modelValue="onToggleNa"
          checked-value="1"
          unchecked-value="0"
          :label="$t('na')"
        />
      </div>
      <b-modal
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        v-model="showAddRowModal"
        :title="
          isUpdtating
            ? $t('workflow.update') + ' ' + fieldData.label[selectedLanguage]
            : $t('workflow.add') + ' ' + fieldData.label[selectedLanguage]
        "
        size="xl"
      >
        <div
          :class="
            $store.state.rightAlign
              ? 'card-body text-right'
              : 'card-body text-left'
          "
        >
          <div class="form-row">
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance || !fieldData.advance.entityNameDisabled
              "
            >
              <b-form-group
                :description="fieldData.entityNameHelperText[selectedLanguage]"
                :invalid-feedback="
                  fieldData.entityNameValidationMessage[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(fieldData.validation.isRequireEntityName)
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.entityNameLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  :disabled="isDisabled == 1"
                  v-model="v$.form.entityName.$model"
                  :placeholder="
                    fieldData.entityNamePlaceholderText[selectedLanguage]
                  "
                  :state="
                    v$.form && v$.form.entityName.$dirty
                      ? !v$.form.entityName.$error
                      : null
                  "
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance ||
                !fieldData.advance.dateOfRegistrationDisabled
              "
            >
              <b-form-group
                :description="
                  fieldData.dateOfRegistrationHelperText[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(
                        fieldData.validation.isRequireDateOfRegistration
                      )
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.dateOfRegistrationLabel[selectedLanguage] }}
                </label>
                <b-input-group
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                >
                  <Datepicker
                    :disabled="isDisabled == 1"
                    input-class="custom-date-picker"
                    calendar-class="date-calender"
                    class="form-control"
                    :class="
                      v$.form.dateOfRegistration.$error
                        ? 'border border-danger rounded'
                        : null
                    "
                    v-model="v$.form.dateOfRegistration.$model"
                    :format="customDateFormat"
                    :disabled-dates="disabledDates"
                    :state="
                      v$.form && v$.form.dateOfRegistration.$dirty
                        ? !v$.form.dateOfRegistration.$error
                        : null
                    "
                  ></Datepicker>
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </b-input-group>
                <label
                  v-if="
                    v$.form.dateOfRegistration.$error &&
                    fieldData.validation.isRequireDateOfRegistration &&
                    !form.dateOfRegistration
                  "
                  class="text-danger"
                  style="font-size: 80%"
                  >{{
                    fieldData.dateOfRegistrationValidationMessage[
                      selectedLanguage
                    ]
                  }}</label
                >
              </b-form-group>
            </div>
          </div>

          <div class="form-row">
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance ||
                !fieldData.advance.countryOfRegistrationDisabled
              "
            >
              <b-form-group
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :description="
                  fieldData.countryOfRegistrationHelperText[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(
                        fieldData.validation.isRequireCountryOfRegistration
                      )
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.countryOfRegistrationLabel[selectedLanguage] }}
                </label>
                <v-select
                  :disabled="isDisabled == 1"
                  :searchable="true"
                  :clearable="true"
                  label="name"
                  :class="
                    v$.form.countryOfRegistration.$error
                      ? 'border border-danger rounded'
                      : null
                  "
                  v-model="v$.form.countryOfRegistration.$model"
                  :state="
                    v$.form && v$.form.countryOfRegistration.$dirty
                      ? !v$.form.countryOfRegistration.$error
                      : null
                  "
                  :options="optionsCountries"
                  @update:modelValue="onSelected"
                >
                  <template slot="option" slot-scope="option">
                    <country-flag
                      v-if="option.icon"
                      :country="option.icon"
                      size="small"
                    />
                    <span>{{ "&nbsp;" + option.name }}</span>
                  </template>
                </v-select>
                <label
                  v-if="
                    v$.form.countryOfRegistration.$error &&
                    fieldData.validation.isRequireCountryOfRegistration &&
                    !form.countryOfRegistration
                  "
                  class="text-danger"
                  style="font-size: 80%"
                  >{{
                    fieldData.countryOfRegistrationValidationMessage[
                      selectedLanguage
                    ]
                  }}</label
                >
              </b-form-group>
            </div>

            <div
              class="col-md-6"
              v-show="
                !fieldData.advance || !fieldData.advance.percentageOwnedDisabled
              "
            >
              <b-form-group
                :description="
                  fieldData.percentageOwnedHelperText[selectedLanguage]
                "
                :invalid-feedback="
                  fieldData.percentageOwnedValidationMessage[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(fieldData.validation.isRequirePercentageOwned)
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.percentageOwnedLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  onkeydown="return event.keyCode !== 69"
                  :disabled="isDisabled == 1"
                  min="1"
                  max="100"
                  v-model="v$.form.percentageOwned.$model"
                  :placeholder="
                    fieldData.percentageOwnedPlaceholderText[selectedLanguage]
                  "
                  :state="
                    v$.form && v$.form.percentageOwned.$dirty
                      ? !v$.form.percentageOwned.$error
                      : null
                  "
                  type="number"
                  onpaste="return false;"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="form-row">
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance ||
                !fieldData.advance.registrationNumberDisabled
              "
            >
              <b-form-group
                :description="
                  fieldData.registrationNumberHelperText[selectedLanguage]
                "
                :invalid-feedback="
                  fieldData.registrationNumberValidationMessage[
                    selectedLanguage
                  ]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(
                        fieldData.validation.isRequireRegistrationNumber
                      )
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.registrationNumberLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  :disabled="isDisabled == 1"
                  v-model="v$.form.registrationNumber.$model"
                  :placeholder="
                    fieldData.registrationNumberPlaceholderText[
                      selectedLanguage
                    ]
                  "
                  :state="
                    v$.form && v$.form.registrationNumber.$dirty
                      ? !v$.form.registrationNumber.$error
                      : null
                  "
                  type="text"
                  onpaste="return false;"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="col-md-6"
              v-show="!fieldData.advance || !fieldData.advance.freeFormDisabled"
            >
              <div v-if="fieldData.freeFormLabel">
                <b-form-group
                  :description="fieldData.freeFormHelperText[selectedLanguage]"
                  :invalid-feedback="
                    fieldData.freeFormValidationMessage[selectedLanguage]
                  "
                >
                  <label>
                    <span
                      v-if="
                        fieldData.validation &&
                        JSON.parse(fieldData.validation.isRequireFreeForm)
                      "
                      class="required-span"
                      >*</span
                    >
                    {{ fieldData.freeFormLabel[selectedLanguage] }}
                  </label>
                  <b-form-input
                    :class="
                      $store.state.rightAlign ? 'text-right' : 'text-left'
                    "
                    :disabled="isDisabled == 1"
                    v-model="v$.form.freeForm.$model"
                    :placeholder="
                      fieldData.freeFormPlaceholderText[selectedLanguage]
                    "
                    :state="
                      v$.form && v$.form.freeForm.$dirty
                        ? !v$.form.freeForm.$error
                        : null
                    "
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <template v-slot:modal-footer>
          <b-button variant="danger" @click="resetModal">{{
            $t("closeBtnText")
          }}</b-button>
          <b-button
            variant="success"
            @click="isUpdtating ? updateRow() : addRow()"
            >{{ isUpdtating ? $t("updateBtn") : $t("saveBtnText") }}</b-button
          >
        </template>
      </b-modal>

      <b-row v-if="taskStatus != 4">
        <b-col cols="12">
          <b-spinner v-if="isToggle" />
          <b-button
            v-else
            :disabled="isDisabled == 1 || parseInt(fieldData.isNa) === 1"
            class="mr-2 mt-2"
            variant="outline-success"
            size="lg"
            @click="showAddRowModal = !showAddRowModal"
          >
            <font-awesome-icon icon="plus" />
            {{ $t("workflow.add") }}
          </b-button>
        </b-col>
        <b-col cols="12">
          <label v-if="isValidationRequired(fieldData)" class="text-danger">{{
            $t("createProfile.requiredValidationMessage")
          }}</label>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="12" class="p-5">
          <b-table-simple striped hover>
            <b-thead>
              <b-tr>
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.entityNameDisabled
                  "
                  >{{ fieldData.entityNameLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.dateOfRegistrationDisabled
                  "
                  >{{
                    fieldData.dateOfRegistrationLabel[selectedLanguage]
                  }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.percentageOwnedDisabled
                  "
                  >{{ fieldData.percentageOwnedLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.countryOfRegistrationDisabled
                  "
                  >{{
                    fieldData.countryOfRegistrationLabel[selectedLanguage]
                  }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.registrationNumberDisabled
                  "
                  >{{
                    fieldData.registrationNumberLabel[selectedLanguage]
                  }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.freeFormDisabled
                  "
                  >{{
                    fieldData.freeFormLabel
                      ? fieldData.freeFormLabel[selectedLanguage]
                      : ""
                  }}</b-th
                >
                <b-th>{{ $t("upload.fileAction") }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(item, index) in items" :key="index">
              <b-tr>
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.entityNameDisabled
                  "
                  >{{ item.entity_name || "" }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.dateOfRegistrationDisabled
                  "
                  >{{ format_date(item.date_of_registration) }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.percentageOwnedDisabled
                  "
                  >{{ item.percentage_owned || "" }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.countryOfRegistrationDisabled
                  "
                  >{{
                    (item.counrty_registration &&
                      item.counrty_registration.name) ||
                    ""
                  }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.registrationNumberDisabled
                  "
                  >{{ item.registration_number }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.freeFormDisabled
                  "
                  >{{ item.free_form || "" }}</b-td
                >
                <b-td>
                  <b-button
                    @click="onEditRow(item, index)"
                    size="sm"
                    class="mr-1"
                  >
                    <font-awesome-icon
                      :icon="taskStatus != 4 ? 'edit' : 'eye'"
                    />
                  </b-button>
                  <b-button
                    @click="onDeleteRow(index)"
                    variant="danger"
                    size="sm"
                    v-if="taskStatus != 4"
                  >
                    <font-awesome-icon icon="trash" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <!-- show advance field options -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        size="xl"
        v-model="showSettings"
        scrollable
        title="Advanced Settings"
      >
        <AdvanceSettings
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import MenuButtons from "@/components/menuButtons";
import AdvanceSettings from "@/components/workflowSettings/advanceCorporateShareHoldersSettings";
import { Helper } from "@/helpers";
import vSelect from "vue-select";
import { useVuelidate } from "@vuelidate/core";
import { EventBus } from "@/event-bus.js";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import moment from "moment";
import "moment/locale/es";
import Datepicker from "vuejs-datepicker";
import CountryCodes from "@/utils/countryCodesRiskScore.json";
import { routeKeys, routeNames } from "@/constants";
import { clone } from "lodash";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "CorporateShareholdersGroup",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    MenuButtons,
    vSelect,
    AdvanceSettings,
    Datepicker,
    Checkbox
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    optionsCountries() {
      return CountryCodes[this.fieldData.selectedListTypeIndex?.value || 0]
        .countries;
    }
  },
  data() {
    return {
      showFreeForm: false,
      showAddRowModal: false,
      items: [],
      isHidden: false,
      condition: {},
      title: null,
      postData: [],
      taskStatus: 0,
      form: {
        entityName: null,
        percentageOwned: null,
        registrationNumber: null,
        dateOfRegistration: null,
        countryOfRegistration: null,
        freeForm: null
      },
      label: null,
      showSettings: false,
      collapse: true,
      isUpdtating: false,
      selectedRowIndex: null,
      disabledDates: {
        to:
          this.fieldData.advanceDate &&
          this.fieldData.advanceDate.disabledMinDateInput
            ? new Date(Date.now() - 864e5)
            : this.fieldData.advanceDate && this.fieldData.advanceDate.minDate
            ? new Date(this.fieldData.advanceDate.minDate)
            : "", // Disable all dates up to specific date
        from:
          this.fieldData.advanceDate &&
          this.fieldData.advanceDate.disabledMaxDateInput
            ? new Date()
            : this.fieldData.advanceDate && this.fieldData.advanceDate.maxDate
            ? new Date(this.fieldData.advanceDate.maxDate)
            : "" // Disable all dates after specific date
      },
      isToggle: false
    };
  },

  watch: {
    selectedLanguage(newLanguage) {
      this.updateLabels(newLanguage);
    }
  },

  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (
        value === "progress" ||
        parseInt(this.fieldData.isDisabled) === 1 ||
        parseInt(this.fieldData.isNa) === 1 ||
        this.fieldData.isHidden
      ) {
        this.setDataToUser();
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        let validations = Object.values(this.fieldData.validation);
        const found = validations.find((value) => value == true);
        if (
          (this.items && this.items.length > 0) ||
          this.fieldData.isHidden ||
          found == undefined
        ) {
          this.setDataToUser();
          this.$store.state.validFields = true;
          this.fieldData.isInValid = false;
        } else {
          this.$store.state.validFields = false;
          this.fieldData.isInValid = true;
        }
      }
    });

    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },

  mounted() {
    this.setFreeFormFields();
    this.setNewConditions();
    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name === routeNames[routeKeys.USER_FORM]
    ) {
      this.getDataForUser();
    }
    let st = parseInt(localStorage.getItem("activeTaskStatus"));
    this.taskStatus = st;
  },

  validations() {
    return {
      form: {
        entityName: {
          [this.entityName]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireEntityName
              ? required
              : !required
        },
        percentageOwned: {
          [this.percentageOwned]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequirePercentageOwned
              ? required
              : !required
        },
        registrationNumber: {
          [this.registrationNumber]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRegistrationNumber
              ? required
              : !required
        },
        dateOfRegistration: {
          [this.dateOfRegistration]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireDateOfRegistration
              ? required
              : !required
        },

        countryOfRegistration: {
          [this.countryOfRegistration]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireCountryOfRegistration
              ? required
              : !required
        },

        freeForm: {
          [this.freeForm]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireFreeForm
              ? required
              : !required
        }
      }
    };
  },

  methods: {
    setEnumeratedFieldNumber({
      enableSectionFieldEnumeration,
      sectionIndex,
      fieldIndex,
      fieldData,
      selectedLanguage
    }) {
      if (parseInt(enableSectionFieldEnumeration) === 1) {
        return `${sectionIndex + 1}.${fieldIndex + 1} ${
          fieldData.label[selectedLanguage]
        }`;
      }
      return fieldData.label[selectedLanguage];
    },
    isValidationRequired(fieldData) {
      let condition = Object.values(fieldData.validation);
      return !!condition.includes(true) && fieldData.isInValid;
    },
    setFreeFormFields() {
      if (this.fieldData.freeFormLabel == undefined) {
        Object.assign(this.fieldData, {
          freeFormLabel: {
            english: "Free Form"
          },
          freeFormPlaceholderText: {
            english: ""
          },
          freeFormHelperText: {
            english: ""
          },
          freeFormValidationMessage: {
            english: "This field is required"
          }
        });
        let validation = {
          ...this.fieldData.validation,
          isRequireFreeForm: false
          // this.fieldData.validation.isRequireFreeForm || false
        };
        Object.assign(this.fieldData, { validation });

        let advance = {
          ...this.fieldData.advance,
          freeFormDisabled: true
          // this.fieldData.advance.freeFormDisabled || false
        };
        Object.assign(this.fieldData, { advance });
      }
    },

    setNewConditions() {
      Object.assign(this.fieldData, {
        isInValid: this.fieldData.isInValid || null
      });
      Object.assign(this.fieldData, {
        isHidden: this.fieldData.isHidden || false
      });
      Object.assign(this.fieldData, {
        isConditional: this.fieldData.isConditional || 0
      });
      Object.assign(this.fieldData, {
        conditions:
          this.fieldData.conditions && this.fieldData.conditions.length
            ? this.fieldData.conditions
            : []
      });
      Object.assign(this.fieldData, {
        conditionalFields:
          this.fieldData.conditionalFields &&
          this.fieldData.conditionalFields.length
            ? this.fieldData.conditionalFields
            : []
      });
      Object.assign(this.fieldData, {
        sectionConditions:
          this.fieldData.sectionConditions &&
          this.fieldData.sectionConditions.length
            ? this.fieldData.sectionConditions
            : []
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },

    customDateFormat(value) {
      let customFormat = this.fieldData.format;
      if (customFormat == "default") {
        return moment(value).format("DD-MM-YYYY");
      } else {
        return moment(value).locale("en").format(customFormat);
      }
    },

    onSelected(value) {
      this.countryOfRegistration = value.name;
    },

    onToggleNa(e) {
      this.isToggle = true;
      this.fieldData.isNa = e;
      this.isToggle = false;
      if (parseInt(e) === 1) {
        this.items = [];
        this.postData = [];
      }
    },

    addRow() {
      const registerData = clone(this.$store.state.registerData);
      this.v$.form?.$touch();
      if (this.v$.form.$errors?.length && this.v$.form.$invalid) {
        return false;
      }
      registerData[this.fieldData.id] = "corporate-shareholders";
      this.$store.state.registerData = registerData;

      // main calculations :p
      const numkeys = Object.keys(registerData).length;
      this.$store.state.progressValue = numkeys;

      let row = {
        entity_name: this.form.entityName,
        date_of_registration: this.form.dateOfRegistration,
        percentage_owned: this.form.percentageOwned,
        counrty_registration: this.form.countryOfRegistration,
        registration_number: this.form.registrationNumber,
        free_form: this.form.freeForm,

        actions: "&nbsp;"
      };
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name === routeNames[routeKeys.USER_FORM]
      ) {
        let aa = [
          {
            postValue: row.entity_name,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.entityNamePostName) ||
              "entityNamePost",
            key: "entity_name",
            labelKey: "entityNameLabel",
            label: this.fieldData.entityNameLabel[this.selectedLanguage]
          },
          {
            postValue: row.date_of_registration,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.dateOfRegistrationPostName) ||
              "dateOfRegistration",
            key: "date_of_registration",
            labelKey: "dateOfRegistrationLabel",
            label: this.fieldData.dateOfRegistrationLabel[this.selectedLanguage]
          },
          {
            postValue: row.percentage_owned,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.percentageOwnedPostName) ||
              "percentageOwnedPostName",
            key: "percentage_owned",
            labelKey: "percentageOwnedLabel",
            label: this.fieldData.percentageOwnedLabel[this.selectedLanguage]
          },
          {
            postValue: row.counrty_registration,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.countryOfRegistrationPostName) ||
              "counrtyRegistrationPostName",
            key: "counrty_registration",
            labelKey: "countryOfRegistrationLabel",
            label:
              this.fieldData.countryOfRegistrationLabel[this.selectedLanguage]
          },
          {
            postValue: row.registration_number,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.registrationNumberPostName) ||
              "registrationNumberPostName",
            key: "registration_number",
            labelKey: "registrationNumberLabel",
            label: this.fieldData.registrationNumberLabel[this.selectedLanguage]
          },
          {
            postValue: row.free_form,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.freeFormPostName) ||
              "freeFormPostName",
            key: "free_form",
            labelKey: "freeFormLabel",
            label: this.fieldData.freeFormLabel[this.selectedLanguage]
          }
        ];
        this.postData.push(aa);
        this.items.push(row);
      }

      this.resetModal();
    },

    updateLabels(newLanguage) {
      this.postData[0].forEach((data) => {
        data.label = this.fieldData?.[data.labelKey]?.[newLanguage];
      });
    },

    getDataForUser() {
      this.$store.state.getUserFormSubmitArray.map((data) => {
        if (data.elementId === this.fieldData.id) {
          this.fieldData.isNa = data.isWorkFlowNa;
          this.fieldData.isHidden = parseInt(data.isHiddenElement) === 1;
          if (data.elements && data.elements.length > 0) {
            data.elements.map((element) => {
              this.fieldData.isNa = data.isWorkFlowNa;
              let row = {
                entity_name:
                  element[element.findIndex((x) => x.key == "entity_name")]
                    .postValue,
                date_of_registration:
                  element[
                    element.findIndex((x) => x.key == "date_of_registration")
                  ].postValue,
                percentage_owned:
                  element[element.findIndex((x) => x.key == "percentage_owned")]
                    .postValue,
                counrty_registration:
                  element[
                    element.findIndex((x) => x.key == "counrty_registration")
                  ].postValue,
                registration_number:
                  element[
                    element.findIndex((x) => x.key == "registration_number")
                  ].postValue,
                free_form:
                  element[element.findIndex((x) => x.key == "free_form")]
                    .postValue,

                actions: "&nbsp;"
              };
              this.items.push(row);
              this.postData = data.elements;
            });
          }
        }
      });
    },

    setDataToUser() {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name === routeNames[routeKeys.USER_FORM]
      ) {
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.isWorkFlowNa = this.fieldData.isNa;
            a.isHiddenElement = this.fieldData.isHidden ? 1 : 0;
            a.elements = this.postData;
          }
        });
      }
    },

    resetModal() {
      (this.form.entityName = null),
        (this.form.dateOfRegistration = null),
        (this.form.percentageOwned = null),
        (this.form.countryOfRegistration = null),
        (this.form.registrationNumber = null),
        (this.form.freeForm = null);
      this.v$.$reset();
      this.showAddRowModal = false;
      this.isUpdtating = false;
    },

    updateRow() {
      this.v$.form?.$touch();
      if (this.v$.form.$errors?.length && this.v$.form.$invalid) {
        return false;
      }
      this.items[this.selectedRowIndex].entity_name = this.form.entityName;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "entity_name"
        )
      ].postValue = this.form.entityName;

      this.items[this.selectedRowIndex].date_of_registration =
        this.form.dateOfRegistration;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "date_of_registration"
        )
      ].postValue = this.form.dateOfRegistration;

      this.items[this.selectedRowIndex].percentage_owned =
        this.form.percentageOwned;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "percentage_owned"
        )
      ].postValue = this.form.percentageOwned;

      this.items[this.selectedRowIndex].counrty_registration =
        this.form.countryOfRegistration;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "counrty_registration"
        )
      ].postValue = this.form.countryOfRegistration;

      this.items[this.selectedRowIndex].registration_number =
        this.form.registrationNumber;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "registration_number"
        )
      ].postValue = this.form.registrationNumber;

      this.items[this.selectedRowIndex].free_form = this.form.freeForm;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "free_form"
        )
      ].postValue = this.form.freeForm;

      this.resetModal();
    },

    onDeleteRow(index) {
      const registerData = clone(this.$store.state.registerData);
      if (this.items.length == 1 && this.postData.length == 1) {
        delete registerData[this.fieldData.id];
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
        this.$store.state.progressValue = numkeys;
      }
      this.items.splice(index, 1);
      this.postData.splice(index, 1);
    },

    onEditRow(item, index) {
      this.isUpdtating = true;
      this.showAddRowModal = true;
      this.selectedRowIndex = index;
      this.form = {
        entityName: item.entity_name,
        dateOfRegistration: item.date_of_registration,
        percentageOwned: item.percentage_owned,
        countryOfRegistration: item.counrty_registration,
        registrationNumber: item.registration_number,
        freeForm: item.free_form
      };
    },

    setValidation() {
      let validation = {
        isRequireEntityName: true,
        isRequireDateOfRegistration: true,
        isRequirePercentageOwned: true,
        isRequireRegistrationNumber: true,
        isRequireCountryOfRegistration: true
      };
      Object.assign(this.fieldData, { validation });
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      this.onRerenerView();
      Helper.makeToast(this.$bvToast, "success", "Settings updated");
    },

    onRerenerView() {
      this.$store.state.isLoading = true;
      setTimeout(() => {
        this.$store.state.isLoading = false;
      }, 50);
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>

<style lang="scss">
.v-select-toggle[data-v-138dff1d] {
  border: 1px solid $light-silver;
  background-color: $white;
  height: 40px;
}

.v-select.disabled .v-select-toggle[data-v-138dff1d] {
  background-color: $bright-gray !important;
  border: 1px solid $light-gray !important;
  height: 40px;
}
.custom-date-picker {
  width: 100%;
  border: none;
}
.custom-date-picker:focus {
  border: none;
}
.date-calender {
  width: 350px !important;
}
</style>
