export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance panel"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domov"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítava sa..."])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predlohy"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dotazníky"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte dotazníky hĺbkovej previerky a priložte dokumentáciu"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať dotazník"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulár odoslaný"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdzujem vyššie uvedené"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť na panel"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehľad"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum pridelenia:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum dokončenia:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnuť"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOKONČENÉ"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PREBIEHA"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEZAČATÉ"])},
    "linkUpModalHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyžaduje sa dodatočná akcia"])},
    "linkUpModalBodyText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujeme, že ste vyplnili formulár žiadosti o povinnú starostlivosť tretej strany v službe Salesforce."])},
    "linkUpModalBodyText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na základe poskytnutých odpovedí vyžaduje Salesforce, aby vaša organizácia vyplnila dotazník Salesforce Third Party Due Diligence Questionnaire."])},
    "linkUpModalBodyText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte tento dotazník čo najskôr. Ak dotazník nevyplníte, môže to ovplyvniť žiadosť vašej organizácie o nadviazanie obchodného vzťahu so spoločnosťou Salesforce."])},
    "linkUpModalBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začať dotazník due diligence"])},
    "linkUpModalCancelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prejsť späť na dotazníky"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstrániť riadok"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť riadok"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ľutujeme, žiadne zodpovedajúce možnosti."])}
  },
  "training": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Školenia"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prečítajte si vzdelávacie materiály a pripravte sa na skúšky"])},
    "trainingTite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Training Courses"])},
    "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code of Conduct"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatvoriť"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontrolovať"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začať"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prebieha"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončiť"])},
    "notComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezačaté"])},
    "toFirstSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to first slide"])},
    "toDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť na panel"])},
    "slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing:"])},
    "nextSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Slide"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "startCertification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your certification"])},
    "trainingCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Completed"])},
    "proceedToExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning stage of the training complete. Proceed to examination."])},
    "fromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start From beginning"])},
    "continueTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Training"])},
    "ContinueText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to Continue from slide number"])},
    "ContinueText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or start from beginning"])},
    "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnuť súbor"])}
  },
  "certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifikácie"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spraviť skúšku a získať certifikát"])},
    "noOfQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Questions"])},
    "examTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Time: "])},
    "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mins"])},
    "maxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Attempts"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" attempt(s)"])},
    "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" remaining"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začať"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "timeLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostávajúci čas"])},
    "guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min."])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "summaryText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máte celkovo"])},
    "summaryText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pokusy/ pokusov na celú skúšku a jej otázky"])},
    "startNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZAČAŤ TERAZ"])},
    "doLate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urobím to neskôr"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátiť sa späť"])},
    "attemptsToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempts to date:"])},
    "remainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Attempts:"])},
    "maximumReach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum number of attempts. Please contact the compliance officer to discuss your options"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
    "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "backToQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátiť sa späť"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing:"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "submitAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Answers"])},
    "answerOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer Overview"])},
    "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewed"])},
    "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered"])},
    "notAnswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not answered"])},
    "timeLimitText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a"])},
    "timeLimitText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute timed exam"])},
    "examAboutToStartText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your exam is about to start in"])},
    "examAboutToStartText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "startImmediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Immediately"])},
    "startExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Exam"])},
    "aboutToSubmitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to submit your answers. This will count towards the maximum attempts. If you are happy with your answers then submit."])},
    "aboutToSubmitTextSimple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to submit your exam. This action cannot be undone. Are you sure you want to submit?"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
    "noteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can go back and review your answers before you submit."])},
    "submitExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Exam"])},
    "cancelExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Exam"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "noAnsGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadna poskytnutá odpoveď"])},
    "cancelExamText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to cancel without completing your exam?"])},
    "cancelExamText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelling the exam would count towards your attempts."])},
    "cancelExamText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have"])},
    "cancelExamText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attempt(s) remaining"])},
    "examSubmittedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Submitted"])},
    "closeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavrieť"])},
    "thankYouText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for Choosing Our Exam Portal, for further queries please contact our Admin."])},
    "examResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Result"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score:"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["out of"])},
    "yourResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Result:"])},
    "toPassExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Needed To Pass Exam:"])},
    "examStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Status:"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed"])},
    "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations you have passed!"])},
    "PlsTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again"])},
    "goToQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to question"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert!"])},
    "blankAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Cannot Submit Blank Answer/s."])},
    "englishOriginalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Original"])}
  },
  "annual-certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifikácie"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifikovať viacero certifikácií"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chystáte sa opustiť stránku. Všetky neuložené zmeny sa stratia. Naozaj chcete odísť?"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chystáte sa odoslať dotazník. Tento krok nie je možné vrátiť späť. Naozaj chcete dokončiť odoslanie?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš formulár bol úspešne odoslaný."])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Našlo sa jedno alebo viac chýbajúcich polí alebo chýb. Skontrolujte ich a v prípade potreby aktualizujte."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozornenie"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešné uloženie"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priebeh nebol uložený. Skúste to znova."])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešná aktualizácia"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generujeme váš súbor PDF..."])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sťahovanie súboru PDF úspešné"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súbor PDF nebol stiahnutý, skúste to znova"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešné odhlásenie"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončiť registráciu"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Aktualizovať profil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktné údaje"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovať heslo"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priezvisko"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktné číslo"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov pracovnej pozície"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súčasné heslo"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť heslo"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte svoje meno"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte svoje priezvisko "])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte svoje kontaktné číslo"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte názov pracovnej pozície alebo rolu"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte súčasné heslo"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte nové heslo"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte potvrdzujúce heslo"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslá musia byť rovnaké"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša žiadosť sa spracúva…"])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presunutím myšou nahrajte obsah! (drag & drop)"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...alebo kliknutím vyberte súbor z počítača"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov súboru"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ súboru"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veľkosť súboru (v bytoch)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyžaduje sa"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetovať"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovať"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstrániť"])},
    "deleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naozaj chcete odstrániť túto položku?"])},
    "deleteHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstrániť riadok"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
    "burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Taiwan"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
    "haitian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haitian Creole"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "khmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "lao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lao"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "portuguesebr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Brazilian"])},
    "portuguesemz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Mozambique"])},
    "portuguesepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Portugal"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "serbian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian"])},
    "sinhalese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
    "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "spanishmx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish Mexican"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatný formát"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail je neplatný"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatné súčasné heslo."])}
  },
  "privacyStatement": {
    "msgTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy at Novartis"])},
    "contactUsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The responsible use of personal data is a core value at Novartis. Novartis Group fully respects privacy laws and is subject to an internal framework of privacy rules and policies. The internal transfer of data is governed by Binding Corporate Rules, so called \"BCR\". BCR are a system of privacy principles, rules and tools intended to ensure the protection of personal data. This collection of rules represents today's best practice to meet the European Economic Area's (\"EEA\") data protection requirements for the transfer of personal data within a Group of companies. To be legally effective, the BCR have been approved by EEA Data Protection Agencies. BCR regulate the mechanism of transfer of data inside the Novartis Group of companies."])},
    "contactMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish to contact us regarding how we use your personal data or you wish to exercise your data privacy rights, please email us at"])}
  },
  "footerDashboard": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any information provided will be handled in accordance with Tecan's "])},
    "linkText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "fullTex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any information provided will be handled in accordance with Tecan's Privacy Policy."])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukladá sa..."])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začať"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatvoriť"])},
  "okBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstrániť"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť priebeh"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásenie"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásenie"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásiť sa"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásiť sa"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovať"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
}