<template>
  <section class="add-edit-action">
    <Modal
      v-if="isNextStepModalVisible"
      title="Add step"
      data-test-id="add-edit-action__add-step-modal"
      @close-modal="toggleNextStepModal"
    >
      <Select
        isLabelHidden
        class="add-edit-action__modal-select"
        data-test-id="add-edit-action__modal-select"
        label="Select step type"
        placeholder="Select step type"
        name="nextActionStep"
        id="nextActionStep"
        :options="nextStepModalOptions"
        :error="errorMessages.nextActionStep"
        @change="onChangeInput('nextActionStep', $event)"
      />
      <template #left>
        <CallToAction
          type="button"
          theme="error-inverse"
          value="Cancel"
          data-test-id="add-edit-action__modal-cancel-cta"
          @click="onCancelStep"
        />
      </template>
      <template #right>
        <CallToAction
          type="button"
          value="Add step"
          :is-error="nextActionStepHasError"
          data-test-id="add-edit-action__modal-add-cta"
          @click="onAddStep"
        />
      </template>
    </Modal>
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <h2 class="add-edit-action__title" data-test-id="add-edit-action__title">
        {{ getTitle }}
      </h2>
      <form
        class="add-edit-action__form"
        data-test-id="add-edit-action__form"
        @submit.prevent
      >
        <div class="add-edit-action__form-holder">
          <div
            class="add-edit-action__form-section add-edit-action__form-section--action-details"
          >
            <h4
              class="add-edit-action__form-section-title"
              data-test-id="add-edit-action__form-section-title"
            >
              Action Details
            </h4>
            <Input
              id="actionName"
              label="Name"
              name="actionName"
              placeholder="eg. name"
              class="add-edit-action__form-input"
              data-test-id="add-edit-action__form-name"
              :value="actionName"
              :error="errorMessages.actionName"
              :focus="true"
              @change="onChangeInput('actionName', $event)"
            />
            <div v-if="errorMessagesApi[actionKeys.NAME]">
              <InputErrorMessage
                v-for="(errorMessage, index) in errorMessagesApi[
                  actionKeys.NAME
                ]"
                :key="`error-message-api-name-${index}`"
                :error="errorMessage"
              />
            </div>
            <Textarea
              id="actionDescription"
              label="Description"
              name="actionDescription"
              placeholder="Description"
              data-test-id="add-edit-action__form-description"
              :value="actionDescription"
              :error="errorMessages.actionDescription"
              @change="
                (_, $event) => onChangeInput('actionDescription', $event)
              "
            />
            <div v-if="errorMessagesApi[actionKeys.DESCRIPTION]">
              <InputErrorMessage
                v-for="(errorMessage, index) in errorMessagesApi[
                  actionKeys.DESCRIPTION
                ]"
                :key="`error-message-api-description-${index}`"
                :error="errorMessage"
              />
            </div>
          </div>
          <div class="add-edit-action__accordion-actions">
            <CallToAction
              class="add-edit-action__accordion-actions--expand"
              value="Expand all"
              theme="none"
              data-test-id="add-edit-action__accordion-actions--expand"
              @click="setAccordionCollapse(false)"
            />
            <CallToAction
              class="add-edit-action__accordion-actions--collapse"
              value="Collapse all"
              theme="none"
              data-test-id="add-edit-action__accordion-actions--collapse"
              @click="setAccordionCollapse(true)"
            />
          </div>
          <div class="add-edit-action__form-section">
            <AccordionSection
              title="Expected Data"
              data-test-id="add-edit-action__expected-data"
              :isCollapsed="areAccordionsCollapsed"
              isCollapsable
            >
              <FormRowsList
                :formRowsList="makeExpectedDataListComponents.formRowsList"
                :formCtaList="makeExpectedDataListComponents.formCtaList"
              />
            </AccordionSection>
            <div v-if="errorMessagesApi[actionKeys.EXPECTED_DATA_DEFINITION]">
              <InputErrorMessage
                v-for="(errorMessage, index) in errorMessagesApi[
                  actionKeys.EXPECTED_DATA_DEFINITION
                ]"
                :key="`error-message-api-expected-data-${index}`"
                :error="errorMessage"
              />
            </div>
          </div>
          <div
            v-if="detailedExpectedData"
            @click="fetchParsedExpectedDataWrapper"
            @focusin="fetchParsedExpectedDataWrapper"
            tabindex="0"
          >
            <AccordionSection
              v-for="(step, index) in functionSteps"
              :key="`functionSteps-${index}`"
              class="add-edit-action__form-section"
              :data-test-id="`add-edit-action__form-section--${toCamel(
                step.stepType
              )}`"
              :title="
                makeAccordionTitle({
                  index,
                  title: actionStepTitleMap[step.stepType]
                })
              "
              :menuItems="getStepAccordionOptions(step.hasComment)"
              :isCollapsed="areAccordionsCollapsed"
              isCollapsable
              :showCommentBox="step.hasComment"
              :comment="step.comment"
              @click="onAccordionDropDownSelection(index, $event)"
              @input="onStepCommentChange(index, $event)"
            >
              <IfBlockWrapper
                v-if="step.stepType === actionStepType.IF"
                :blocks="step"
                :error="errorMessages.functionSteps[index] || ''"
                :stepIndex="index"
                @change="onIfBlockChange($event, index)"
                @create="onIfBlockChange($event, index)"
              />
              <SetStep
                v-else-if="step.stepType === actionStepType.SET"
                v-bind="step"
                @change="onSetStepChange($event, index)"
                @create="onSetStepCreate($event, index)"
                @reset="onSetStepChange($event, index)"
              />
              <BaseText
                v-else-if="step.stepType === actionStepType.END"
                v-bind="step"
              />
              <TriggerStep
                v-else-if="step.stepType === actionStepType.TRIGGER"
                v-bind="step"
                @change="onTriggerStepChange($event, index)"
                @create="onTriggerStepChange($event, index)"
                @reset="onTriggerStepChange($event, index)"
              />
              <EmailNotificationStep
                v-else-if="step.stepType === actionStepSubType.EMAIL"
                v-bind="step"
                @change="
                  onEmailNotificationStepChange({
                    event: $event,
                    stepIndex: index
                  })
                "
                @create="
                  onEmailNotificationStepChange({
                    event: $event,
                    stepIndex: index
                  })
                "
                @reset="
                  onEmailNotificationStepChange({
                    event: $event,
                    stepIndex: index
                  })
                "
              />
              <TaskStep
                v-else-if="step.stepType === actionStepType.TASK"
                v-bind="step"
                @change="
                  onTaskStepChange({
                    event: $event,
                    stepIndex: index
                  })
                "
                @create="
                  onTaskStepChange({
                    event: $event,
                    stepIndex: index
                  })
                "
                @reset="
                  onTaskStepChange({
                    event: $event,
                    stepIndex: index
                  })
                "
              />
            </AccordionSection>
            <InputErrorMessage :error="errorMessages.functionStepsList" />
            <div v-if="errorMessagesApi[actionKeys.FUNCTION_BODY]">
              <InputErrorMessage
                v-for="(errorMessage, index) in errorMessagesApi[
                  actionKeys.FUNCTION_BODY
                ]"
                :key="`error-message-api-function-body-${index}`"
                :error="errorMessage"
              />
            </div>
            <InputErrorMessage
              v-if="errorMessagesApi.server"
              :error="errorMessagesApi.server"
            />
          </div>
        </div>
        <div
          class="add-edit-action__form-cta-holder"
          @mouseenter="fetchParsedExpectedDataWrapper"
          @focusin="fetchParsedExpectedDataWrapper"
        >
          <BaseFooter>
            <template #left>
              <CallToAction
                type="button"
                value="Add step"
                data-test-id="add-edit-action__form-cta-holder--add-step"
                @click="addStepToBottomOfFunction"
              />
            </template>
            <template #right>
              <CallToAction
                class="add-edit-action__form-cta"
                type="button"
                theme="secondary"
                value="Close"
                data-test-id="add-edit-action__form-cta-holder--close"
                @click="onCancel"
              />
              <CallToAction
                class="add-edit-action__form-cta"
                type="button"
                value="Submit"
                :is-loading="isSavingAction"
                :is-error="hasActionError"
                :is-success="isSavingSuccessful"
                data-test-id="add-edit-action__form-cta-holder--submit"
                @click="onSubmit"
              />
            </template>
          </BaseFooter>
        </div>
      </form>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  actionKeys,
  actionKeysApi,
  actionName as actionNameEnum,
  actionStepOptions,
  actionStepSubType,
  actionStepTitleMap,
  actionStepType,
  getterName,
  ifBlockSuccessStepType,
  ifSuccessStepOptions,
  operations,
  routeNames,
  stepAccordionOptionValue,
  themes,
  timers,
  urls,
  variableAssignmentOptionsWithEmptyOption,
  variableAssignmentType,
  variableScope,
  variableType,
  variableValueBooleanOptions
} from "@/constants";
import { cloneDeep, debounce, isEmpty } from "lodash";
import { makeActionDTO } from "@/services/actions/dto/actionsDTO/actions.dto";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseFooter from "@/atoms/BaseFooter/BaseFooter";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import Input from "@/molecules/Input/Input";
import Textarea from "@/molecules/Textarea/Textarea";
import Select from "@/molecules/Select/Select";
import AccordionSection from "@/molecules/AccordionSection/AccordionSection";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import Modal from "@/molecules/Modal/Modal";
import { getSelectedOption, hasStatus500, toCamel } from "@/utils";
import IfBlockWrapper from "@/organisms/IfBlockWrapper/IfBlockWrapper";
import IfStatementMixin from "@/organisms/AddEditAction/mixins/IfStatement.mixin";
import AddEditActionValidationMixin from "@/organisms/AddEditAction/mixins/AddEditActionValidation/AddEditActionValidation.mixin";
import SetStepMixin from "@/organisms/AddEditAction/mixins/SetStepMixin/SetStep.mixin";
import EndStepMixin from "@/organisms/AddEditAction/mixins/EndStepMixin/EndStep.mixin";
import TriggerStepMixin from "@/organisms/AddEditAction/mixins/TriggerStepMixin/TriggerStep.mixin";
import TaskStepMixin from "@/organisms/AddEditAction/mixins/TaskStepMixin/TaskStep.mixin";
import RoundMixin from "@/organisms/AddEditAction/mixins/RoundMixin/Round.mixin";
import EmailNotificationMixin from "@/organisms/AddEditAction/mixins/EmailNotificationMixin/EmailNotification.mixin";
import { expectedDataMixin } from "@/mixins";
import { makeParsedExpectedDataAPIDTO } from "@/services/actions/dto/setStepDTO/setStep.dto";
import FormRowsList from "@/molecules/FormRowsList/FormRowsList";
import {
  addBracket,
  deleteBracket,
  getStepAccordionOptions,
  isCountryValueDataType,
  isSetStepType,
  makeAccordionTitle,
  makeActionOption,
  makeExpectedDataBaseLists,
  makeExpectedDataUnparsedProxyList,
  makeFirstOptionForSelect,
  makeFunctionSetStepErrorObj,
  makeListOfStrings,
  makeSaveActionObject,
  makeSetStepFlatObj,
  makeBaseErrorMessages
} from "./AddEditAction.logic";

export default {
  name: "AddEditAction",
  components: {
    IfBlockWrapper,
    Input,
    Textarea,
    CallToAction,
    BaseText,
    AccordionSection,
    BaseLoader,
    Select,
    BaseFooter,
    InputErrorMessage,
    Modal,
    FormRowsList
  },
  mixins: [
    IfStatementMixin,
    AddEditActionValidationMixin,
    SetStepMixin,
    EndStepMixin,
    TriggerStepMixin,
    RoundMixin,
    EmailNotificationMixin,
    TaskStepMixin,
    expectedDataMixin
  ],
  props: {
    action: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      toCamel,
      actionStepTitleMap,
      actionName: "",
      actionDescription: "",
      nextActionStep: "",
      isLoading: false,
      isNextStepModalVisible: false,
      functionSteps: [],
      nextStepModalOptions: [],
      tempAction: {},
      successStepEvent: {},
      errorMessages: this.makeBaseErrorMessages(),
      errorMessagesApi: this.makeErrorMessagesApi(),
      areAccordionsCollapsed: false,
      isSavingAction: false,
      hasActionError: false,
      isSavingSuccessful: false,
      showCommentBox: false,
      addBlockIndex: undefined,
      actionKeys,
      variableType,
      variableScope,
      variableAssignmentOptionsWithEmptyOption,
      themes,
      variableAssignmentType,
      variableValueBooleanOptions,
      actionStepType,
      actionStepSubType
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId
    }),
    ...mapGetters({
      getSystemActions: getterName.ACTIONS.GET_SYSTEM_ACTIONS,
      getExpectedDataDDQTasks:
        getterName.EXPECTED_DATA.GET_EXPECTED_DATA_DDQ_TASKS
    }),
    isNewAction() {
      return this.$route.name === routeNames.ADD_ACTION;
    },
    getTitle() {
      return this.isNewAction ? "Add Action" : "Action Configuration";
    },
    getExpectedDataUnparsedProxyList() {
      return this.makeExpectedDataUnparsedProxyList({
        expectedData: this.expectedData
      });
    },
    getExpectedDataBaseLists() {
      return this.makeExpectedDataBaseLists({
        expectedData: this.detailedExpectedData
      });
    },
    getExpectedDataEntityList() {
      return (
        this.expectedData?.reduce?.(
          (
            acc,
            { expectedVariableName = "", expectedVariableType = "" } = {}
          ) => {
            if (this.isExpectedDataEntityType(expectedVariableType)) {
              acc.push(
                this.makeActionOption({
                  text: expectedVariableName,
                  value: expectedVariableName,
                  type: expectedVariableType,
                  subType: expectedVariableType
                })
              );
            }
            return acc;
          },
          []
        ) || []
      );
    },
    getProxyExpectedDataBaseLists() {
      const proxySetStepIds = Object.keys(this.proxyExpectedData);
      return Object.values(this.proxyExpectedData).reduce(
        (acc, data, index) => {
          acc[proxySetStepIds[index]] = this.makeExpectedDataBaseLists({
            expectedData: [data]
          });
          return acc;
        },
        {}
      );
    },
    getListOfSettableOptionsPerEachStep() {
      return this.functionSteps.map((_, stepIndex) => {
        const localVariablesList = this.getLocalVariableUniqueList(
          stepIndex
        ).reduce((acc, setStep) => {
          if (
            this.isProxyValueDataType(setStep.valueDataType) &&
            this.getProxyExpectedDataBaseLists?.[setStep.id]
          ) {
            acc.push(
              ...this.getProxyExpectedDataBaseLists[setStep.id].settableOptions
            );
          } else if (setStep.variableNameSearchValue) {
            acc.push({
              text: setStep.variableNameSearchValue,
              value: setStep.variableNameSearchValue
            });
          }
          return acc;
        }, []);
        return [
          ...this.getExpectedDataBaseLists.settableOptions,
          ...localVariablesList
        ];
      });
    },
    getListOfAllOptionsPerEachStep() {
      return this.functionSteps.map((_, stepIndex) =>
        this.getLocalVariableUniqueList(stepIndex).reduce(
          (acc, setStep) => {
            const option = this.makeActionOption({
              text: setStep.variableNameSearchValue,
              value: setStep.variableNameSearchValue,
              type: setStep.variableType,
              subType: setStep.valueDataType,
              isArray: false,
              castTo:
                this.getAllEntities[setStep.componentValue]?.data?.data?.entity
            });
            if (
              this.isProxyValueDataType(setStep.valueDataType) &&
              this.getProxyExpectedDataBaseLists?.[setStep.id]
            ) {
              acc.push(
                ...this.getProxyExpectedDataBaseLists[setStep.id].allOptions
              );
            } else if (setStep.variableNameSearchValue) {
              option.isArray =
                this.isMultipleSelectionExpectedForEntityPropertyName(
                  setStep.variableNameSearchValue
                );
              acc.push(option);
            }
            return acc;
          },
          [
            ...this.getExpectedDataBaseLists.allOptions,
            ...this.getExpectedDataEntityList
          ]
        )
      );
    },
    getAllEntities() {
      const proxyExpectedDataBaseListRaw = Object.values(
        this.getProxyExpectedDataBaseLists
      ).reduce((acc, { raw }) => {
        acc = {
          ...acc,
          ...raw
        };
        return acc;
      }, {});

      return {
        ...this.getExpectedDataBaseLists.raw,
        ...proxyExpectedDataBaseListRaw
      };
    }
  },
  watch: {
    getListOfAllOptionsPerEachStep: {
      deep: true,
      handler(oldValue, newValue) {
        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
          debounce(() => {
            this.updateExistingAllSetToBlock();
            this.updateAllIfStatementLeftOptions();
            this.updateSetSteps();
            this.updateAllSuccessTriggerStepsOptions();
            this.updateEmailNotificationStepsOptions();
            this.updateTaskExpectedDataList();
          }, 500)();
        }
      }
    },
    hasActionError(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.hasActionError = false;
        }, timers.MODERATE);
      }
    }
  },
  async created() {
    this.makeTempAction();
    this.assignActionNameAndDescription();
    this.assignExpectedData();
    await this.fetchParsedExpectedDataWrapper();
    this.assignFunctionSteps();
    await this.fetchAllProxyExpectedData();
    this.makeFunctionStepsErrorList();
  },
  methods: {
    makeOptionsForSelect,
    makeAccordionTitle,
    makeExpectedDataBaseLists,
    makeFirstOptionForSelect,
    makeSetStepFlatObj,
    getStepAccordionOptions,
    makeListOfStrings,
    addBracket,
    deleteBracket,
    makeFunctionSetStepErrorObj,
    makeSaveActionObject,
    makeParsedExpectedDataAPIDTO,
    makeActionDTO,
    getSelectedOption,
    isSetStepType,
    makeActionOption,
    makeExpectedDataUnparsedProxyList,
    isCountryValueDataType,
    makeBaseErrorMessages,
    ...mapActions({
      saveAction: actionNameEnum.ACTIONS.SAVE_ACTION,
      amendAction: actionNameEnum.ACTIONS.AMEND_ACTION
    }),
    getAllAvailableOptionsByStepIndex(stepIndex) {
      const highestIndexOfAllAvailableOptions =
        this.getListOfAllOptionsPerEachStep.length - 1;

      if (highestIndexOfAllAvailableOptions >= stepIndex) {
        return this.getListOfAllOptionsPerEachStep[stepIndex] || [];
      }

      return (
        this.getListOfAllOptionsPerEachStep[
          highestIndexOfAllAvailableOptions
        ] || []
      );
    },
    showModal() {
      this.nextStepModalOptions = this.makeOptionsForSelect(
        "",
        actionStepOptions
      );
      this.toggleNextStepModal();
    },
    showSuccessStepModal(event, index) {
      this.nextStepModalOptions = this.makeOptionsForSelect(
        "",
        ifSuccessStepOptions
      );
      this.successStepEvent = {
        event,
        index
      };
      this.toggleNextStepModal();
    },
    amendBracketSection(event, calculationList) {
      if (event.eventType === operations.ADD_BRACKET) {
        this.addBracket({ index: event.index, calculationList });
      } else if (event.eventType === operations.DELETE_BRACKET) {
        this.deleteBracket({ index: event.index, calculationList });
      } else {
        this.updateCalculationBracket(event, calculationList);
      }
    },
    updateAllStepIndexes(editType, index) {
      this.updateIfStatementStepIndexes(editType, index);
      this.updateSetStepsIndexes(editType, index);
      this.updateEndStepsIndexes(editType, index);
      this.updateTriggerStepsIndexes(editType, index);
      this.updateEmailNotificationStepsIndexes(editType, index);
      this.updateEmailNotificationExpectedDataIndexes(editType, index);
      this.updateTaskStepsIndexes({ editType, index });
      this.updateTaskExpectedDataIndexes({ editType, index });
    },
    onAccordionDropDownSelection(index, selection) {
      if (selection === stepAccordionOptionValue.ADD_STEP_ABOVE) {
        this.addBlockIndex = index;
        this.showModal();
      } else if (selection === stepAccordionOptionValue.ADD_STEP_BELOW) {
        this.addBlockIndex = index + 1;
        this.showModal();
      } else if (selection === stepAccordionOptionValue.DELETE_STEP) {
        this.removeBlock(index);
      } else if (selection === stepAccordionOptionValue.ADD_COMMENT) {
        this.setHasComment(index, true);
      } else if (selection === stepAccordionOptionValue.REMOVE_COMMENT) {
        this.setHasComment(index, false);
      }
    },
    removeBlock(index) {
      this.removeBelowValuesAssociatedToStep(index);
      const deletedStep = this.functionSteps.splice(index, 1);

      if (deletedStep?.[0]?.stepType === actionStepType.IF) {
        this.deleteIfStepStatements(index);
      } else if (deletedStep?.[0]?.stepType === actionStepType.SET) {
        this.deleteSetStepIndexFromList(index);
      } else if (deletedStep?.[0]?.stepType === actionStepType.END) {
        this.deleteEndStepIndexFromList(index);
      } else if (deletedStep?.[0]?.stepType === actionStepType.TRIGGER) {
        this.deleteTriggerStepIndexFromList(index);
      } else if (deletedStep?.[0]?.stepType === actionStepSubType.EMAIL) {
        this.deleteEmailNotificationStepIndexFromList(index);
      } else if (deletedStep?.[0]?.stepType === actionStepType.TASK) {
        this.deleteTaskStepIndexFromList(index);
      }

      this.updateAllStepIndexes(operations.DELETE, index);
    },
    deleteSuccessStep({ id, stepType, property, index }, stepIndex) {
      if (stepType === actionStepType.SET) {
        this.deleteSetStepIdFromList(id);
      } else if (stepType === actionStepType.END) {
        this.deleteEndStepIdFromList(id);
      } else if (stepType === actionStepType.TRIGGER) {
        this.deleteTriggerStepIdFromList(id);
      } else if (stepType === actionStepType.TASK) {
        this.deleteTaskStepIdFromList({ id, stepIndex });
      } else if (stepType === actionStepSubType.EMAIL) {
        this.deleteEmailNotificationStepIdFromList({ id, stepIndex });
      }

      if (property === actionKeys.ELSE_BLOCK) {
        this.functionSteps[stepIndex][property] = this.functionSteps[stepIndex][
          property
        ].filter(({ componentOptions }) => componentOptions.id !== id);
      } else {
        this.functionSteps[stepIndex][property][index].successSteps =
          this.functionSteps[stepIndex][property][index].successSteps.filter(
            ({ componentOptions }) => componentOptions.id !== id
          );
      }
    },
    amendSuccessStep(event, stepIndex) {
      if (event.stepType === actionStepType.SET) {
        this.amendSuccessSetStep(event, stepIndex);
      } else if (event.stepType === actionStepType.TRIGGER) {
        this.amendSuccessTriggerStep(event, stepIndex);
      } else if (event.stepType === actionStepSubType.EMAIL) {
        this.amendSuccessEmailNotificationStep({ event, stepIndex });
      } else if (event.stepType === actionStepType.TASK) {
        this.amendSuccessTaskStep({ event, stepIndex });
      }
    },
    isExpectedDataBaseOption({ variableNameSearchValue }) {
      return !!this.getExpectedDataBaseLists.allOptions.find(
        ({ text }) => text === variableNameSearchValue
      );
    },
    toggleNextStepModal() {
      this.resetNextStepErrorMessage();
      this.isNextStepModalVisible = !this.isNextStepModalVisible;
    },
    onCancelStep() {
      this.nextActionStep = undefined;
      this.resetNextStepErrorMessage();
      this.toggleNextStepModal();
    },
    addStepToBottomOfFunction() {
      this.addBlockIndex = this.functionSteps.length;
      this.showModal();
    },
    makeFunctionStepsErrorList() {
      this.errorMessages.functionSteps = this.functionSteps.map((_, index) =>
        this.makeFunctionSetStepErrorObj({
          functionStep: this.functionSteps[index]
        })
      );
    },
    async onAddStep() {
      const isInvalid = await this.isNextActionStepInvalid();

      if (!isInvalid) {
        if (this.nextActionStep === actionStepType.SET) {
          this.makeNewSetStep(this.addBlockIndex);
        } else if (this.nextActionStep === actionStepType.IF) {
          this.makeNewIfStep(this.addBlockIndex);
        } else if (this.nextActionStep === actionStepType.END) {
          this.makeNewEndStep(this.addBlockIndex);
        } else if (this.nextActionStep === actionStepType.TRIGGER) {
          this.makeNewTriggerStep(this.addBlockIndex);
        } else if (this.nextActionStep === actionStepSubType.EMAIL) {
          this.makeNewEmailNotificationStep(this.addBlockIndex);
        } else if (this.nextActionStep === actionStepType.TASK) {
          this.makeNewTaskStep(this.addBlockIndex);
        } else if (this.nextActionStep === ifBlockSuccessStepType.SET) {
          this.makeSuccessSetStepForIfBlock();
        } else if (this.nextActionStep === ifBlockSuccessStepType.TRIGGER) {
          this.makeSuccessTriggerStepForIfBlock();
        } else if (this.nextActionStep === ifBlockSuccessStepType.END) {
          this.makeSuccessEndStepForIfBlock();
        } else if (this.nextActionStep === ifBlockSuccessStepType.EMAIL) {
          this.makeSuccessEmailNotificationStepForIfBlock();
        } else if (this.nextActionStep === ifBlockSuccessStepType.TASK) {
          this.makeSuccessTaskStepForIfBlock();
        }

        this.toggleNextStepModal();
        this.nextActionStep = undefined;
      }
    },
    onCancel() {
      this.$router.push(urls.ACTIONS);
    },
    onChangeInput(inputName, value) {
      if (typeof inputName === "string") {
        this[inputName] = value.trim();
      } else {
        const { key, index, subKey } = inputName;
        this[key][index][subKey] =
          typeof value === "string" ? value.trim() : value;
      }
    },
    async onSubmit() {
      this.isSavingAction = true;
      this.hasActionError = false;
      const isInvalid = await this.validateAction();
      try {
        if (!isInvalid) {
          await this.submitAction();
        }
      } catch (error) {
        this.hasActionError = true;
        this.errorMessagesApi = this.makeErrorMessagesApi(error);
      } finally {
        this.isSavingAction = false;
      }
    },
    async submitAction() {
      const payload = {
        action: this.makeSaveActionObject({
          functionSteps: this.functionSteps,
          actionName: this.actionName,
          actionDescription: this.actionDescription,
          companyId: this.companyId,
          expectedData: this.expectedData
        }),
        entities: Object.values(this.getAllEntities).map((entity) =>
          this.makeParsedExpectedDataAPIDTO(entity)
        )
      };
      if (this.tempAction[actionKeys.ID]) {
        await this.amendAction({
          ...payload,
          actionId: this.tempAction.id
        });
        this.submitActionSuccess("updated");
      } else {
        await this.saveAction(payload);
        this.submitActionSuccess("created");
      }
    },
    submitActionSuccess(operation) {
      this.isSavingSuccessful = true;

      this.$router.push({
        name: routeNames.ACTIONS,
        state: { message: `Action ${operation} successfully` }
      });
    },
    assignExpectedData() {
      this.expectedData =
        this.tempAction?.[actionKeys.EXPECTED_DATA_DEFINITION]?.[
          actionKeys.VARIABLES
        ]?.map((expectedDataDefinition) =>
          this.makeExpectedDataObj(expectedDataDefinition)
        ) || [];
    },
    assignActionNameAndDescription() {
      this.actionName = this.tempAction[actionKeys.NAME] || "";
      this.actionDescription = this.tempAction[actionKeys.DESCRIPTION] || "";
    },
    assignFunctionSteps() {
      this.functionSteps =
        this.tempAction?.[actionKeys.FUNCTION_BODY]?.map(
          (functionData, stepIndex) => {
            if (functionData?.stepType === actionStepType.IF) {
              return this.makeIfStep(functionData, stepIndex);
            } else if (functionData?.stepType === actionStepType.SET) {
              return this.makeSetStepAndExtendSetStepList(
                functionData,
                stepIndex
              );
            } else if (functionData?.stepType === actionStepType.END) {
              return this.makeEndStepAndExtendEndStepList(stepIndex);
            } else if (functionData?.stepType === actionStepType.TRIGGER) {
              return this.makeTriggerStepAndExtendTriggerStepList(
                functionData,
                stepIndex
              );
            } else if (functionData?.stepType === actionStepType.TASK) {
              return this.makeTaskStepAndExtendTaskStepList({
                functionData,
                stepIndex
              });
            } else if (functionData?.stepType === actionStepSubType.EMAIL) {
              return this.makeEmailNotificationStepAndExtendStepList({
                functionData,
                stepIndex
              });
            }
          }
        ) || [];
    },
    makeTempAction() {
      if (isEmpty(this.action)) {
        this.tempAction = this.makeActionDTO();
      } else {
        this.tempAction = cloneDeep(this.action);
      }
    },
    makeErrorMessagesApi({ response } = {}) {
      const { errors = {}, message = "" } = response?.data || {};
      return {
        [actionKeys.NAME]: this.makeListOfStrings(errors[actionKeysApi.NAME]),
        [actionKeys.DESCRIPTION]: this.makeListOfStrings(
          errors[actionKeysApi.DESCRIPTION]
        ),
        [actionKeys.EXPECTED_DATA_DEFINITION]: this.makeListOfStrings(
          errors[actionKeysApi.EXPECTED_DATA_DEFINITION]
        ),
        [actionKeys.FUNCTION_BODY]: this.makeListOfStrings(
          errors[actionKeysApi.FUNCTION_BODY]
        ),
        server: hasStatus500(response) ? message : undefined
      };
    },
    async fetchParsedExpectedDataWrapper() {
      const isExpectedDataValid = !this.validateExpectedData({
        expectedData: this.expectedData
      });
      if (isExpectedDataValid && this.isFetchParsedExpectedDataNeeded) {
        try {
          this.errorMessagesApi = this.makeErrorMessagesApi();
          this.isLoading = true;
          await this.fetchParsedExpectedData();
        } catch (error) {
          this.detailedExpectedData = [];
          this.errorMessagesApi = this.makeErrorMessagesApi(error);
        } finally {
          this.isFetchParsedExpectedDataNeeded = false;
          this.isLoading = false;
        }
      }
    },
    async fetchParsedProxyExpectedDataWrapper({
      setStep = {},
      payload = {}
    } = {}) {
      try {
        this.isLoading = true;
        this.errorMessagesApi = this.makeErrorMessagesApi();
        this.fetchParsedProxyExpectedData({
          id: setStep.id,
          payload
        });
      } catch (error) {
        this.errorMessagesApi = this.makeErrorMessagesApi(error);
      } finally {
        this.isLoading = false;
      }
    },
    setAccordionCollapse(value) {
      this.areAccordionsCollapsed = value;
    },
    setHasComment(index, value) {
      this.functionSteps[index].hasComment = value;
      if (!value) {
        this.functionSteps[index].comment = "";
      }
    },
    onStepCommentChange(index, { target = {} } = {}) {
      this.functionSteps[index].comment = target.value;
    },
    makeFetchProxyExpectedDataPayload(setStep) {
      const entityKey =
        this.getSelectedOption(
          setStep?.componentOptions?.componentOptions?.options
        )?.text || "";
      const payload = this.getAllEntities[entityKey] || {};
      return {
        ...(this.makeParsedExpectedDataAPIDTO(payload)?.data?.data || {}),
        name: setStep?.variableNameSearchValue
      };
    },
    makeSuccessSetStepForIfBlock() {
      const { index: stepIndex, event } = this.successStepEvent;
      const successSetStep = this.makeSuccessSetStep(undefined, stepIndex);

      if (event.property === actionKeys.ELSE_BLOCK) {
        this.functionSteps[stepIndex][event.property] = [
          ...(this.functionSteps[stepIndex]?.[event.property] || []),
          successSetStep
        ];
      } else {
        this.functionSteps[stepIndex][event.property][event.index][
          actionKeys.SUCCESS_STEPS
        ] = [
          ...(this.functionSteps[stepIndex]?.[event.property]?.[event.index]
            ?.successSteps || []),
          successSetStep
        ];
      }
    },
    getLocalVariableUniqueList(stepIndex) {
      return this.functionSteps.reduce((acc, functionStep, position) => {
        if (this.isSetStepType(functionStep.stepType)) {
          const isItemNotIncluded = !acc.find(
            ({ variableNameSearchValue }) =>
              variableNameSearchValue === functionStep.variableNameSearchValue
          );
          const isDefinedAbove = position < stepIndex;
          const isLocalVariable =
            functionStep?.variableScope === variableScope.LOCAL;
          if (
            !this.isExpectedDataBaseOption(functionStep) &&
            this.isSetStepCompleted(functionStep) &&
            isLocalVariable &&
            isDefinedAbove &&
            isItemNotIncluded
          ) {
            acc.push(functionStep);
          }
        }
        return acc;
      }, []);
    }
  }
};
</script>

<style lang="scss" scoped>
.add-edit-action {
  text-align: left;

  &__accordion-actions {
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-end;

    &--expand {
      margin-right: 15px;
    }
  }

  &__form {
    text-align: left;

    &-set-to-block {
      width: 50%;
    }

    &:deep(.add-edit-action__form-set-from-block) {
      display: flex;
      width: 50%;
    }

    &-holder {
      height: calc(100vh - 245px);
      overflow-y: scroll;
      padding-right: 20px;
    }

    &:deep(.add-edit-action__form-cta) {
      margin-left: 20px;
    }

    &-step {
      display: flex;
    }

    &-section {
      margin-bottom: 30px;

      &-title {
        @include heading-five;
      }

      &:deep(.add-edit-action__form-section-separator) {
        padding: 0 10px;
      }

      &--action-details {
        max-width: 50%;
      }
    }

    &-cta-holder {
      text-align: right;

      :deep(.base-footer) {
        padding: 15px 0;
      }
    }
  }

  &:deep(.add-edit-action__modal-select) {
    flex-direction: column;
  }

  &:deep(.label__label) {
    display: flex;
  }
}
</style>
