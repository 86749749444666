<template>
  <div :class="computedClasses" data-test-id="shape-with-info">
    <BaseIcon
      v-if="hasIcon"
      v-bind="getIconOptions"
      class="shape-with-info__icon"
      data-test-id="shape-with-info__icon"
    />
    <BaseText
      v-else-if="hasText"
      v-bind="getTextOptions"
      class="shape-with-info__text"
      data-test-id="shape-with-info__text"
      hasDefaultSpacingRemoved
    />
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import BaseText from "@/atoms/BaseText/BaseText";
import { isValidShape, isValidTheme } from "@/utils";
import {
  shapes,
  themes,
  sizes,
  border,
  iconSizes,
  typographySize
} from "@/constants";

export default defineComponent({
  name: "ShapeWithInfo",
  components: {
    BaseIcon,
    BaseText
  },
  props: {
    shape: {
      type: String,
      default: shapes.NONE,
      validator(value) {
        return isValidShape(value);
      }
    },
    theme: {
      type: String,
      default: themes.NONE,
      validator(value) {
        return isValidTheme(value);
      }
    },
    size: {
      type: String,
      default: sizes.MEDIUM,
      validator(value) {
        return [sizes.DEFAULT, sizes.SMALL, sizes.MEDIUM, sizes.LARGE].includes(
          value
        );
      }
    },
    borderStyle: {
      type: String,
      default: border.NONE,
      validator: (value) =>
        [
          border.SOLID,
          border.DASHED,
          border.DOTTED,
          border.SOLID_OUTLINE
        ].includes(value)
    },
    iconOptions: {
      type: Object,
      default: () => ({})
    },
    textOptions: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const hasIcon = computed(() => !!props.iconOptions?.icon);
    const hasText = computed(() => !!props.textOptions?.text);

    const getTextSize = computed(
      () =>
        Object.values(typographySize).find((value) =>
          value.includes(props.size)
        ) || typographySize.BODY_TEXT
    );

    const getIconOptions = computed(() => ({
      size: props.iconOptions?.size || iconSizes[props.size?.toUpperCase()],
      ...props.iconOptions
    }));

    const getTextOptions = computed(() => ({
      size: props.textOptions?.size || getTextSize?.value,
      ...props.textOptions
    }));

    const computedClasses = computed(() => {
      const baseClass = "shape-with-info";
      const themeClass = `shape-with-info__theme--${props.theme}`;
      const shapeClass = `shape-with-info__shape--${props.shape}`;
      const sizeClass = `shape-with-info__size--${props.size}`;
      const borderClass = `shape-with-info__border--${props.borderStyle}`;

      return [baseClass, themeClass, shapeClass, sizeClass, borderClass]
        .filter(Boolean)
        .join(" ");
    });

    return {
      hasIcon,
      hasText,
      computedClasses,
      getIconOptions,
      getTextOptions,
      getTextSize
    };
  }
});
</script>

<style lang="scss" scoped>
.shape-with-info {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__shape--circle {
    border-radius: 50%;
  }

  &__size {
    &--small {
      width: $spacing24;
      height: $spacing24;
    }

    &--medium {
      width: $spacing40;
      height: $spacing40;
    }

    &--large {
      width: $spacing48;
      height: $spacing48;
    }
  }

  &__border {
    &--solid-outline {
      border: $spacing4 solid;
    }

    &--solid {
      border: 1px solid;
    }

    &--dotted {
      border: 2px dotted;
    }

    &--dashed {
      border: 2px dashed;
    }
  }

  &__icon {
    font-size: inherit;
    align-self: center;
  }

  @each $theme, $primary-color, $secondary-color in $themes {
    &__theme--#{$theme},
    &__text--only {
      background: $primary-color;
      @include get-color($secondary-color);
    }
  }
}
</style>
