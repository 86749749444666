import {
  actionKeys,
  actionKeysApi,
  actionStepType,
  expectedDataTypes,
  triggerDataType,
  variableScope
} from "@/constants";
import { getSelectedOption, isExpectedDataEntityType } from "@/utils";

const makeDDQFormObjAPIDTO = (optionValue = {}) => ({
  [actionKeysApi.COMPANY_ID]: optionValue[actionKeys.COMPANY_ID],
  [actionKeysApi.DDQ_ID]: optionValue[actionKeys.DDQ_ID]
});

const getDDQFormObjAPIDTO = ({
  options = [],
  _makeDDQFormObjAPIDTO = makeDDQFormObjAPIDTO,
  _getSelectedOption = getSelectedOption
} = {}) => {
  try {
    const value = JSON.parse(_getSelectedOption(options)?.value);
    return _makeDDQFormObjAPIDTO(value);
  } catch {
    return _makeDDQFormObjAPIDTO();
  }
};

const makeTriggerStepProxyAPIDTO = ({
  options = [],
  name = "",
  _getSelectedOption = getSelectedOption
} = {}) => ({
  [actionKeysApi.TRIGGER_DATA]: {
    [actionKeysApi.TYPE]: triggerDataType.PROXY,
    [actionKeysApi.DATA]: {
      [actionKeysApi.NAME]: _getSelectedOption(options)?.value
    }
  },
  [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: name
});

const makeTriggerStepDDQformAPIDTO = ({
  options = [],
  name = "",
  _getDDQFormObjAPIDTO = getDDQFormObjAPIDTO
} = {}) => {
  const valueObj = _getDDQFormObjAPIDTO({ options });

  return {
    [actionKeysApi.TRIGGER_DATA]: {
      [actionKeysApi.TYPE]: triggerDataType.IDENTIFIER,
      [actionKeysApi.DATA]: {
        [actionKeysApi.TYPE]: expectedDataTypes.DDQ_FORM,
        [actionKeysApi.VALUE]: {
          [actionKeysApi.DDQ_ID]: valueObj?.[actionKeysApi.DDQ_ID],
          [actionKeysApi.COMPANY_ID]: valueObj?.[actionKeysApi.COMPANY_ID]
        }
      }
    },
    [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: name
  };
};

const getSelectedOptionForTrainingCourseAPIDTO = ({
  options = [],
  _getSelectedOption = getSelectedOption
} = {}) => {
  try {
    return JSON.parse(_getSelectedOption(options)?.value);
  } catch {
    return {};
  }
};

const makeTriggerStepTrainingCourseAPIDTO = ({
  expectDataObj = {},
  _getSelectedOptionForTrainingCourseAPIDTO = getSelectedOptionForTrainingCourseAPIDTO
} = {}) => {
  const selectedOption =
    _getSelectedOptionForTrainingCourseAPIDTO(expectDataObj);

  return {
    [actionKeysApi.TRIGGER_DATA]: {
      [actionKeysApi.TYPE]: actionKeysApi.IDENTIFIER,
      [actionKeysApi.DATA]: {
        [actionKeysApi.TYPE]: expectDataObj[actionKeys.TYPE],
        [actionKeysApi.VALUE]: {
          [actionKeysApi.COMPANY_ID]: selectedOption[actionKeys.COMPANY_ID],
          [actionKeysApi.COURSE_ID]: selectedOption[actionKeys.COURSE_ID]
        }
      }
    },
    [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
      expectDataObj[actionKeys.NAME]
  };
};

const makeTriggerStepExpectedDataFromEntityAPIDTO = ({
  expectDataObj = {},
  _makeTriggerStepProxyAPIDTO = makeTriggerStepProxyAPIDTO,
  _makeTriggerStepDDQformAPIDTO = makeTriggerStepDDQformAPIDTO,
  _makeTriggerStepTrainingCourseAPIDTO = makeTriggerStepTrainingCourseAPIDTO
} = {}) => {
  if (
    expectDataObj.uniqueType === expectedDataTypes.THIRD_PARTY ||
    expectDataObj.uniqueType === expectedDataTypes.IDD_IDD_PLUS
  ) {
    return _makeTriggerStepProxyAPIDTO(expectDataObj);
  }

  if (expectDataObj.uniqueType === expectedDataTypes.DDQ_FORM) {
    return _makeTriggerStepDDQformAPIDTO(expectDataObj);
  }

  if (expectDataObj.uniqueType === expectedDataTypes.TRAINING_COURSE) {
    return _makeTriggerStepTrainingCourseAPIDTO({ expectDataObj });
  }
};

const makeTriggerStepExpectedDataFromLocalSetStepAPIDTO = ({
  expectDataObj = {},
  functionStep = {}
} = {}) => ({
  [actionKeysApi.TRIGGER_DATA]: {
    [actionKeysApi.DATA]: {
      [actionKeysApi.DATA]: {
        [actionKeysApi.DATA]: {
          name: functionStep.variableName
        },
        [actionKeysApi.TYPE]: functionStep.variableType
      },
      [actionKeysApi.TYPE]: variableScope.LOCAL
    },
    [actionKeysApi.TYPE]: triggerDataType.VARIABLE
  },
  [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: expectDataObj.name
});

const makeTriggerStepExpectedDataFromParsedDataAPIDTO = ({
  expectDataObj = {},
  entity = {}
} = {}) => ({
  [actionKeysApi.TRIGGER_DATA]: {
    [actionKeysApi.DATA]: {
      [actionKeysApi.DATA]: {
        [actionKeysApi.DATA]: {
          ...entity?.data?.data
        },
        [actionKeysApi.TYPE]: entity?.data?.type
      },
      [actionKeysApi.TYPE]: entity?.type
    },
    [actionKeysApi.TYPE]: triggerDataType.VARIABLE
  },
  [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: expectDataObj.name
});

const makeTriggerStepExpectedDataFromPrimitiveAndInstanceAPIDTO = ({
  expectDataObj = {},
  entities = [],
  functionSteps = [],
  _getSelectedOption = getSelectedOption,
  _makeTriggerStepExpectedDataFromLocalSetStepAPIDTO = makeTriggerStepExpectedDataFromLocalSetStepAPIDTO,
  _makeTriggerStepExpectedDataFromParsedDataAPIDTO = makeTriggerStepExpectedDataFromParsedDataAPIDTO
} = {}) => {
  const { value: mappedExpectedDataValue = "" } = _getSelectedOption(
    expectDataObj.options
  );
  const functionStep = functionSteps.find(
    ({ variableName }) => variableName === mappedExpectedDataValue
  );

  if (functionStep) {
    return _makeTriggerStepExpectedDataFromLocalSetStepAPIDTO({
      expectDataObj,
      functionStep
    });
  }

  const entity = entities.find(
    ({ data }) => data.data.name === mappedExpectedDataValue
  );

  return _makeTriggerStepExpectedDataFromParsedDataAPIDTO({
    entity,
    expectDataObj
  });
};

const makeTriggerStepExpectedDataAPIDTO = ({
  expectDataObj = {},
  entities = [],
  functionSteps = [],
  _makeTriggerStepExpectedDataFromEntityAPIDTO = makeTriggerStepExpectedDataFromEntityAPIDTO,
  _makeTriggerStepExpectedDataFromPrimitiveAndInstanceAPIDTO = makeTriggerStepExpectedDataFromPrimitiveAndInstanceAPIDTO,
  _isExpectedDataEntityType = isExpectedDataEntityType
} = {}) => {
  if (_isExpectedDataEntityType(expectDataObj.uniqueType)) {
    return _makeTriggerStepExpectedDataFromEntityAPIDTO({ expectDataObj });
  }

  return _makeTriggerStepExpectedDataFromPrimitiveAndInstanceAPIDTO({
    expectDataObj,
    entities,
    functionSteps
  });
};

const makeTriggerStepAPIDTO = ({
  functionStep = {},
  entities = [],
  functionSteps = [],
  _getSelectedOption = getSelectedOption,
  _makeTriggerStepExpectedDataAPIDTO = makeTriggerStepExpectedDataAPIDTO
} = {}) => ({
  [actionKeysApi.STEP_TYPE]: actionStepType.TRIGGER,
  [actionKeysApi.COMMENT]: functionStep[actionKeys.COMMENT] || "",
  [actionKeysApi.ACTION_ID]: _getSelectedOption(functionStep.actionNameOptions)
    .value,
  [actionKeysApi.EXPECTED_DATA_MAPPING]:
    functionStep.expectDataList?.map?.((expectDataObj) =>
      _makeTriggerStepExpectedDataAPIDTO({
        expectDataObj,
        entities,
        functionSteps
      })
    ) || []
});

const makeTriggerStepDDQformDTO = (expectDataObj) => ({
  [actionKeys.TRIGGER_DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.VALUE]: {
        [actionKeys.COMPANY_ID]:
          expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.VALUE
          ]?.[actionKeysApi.COMPANY_ID],
        [actionKeys.DDQ_ID]:
          expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.VALUE
          ]?.[actionKeysApi.DDQ_ID]
      }
    },
    [actionKeys.TYPE]:
      expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE]
  },
  [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectDataObj[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
});

const makeTriggerStepProxyDTO = (expectDataObj = {}) => ({
  [actionKeys.TRIGGER_DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.NAME]:
        expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
          actionKeysApi.NAME
        ]
    },
    [actionKeys.TYPE]:
      expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE]
  },
  [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectDataObj[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
});

const makeTriggerStepTrainingCourseDTO = (expectDataObj = {}) => ({
  [actionKeys.TRIGGER_DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.VALUE]: {
        [actionKeys.COMPANY_ID]:
          expectDataObj[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.VALUE
          ]?.[actionKeysApi.COMPANY_ID],
        [actionKeys.COURSE_ID]:
          expectDataObj[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.VALUE
          ]?.[actionKeysApi.COURSE_ID]
      }
    },
    [actionKeys.TYPE]:
      expectDataObj[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE]
  },
  [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectDataObj[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
});

const makeTriggerStepIdentifierDTO = ({
  expectDataObj = {},
  _makeTriggerStepTrainingCourseDTO = makeTriggerStepTrainingCourseDTO,
  _makeTriggerStepDDQformDTO = makeTriggerStepDDQformDTO
} = {}) => {
  const { type = "" } =
    expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA] || {};
  if (type === triggerDataType.TRAINING_COURSE) {
    return _makeTriggerStepTrainingCourseDTO(expectDataObj);
  }
  if (type === triggerDataType.DDQ_FORM) {
    return _makeTriggerStepDDQformDTO(expectDataObj);
  }
};

const makeTriggerStepVariableDTO = (expectDataObj = {}) => {
  const entity =
    expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
      actionKeysApi.DATA
    ]?.[actionKeysApi.DATA] || {};
  return {
    [actionKeys.TRIGGER_DATA]: {
      [actionKeys.DATA]: {
        [actionKeys.DATA]: {
          [actionKeys.DATA]: {
            ...(entity?.hasOwnProperty(actionKeysApi.IDENTIFIER) && {
              [actionKeys.IDENTIFIER]: entity?.[actionKeysApi.IDENTIFIER]
            }),
            ...(entity?.hasOwnProperty(actionKeysApi.IS_ARRAY) && {
              [actionKeys.IS_ARRAY]: entity?.[actionKeysApi.IS_ARRAY]
            }),
            ...(entity?.hasOwnProperty(actionKeysApi.IS_CUSTOM_ATTRIBUTE) && {
              [actionKeys.IS_CUSTOM_ATTRIBUTE]:
                entity?.[actionKeysApi.IS_CUSTOM_ATTRIBUTE]
            }),
            ...(entity?.hasOwnProperty(actionKeysApi.IS_ENUM) && {
              [actionKeys.IS_ENUM]: entity?.[actionKeysApi.IS_ENUM]
            }),
            ...(entity?.hasOwnProperty(actionKeysApi.IS_READ_ONLY) && {
              [actionKeys.IS_READ_ONLY]: entity?.[actionKeysApi.IS_READ_ONLY]
            }),
            ...(entity?.hasOwnProperty(actionKeysApi.NAME) && {
              [actionKeys.NAME]: entity?.[actionKeysApi.NAME]
            }),
            ...(entity?.hasOwnProperty(actionKeysApi.SUB_TYPE) && {
              [actionKeys.SUB_TYPE]: entity?.[actionKeysApi.SUB_TYPE]
            }),
            ...(entity?.hasOwnProperty(actionKeysApi.OPTIONS) && {
              [actionKeys.OPTIONS]: entity?.[actionKeysApi.OPTIONS]
            })
          },
          [actionKeys.TYPE]:
            expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
              actionKeysApi.DATA
            ]?.[actionKeysApi.TYPE]
        },
        [actionKeys.TYPE]:
          expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.TYPE
          ]
      },
      [actionKeys.TYPE]:
        expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE]
    },
    [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
      expectDataObj?.[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
  };
};

const makeTriggerStepExpectedDataDTO = ({
  expectDataObj = {},
  _makeTriggerStepProxyDTO = makeTriggerStepProxyDTO,
  _makeTriggerStepIdentifierDTO = makeTriggerStepIdentifierDTO,
  _makeTriggerStepVariableDTO = makeTriggerStepVariableDTO
} = {}) => {
  const { type = "" } = expectDataObj?.[actionKeysApi.TRIGGER_DATA] || {};
  if (type === triggerDataType.PROXY) {
    return _makeTriggerStepProxyDTO(expectDataObj);
  }

  if (type === triggerDataType.IDENTIFIER) {
    return _makeTriggerStepIdentifierDTO({ expectDataObj });
  }

  if (type === triggerDataType.VARIABLE) {
    return _makeTriggerStepVariableDTO(expectDataObj);
  }
};

const makeTriggerStepDTO = ({
  functionStep = {},
  _makeTriggerStepExpectedDataDTO = makeTriggerStepExpectedDataDTO
} = {}) => ({
  [actionKeys.STEP_TYPE]: functionStep[actionKeysApi.STEP_TYPE],
  [actionKeys.COMMENT]: functionStep[actionKeysApi.COMMENT] || "",
  [actionKeys.ACTION_ID]: functionStep[actionKeysApi.ACTION_ID],
  [actionKeys.EXPECTED_DATA_MAPPING]: functionStep[
    actionKeysApi.EXPECTED_DATA_MAPPING
  ].map((expectDataObj) => _makeTriggerStepExpectedDataDTO({ expectDataObj }))
});

export {
  makeTriggerStepDDQformDTO,
  makeTriggerStepProxyDTO,
  makeTriggerStepAPIDTO,
  makeTriggerStepDTO,
  makeTriggerStepExpectedDataDTO,
  makeTriggerStepVariableDTO,
  makeTriggerStepIdentifierDTO,
  makeTriggerStepTrainingCourseDTO,
  makeDDQFormObjAPIDTO,
  getDDQFormObjAPIDTO,
  makeTriggerStepProxyAPIDTO,
  makeTriggerStepDDQformAPIDTO,
  makeTriggerStepTrainingCourseAPIDTO,
  getSelectedOptionForTrainingCourseAPIDTO,
  makeTriggerStepExpectedDataFromEntityAPIDTO,
  makeTriggerStepExpectedDataFromPrimitiveAndInstanceAPIDTO,
  makeTriggerStepExpectedDataFromLocalSetStepAPIDTO,
  makeTriggerStepExpectedDataFromParsedDataAPIDTO,
  makeTriggerStepExpectedDataAPIDTO
};
